import React from "react";
// import "./Home.css";
import Nav from "./Nav"
import {ShieldCheck, RightArrow, ArrowRight} from "../components/svg"
import Footer from '../components/footer'
import {businessInfo} from '../components/selfDefine'
import { layoutGenerator } from 'react-break';
import {Collapse, Timeline,BackTop,List } from 'antd';
import {service} from './text';
import { useAppContext } from "../libs/contextLib";
export default function Landing(props) {
  const layout = layoutGenerator({
    mobile: 0,
    phablet: 550,
    tablet: 768,
    desktop: 992,
  })
  const OnAtMostTablet = layout.isAtMost('tablet');
  const OnDesktop = layout.is('desktop');
  const {BTCPrice} = useAppContext();
  // const { Panel } = Collapse;

  const list =()=>
    <List 
    itemLayout="horizontal"
    split={false}
    dataSource={service}
    renderItem={(item, i) => (
      <List.Item>
        <List.Item.Meta
        description={<article className='message is-dark'>
          <div className='message-body'>{i+1}. {item.text}</div><a href={item.url}>{ArrowRight()}</a>

        </article>}
        />

      </List.Item>
    )}
      />
    

    
  
  const peersfer = (
    <div class="columns is-desktop">
    <div class="column is-one-third is-offset-1 has-text-centered">
      <div className='peersfer'>
        <h3>在根据您的个人实际情况做完个性化评估后，我们可以给<b>您定制化提供以下几种免费的服务咨询和技术支持：</b></h3> 
        <figure style={{marginTop:'150px'}}>
                <img src={ require('../image/illustrators/rocket_boy.png').default} />
        </figure>
      </div>
    </div>
    <div class="column faq-background">
      
        {list()}
      
    </div>
  </div>
  )

  

  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    <div id={'transfer'}>
      <div >
        <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} />     
        <div className="hero-body">
          <div className="container">
            <div className="columns ">
              <div className="column is-4 is-offset-1 landing-caption">
                <div>
                  <h1 className='is-bold'>
                  您的私房钱一站式解决方案
                  </h1>
                  
                <h3>
                跨境金融 · 贵金属 · 区块链加密资产 · 隐形消费 </h3>
                <h3> 私人定制财富管理 · 储蓄与贷款 · 合理避税 </h3>
                {/* <h3 className='is-5'><span className='tag is-link is-light is-bold is-medium'>私人定制财富管理 · 储蓄与贷款</span> </h3>  */}
                {/* <div class="tags has-addons is-bold has-text-justified">
                  <span class="tag is-medium is-link  ">当前 1 枚比特币 BTC 价格为: </span>
                  <span class="tag is-medium is-link is-light">{BTCPrice} 美元</span>
                </div> */}

                <a class="button is-link is-bold" href='#mission'>  
                            我们的使命 {RightArrow()}
                </a>
                </div>
                
                 
              </div>

              <div className="column is-offset-1">
                <OnAtMostTablet>
                <figure >
                </figure> 

                </OnAtMostTablet>
                <OnDesktop>
                <img style={{width:'570px'}} src="LandingColor.png"/>
                </OnDesktop>
              </div>
              
            </div>

          </div>
        </div>
      </div>
      <div className='hero-body' id='mission'>
        <div >
          <h1 className='has-text-centered'>为什么选择我们 <span className='has-text-link'>{ShieldCheck()}</span></h1>
          <div className='columns'>
            <div className='column is-2'>
            </div>
            <div className='column '>
            <div className='widget' id='whyUs'>
            <h3><i class="fas fa-quote-left"></i></h3> <div><h3>我们倡导对自己隐私有需求的朋友，能完全靠区块链加密资产生活，圈内最佳实践可以做到生活的方方面面 100% 只使用加密资产。
              </h3>
              <h3>
              这种生活方式将会让您从此打开新世界的大门：再无人知晓您的私房钱和小金库，资产从此再无国界限制，可以全年 7 天 24 小时转账给世界上任何角落的人，也再无各类棘手的 CRS 和税务相关的烦恼等等。
              </h3>
              </div>

          </div>
        

            </div>
            <div className='column is-2'>
            </div>

          </div>
          </div>
      </div>

      {/* <div className='hero-body '>
        <div className='columns feature'>
          <div className='column'></div>
          <div className='column '>
            <div className='widget'>
                <p className='heading'>快速到账</p>
                <figure>
                  <img src={ require('../image/illustrators/rocket_launch.png')} />
                </figure> 
                <div className='plain-text'>
                  <p>美、欧、加、澳、新加坡元，港币，英镑数小时内到账</p>
                  <p>其它支持的法币 1-3 个工作日</p>
                  
                </div>
            </div>
          </div>
          <div className='column '>
            <div className='widget'>
              <p>截止到 {businessInfo.date}</p>
              <p>客户买卖加密货币金额</p>
              <span class="tag is-info is-large is-bold is-rounded is-light">{businessInfo.amount} </span>
                <figure>
                  <img src={ require('../image/illustrators/card.jpg')} />
                </figure>  
              
            </div>
          </div>
          <div className='column '>
            <div className='widget'>
              <p className='heading'>无需费用</p>
              <figure>
                  <img src={ require('../image/illustrators/adventure.png')} />
                </figure>
                <div className='plain-text'>
                  <p>我们作为咨询方不收取任何额外费用</p>
                </div>
            </div>
          </div>
          <div className='column'></div>
        </div>
      </div> */}

      <div className='hero-body' style={{marginBottom:'15%',paddingTop:'15%'}}>     
        {peersfer}
      </div>     
    </div>

    <Footer/>
   
</>
  );
}

