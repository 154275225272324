import React from "react";
// import "./Home.css";
import Nav from "./Nav"
import Footer from '../components/footer'
import { layoutGenerator } from 'react-break';
import {Collapse, BackTop } from 'antd';
import {faq} from './text';
import EarningCal from "../components/earningCal"
export default function Earning(props) {
  const layout = layoutGenerator({
    mobile: 0,
    phablet: 550,
    tablet: 768,
    desktop: 992,
  })
  const OnAtMostTablet = layout.isAtMost('tablet');
  const OnDesktop = layout.is('desktop');
  const { Panel } = Collapse;

  const list = (array) => array.map( (item, i) =>
    <Panel header={item.header} key = {i+1}>
            <div className="message is-link">
                <p className="message-body">{item.text}</p>
            </div>
    </Panel>
)
    
    
  
  const peersfer = (
    <div class="columns is-desktop">
    <div class="column is-one-third is-offset-1 has-text-centered">
      <div className='peersfer'>
        <h3>在根据您的个人实际情况做完个性化评估后，我们可以给<b>您定制化提供以下几种免费的服务咨询和技术支持：</b></h3> 
        <figure style={{marginTop:'150px'}}>
                <img src={ require('../image/illustrators/rocket_boy.png').default} />
        </figure>
      </div>
    </div>
    <div class="column faq-background">
      
        {list(faq.cryptoSaving)}
      
    </div>
  </div>
  )

  

  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    <div id={'transfer'}>
      <div >
        <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} />     
        <div className="hero-body">
          <div className="container">
            <div className="columns ">
              <div className="column is-4 is-offset-1 landing-caption">
                <div>
                  <h1 className='is-bold'>
                  私房钱的固定收益<br/>
                  </h1>
                  
                <h3>
                稳健投资 · 无惧波动 · 满意的收益 </h3>
                <h3> 支持 BTC, ETH 等 8 种主流加密货币 </h3>
                {/* <h3 className='is-5'><span className='tag is-link is-light is-bold is-medium'>私人定制财富管理 · 储蓄与贷款</span> </h3>  */}
                {/* <div class="tags has-addons is-bold has-text-justified">
                  <span class="tag is-medium is-link  ">当前 1 枚比特币 BTC 价格为: </span>
                  <span class="tag is-medium is-link is-light">{BTCPrice} 美元</span>
                </div> */}

                <a class="button is-link is-bold" href='#mission'>  
                            <p>开启私房钱财富之旅 <span><i class="fas fa-unlock-alt"></i></span></p>
                </a>
                </div>
                
                 
              </div>

              <div className="column is-offset-1">
                <OnAtMostTablet>
                <figure >
                </figure> 

                </OnAtMostTablet>
                <OnDesktop>
                <img style={{width:'570px'}} src="Bitcoin-rafiki.png"/>
                </OnDesktop>
              </div>
              
            </div>

          </div>
        </div>
      </div>

      <div className='hero-body'>
        <div className='columns feature'>
          <div className='column'></div>
          <div className='column '>
            <div className='widget'>
                <p className='heading'>随存随取 即时到账</p>
                <figure>
                  <img src={ require('../image/illustrators/rocket_launch.png').default} />
                </figure> 
                <div className='plain-text'>
                  <p>利息每日计算，私房钱随存随取，尽享“财务自由”</p>  
                </div>
            </div>
          </div>
          <div className='column '>
            <div className='widget'>
              <p className='heading'> 24/7 在线支持 <i class="fas fa-headset"></i></p>
                <figure>
                  <img src={ require('../image/illustrators/Delivery-bro.png').default} />
                </figure>  
                <div className='plain-text'>
                  <p>利息每日计算，私房钱随存随取，尽享“财务自由”</p>  
                </div>
            </div>
          </div>
          <div className='column '>
            <div className='widget'>
              <p className='heading'>支持 6 种稳定币</p>
              <figure>
                  <img src={ require('../image/illustrators/adventure.png').default} />
                </figure>
                <div className='plain-text'>
                  <p>支持 18 种加密货币，包括 6 种稳定币</p>
                  
                </div>
            </div>
          </div>
          <div className='column'></div>
        </div>
      </div>

      <div className='hero-body' style={{marginBottom:"10%"}}>
        
          
        <div className='columns'>
            
            <div className='column '><h1 className='has-text-centered'>收益计算器 <span className='has-text-link'><i class="fas fa-calculator"></i></span></h1>
                <div className='widget' id ="calculator"> 
                    <EarningCal/>
                </div>
            </div>
            
            <div class="column faq-background is-7" style={{marginLeft:'5%'}}>
                <Collapse defaultActiveKey={['1']} ghost={true} bordered={false} style={{fontSize:'17px'}}>
                    {list(faq.cryptoSaving)}   
                </Collapse> 
            </div>
            
        </div>
        
          
      </div>

    </div>

    <Footer/>
   
</>
  );
}

