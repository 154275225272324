import React from "react";
export default function CountrListFull(props){
    return (
    // <select name="beneficiary_country" required="">
    <select value={props.form.beneficialCountry} onChange={e=>props.setForm({...props.form, beneficialCountry: e.target.value})}>
        <option value="AX">Aland Islands</option>
        <option value="AL">Albania</option>
        <option value="DZ">Algeria</option>
        <option value="AS">American Samoa</option>
        <option value="AD">Andorra</option>
        <option value="AO">Angola</option>
        <option value="AI">Anguilla</option>
        <option value="AQ">Antarctica</option>
        <option value="AG">Antigua and Barbuda</option>
        <option value="AR">Argentina</option>
        <option value="AM">Armenia</option>
        <option value="AW">Aruba</option>
        <option value="AP">Asia/Pacific Region</option>
        <option value="AU">Australia</option>
        <option value="AT">Austria</option>
        <option value="AZ">Azerbaijan</option>
        <option value="BS">Bahamas</option>
        <option value="BH">Bahrain</option>
        <option value="BD">Bangladesh</option>
        <option value="BB">Barbados</option>
        <option value="BY">Belarus</option>
        <option value="BE">Belgium</option>
        <option value="BZ">Belize</option>
        <option value="BJ">Benin</option>
        <option value="BM">Bermuda</option>
        <option value="BT">Bhutan</option>
        <option value="BO">Bolivia</option>
        <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
        <option value="BA">Bosnia and Herzegovina</option>
        <option value="BW">Botswana</option>
        <option value="BV">Bouvet Island</option>
        <option value="BR">Brazil</option>
        <option value="IO">British Indian Ocean Territory</option>
        <option value="BN">Brunei Darussalam</option>
        <option value="BG">Bulgaria</option>
        <option value="BF">Burkina Faso</option>
        <option value="BI">Burundi</option>
        <option value="KH">Cambodia</option>
        <option value="CM">Cameroon</option>
        <option value="CA">Canada</option>
        <option value="CV">Cape Verde</option>
        <option value="KY">Cayman Islands</option>
        <option value="CF">Central African Republic</option>
        <option value="TD">Chad</option>
        <option value="CL">Chile</option>
        <option value="CN">China</option>
        <option value="CX">Christmas Island</option>
        <option value="CC">Cocos (Keeling) Islands</option>
        <option value="CO">Colombia</option>
        <option value="KM">Comoros</option>
        <option value="CG">Congo</option>
        <option value="CK">Cook Islands</option>
        <option value="CR">Costa Rica</option>
        <option value="HR">Croatia</option>
        <option value="CW">Curacao</option>
        <option value="CY">Cyprus</option>
        <option value="CZ">Czech Republic</option>
        <option value="DK">Denmark</option>
        <option value="DJ">Djibouti</option>
        <option value="DM">Dominica</option>
        <option value="DO">Dominican Republic</option>
        <option value="EC">Ecuador</option>
        <option value="EG">Egypt</option>
        <option value="SV">El Salvador</option>
        <option value="GQ">Equatorial Guinea</option>
        <option value="ER">Eritrea</option>
        <option value="ET">Ethiopia</option>
        <option value="EU">Europe</option>
        <option value="FK">Falkland Islands (Malvinas)</option>
        <option value="FO">Faroe Islands</option>
        <option value="FJ">Fiji</option>
        <option value="FI">Finland</option>
        <option value="FR">France</option>
        <option value="GF">French Guiana</option>
        <option value="PF">French Polynesia</option>
        <option value="TF">French Southern Territories</option>
        <option value="GA">Gabon</option>
        <option value="GM">Gambia</option>
        <option value="GE">Georgia</option>
        <option value="DE">Germany</option>
        <option value="GH">Ghana</option>
        <option value="GI">Gibraltar</option>
        <option value="GR">Greece</option>
        <option value="GL">Greenland</option>
        <option value="GD">Grenada</option>
        <option value="GP">Guadeloupe</option>
        <option value="GU">Guam</option>
        <option value="GT">Guatemala</option>
        <option value="GG">Guernsey</option>
        <option value="GN">Guinea</option>
        <option value="GW">Guinea-Bissau</option>
        <option value="GY">Guyana</option>
        <option value="HT">Haiti</option>
        <option value="HM">Heard Island and McDonald Islands</option>
        <option value="VA">Holy See (Vatican City State)</option>
        <option value="HN">Honduras</option>
        <option value="HK">Hong Kong</option>
        <option value="HU">Hungary</option>
        <option value="IS">Iceland</option>
        <option value="IN">India</option>
        <option value="ID">Indonesia</option>
        <option value="IE">Ireland</option>
        <option value="IM">Isle of Man</option>
        <option value="IL">Israel</option>
        <option value="IT">Italy</option>
        <option value="JM">Jamaica</option>
        <option value="JP">Japan</option>
        <option value="JE">Jersey</option>
        <option value="JO">Jordan</option>
        <option value="KZ">Kazakhstan</option>
        <option value="KE">Kenya</option>
        <option value="KI">Kiribati</option>
        <option value="KR">Korea, Republic of</option>
        <option value="KW">Kuwait</option>
        <option value="KG">Kyrgyzstan</option>
        <option value="LA">Lao People's Democratic Republic</option>
        <option value="LV">Latvia</option>
        <option value="LS">Lesotho</option>
        <option value="LR">Liberia</option>
        <option value="LY">Libyan Arab Jamahiriya</option>
        <option value="LI">Liechtenstein</option>
        <option value="LT">Lithuania</option>
        <option value="LU">Luxembourg</option>
        <option value="MO">Macao</option>
        <option value="MK">Macedonia</option>
        <option value="MG">Madagascar</option>
        <option value="MW">Malawi</option>
        <option value="MY">Malaysia</option>
        <option value="MV">Maldives</option>
        <option value="ML">Mali</option>
        <option value="MT">Malta</option>
        <option value="MH">Marshall Islands</option>
        <option value="MQ">Martinique</option>
        <option value="MR">Mauritania</option>
        <option value="MU">Mauritius</option>
        <option value="YT">Mayotte</option>
        <option value="MX">Mexico</option>
        <option value="FM">Micronesia, Federated States of</option>
        <option value="MD">Moldova, Republic of</option>
        <option value="MC">Monaco</option>
        <option value="MN">Mongolia</option>
        <option value="ME">Montenegro</option>
        <option value="MS">Montserrat</option>
        <option value="MA">Morocco</option>
        <option value="MZ">Mozambique</option>
        <option value="NA">Namibia</option>
        <option value="NR">Nauru</option>
        <option value="NP">Nepal</option>
        <option value="NL">Netherlands</option>
        <option value="NC">New Caledonia</option>
        <option value="NZ">New Zealand</option>
        <option value="NI">Nicaragua</option>
        <option value="NE">Niger</option>
        <option value="NG">Nigeria</option>
        <option value="NU">Niue</option>
        <option value="NF">Norfolk Island</option>
        <option value="MP">Northern Mariana Islands</option>
        <option value="NO">Norway</option>
        <option value="OM">Oman</option>
        <option value="O1">Other Country</option>
        <option value="PK">Pakistan</option>
        <option value="PW">Palau</option>
        <option value="PS">Palestinian Territory</option>
        <option value="PA">Panama</option>
        <option value="PG">Papua New Guinea</option>
        <option value="PY">Paraguay</option>
        <option value="PE">Peru</option>
        <option value="PH">Philippines</option>
        <option value="PN">Pitcairn</option>
        <option value="PL">Poland</option>
        <option value="PT">Portugal</option>
        <option value="PR">Puerto Rico</option>
        <option value="QA">Qatar</option>
        <option value="RE">Reunion</option>
        <option value="RO">Romania</option>
        <option value="RU">Russian Federation</option>
        <option value="RW">Rwanda</option>
        <option value="BL">Saint Bartelemey</option>
        <option value="SH">Saint Helena</option>
        <option value="KN">Saint Kitts and Nevis</option>
        <option value="LC">Saint Lucia</option>
        <option value="MF">Saint Martin</option>
        <option value="PM">Saint Pierre and Miquelon</option>
        <option value="VC">Saint Vincent and the Grenadines</option>
        <option value="WS">Samoa</option>
        <option value="SM">San Marino</option>
        <option value="ST">Sao Tome and Principe</option>
        <option value="SA">Saudi Arabia</option>
        <option value="SN">Senegal</option>
        <option value="RS">Serbia</option>
        <option value="SC">Seychelles</option>
        <option value="SG">Singapore</option>
        <option value="SX">Sint Maarten</option>
        <option value="SK">Slovakia</option>
        <option value="SI">Slovenia</option>
        <option value="SB">Solomon Islands</option>
        <option value="SO">Somalia</option>
        <option value="ZA">South Africa</option>
        <option value="GS">South Georgia and the South Sandwich Islands</option>
        <option value="SS">South Sudan</option>
        <option value="ES">Spain</option>
        <option value="LK">Sri Lanka</option>
        <option value="SR">Suriname</option>
        <option value="SJ">Svalbard and Jan Mayen</option>
        <option value="SZ">Swaziland</option>
        <option value="SE">Sweden</option>
        <option value="CH">Switzerland</option>
        <option value="TW">Taiwan</option>
        <option value="TJ">Tajikistan</option>
        <option value="TZ">Tanzania, United Republic of</option>
        <option value="TH">Thailand</option>
        <option value="TL">Timor-Leste</option>
        <option value="TG">Togo</option>
        <option value="TK">Tokelau</option>
        <option value="TO">Tonga</option>
        <option value="TT">Trinidad and Tobago</option>
        <option value="TN">Tunisia</option>
        <option value="TR">Turkey</option>
        <option value="TM">Turkmenistan</option>
        <option value="TC">Turks and Caicos Islands</option>
        <option value="TV">Tuvalu</option>
        <option value="UG">Uganda</option>
        <option value="UA">Ukraine</option>
        <option value="AE">United Arab Emirates</option>
        <option value="GB">United Kingdom</option>
        <option value="US">United States</option>
        <option value="UM">United States Minor Outlying Islands</option>
        <option value="UY">Uruguay</option>
        <option value="UZ">Uzbekistan</option>
        <option value="VU">Vanuatu</option>
        <option value="VE">Venezuela</option>
        <option value="VN">Vietnam</option>
        <option value="VG">Virgin Islands, British</option>
        <option value="VI">Virgin Islands, U.S.</option>
        <option value="WF">Wallis and Futuna</option>
        <option value="EH">Western Sahara</option>
        <option value="ZM">Zambia</option>
        <option value="ZW">Zimbabwe</option>
    </select>
    );

}

function CountryDisabled(){
    return(
         <>
         <option disabled value="AX">Aland Islands</option>
         <option disabled value="AL">Albania</option>
         <option disabled value="DZ">Algeria</option>
         <option disabled value="AS">American Samoa</option>
         <option disabled value="AD">Andorra</option>
         <option disabled value="AO">Angola</option>
         <option disabled value="AI">Anguilla</option>
         <option disabled value="AQ">Antarctica</option>
         <option disabled value="AG">Antigua and Barbuda</option>
         <option disabled value="AR">Argentina</option>
         <option disabled value="AM">Armenia</option>
         <option disabled value="AW">Aruba</option>
         <option disabled value="AP">Asia/Pacific Region</option>
         {/* <option disabled value="AU">Australia</option> */}
         <option disabled value="AT">Austria</option>
         <option disabled value="AZ">Azerbaijan</option>
         <option disabled value="BS">Bahamas</option>
         <option disabled value="BH">Bahrain</option>
         <option disabled value="BD">Bangladesh</option>
         <option disabled value="BB">Barbados</option>
         <option disabled value="BY">Belarus</option>
         <option disabled value="BE">Belgium</option>
         <option disabled value="BZ">Belize</option>
         <option disabled value="BJ">Benin</option>
         <option disabled value="BM">Bermuda</option>
         <option disabled value="BT">Bhutan</option>
         <option disabled value="BO">Bolivia</option>
         <option disabled value="BQ">Bonaire, Saint Eustatius and Saba</option>
         <option disabled value="BA">Bosnia and Herzegovina</option>
         <option disabled value="BW">Botswana</option>
         <option disabled value="BV">Bouvet Island</option>
         <option disabled value="BR">Brazil</option>
         <option disabled value="IO">British Indian Ocean Territory</option>
         <option disabled value="BN">Brunei Darussalam</option>
         <option disabled value="BG">Bulgaria</option>
         <option disabled value="BF">Burkina Faso</option>
         <option disabled value="BI">Burundi</option>
         <option disabled value="KH">Cambodia</option>
         <option disabled value="CM">Cameroon</option>
         {/* <option disabled value="CA">Canada</option> */}
         <option disabled value="CV">Cape Verde</option>
         <option disabled value="KY">Cayman Islands</option>
         <option disabled value="CF">Central African Republic</option>
         <option disabled value="TD">Chad</option>
         <option disabled value="CL">Chile</option>
         <option disabled value="CN">China</option>
         <option disabled value="CX">Christmas Island</option>
         <option disabled value="CC">Cocos (Keeling) Islands</option>
         <option disabled value="CO">Colombia</option>
         <option disabled value="KM">Comoros</option>
         <option disabled value="CG">Congo</option>
         <option disabled value="CK">Cook Islands</option>
         <option disabled value="CR">Costa Rica</option>
         <option disabled value="HR">Croatia</option>
         <option disabled value="CW">Curacao</option>
         <option disabled value="CY">Cyprus</option>
         <option disabled value="CZ">Czech Republic</option>
         <option disabled value="DK">Denmark</option>
         <option disabled value="DJ">Djibouti</option>
         <option disabled value="DM">Dominica</option>
         <option disabled value="DO">Dominican Republic</option>
         <option disabled value="EC">Ecuador</option>
         <option disabled value="EG">Egypt</option>
         <option disabled value="SV">El Salvador</option>
         <option disabled value="GQ">Equatorial Guinea</option>
         <option disabled value="ER">Eritrea</option>
         <option disabled value="ET">Ethiopia</option>
         <option disabled value="EU">Europe</option>
         <option disabled value="FK">Falkland Islands (Malvinas)</option>
         <option disabled value="FO">Faroe Islands</option>
         <option disabled value="FJ">Fiji</option>
         <option disabled value="FI">Finland</option>
         <option disabled value="FR">France</option>
         <option disabled value="GF">French Guiana</option>
         <option disabled value="PF">French Polynesia</option>
         <option disabled value="TF">French Southern Territories</option>
         <option disabled value="GA">Gabon</option>
         <option disabled value="GM">Gambia</option>
         <option disabled value="GE">Georgia</option>
         <option disabled value="DE">Germany</option>
         <option disabled value="GH">Ghana</option>
         <option disabled value="GI">Gibraltar</option>
         <option disabled value="GR">Greece</option>
         <option disabled value="GL">Greenland</option>
         <option disabled value="GD">Grenada</option>
         <option disabled value="GP">Guadeloupe</option>
         <option disabled value="GU">Guam</option>
         <option disabled value="GT">Guatemala</option>
         <option disabled value="GG">Guernsey</option>
         <option disabled value="GN">Guinea</option>
         <option disabled value="GW">Guinea-Bissau</option>
         <option disabled value="GY">Guyana</option>
         <option disabled value="HT">Haiti</option>
         <option disabled value="HM">Heard Island and McDonald Islands</option>
         <option disabled value="VA">Holy See (Vatican City State)</option>
         <option disabled value="HN">Honduras</option>
         {/* <option disabled value="HK">Hong Kong</option> */}
         <option disabled value="HU">Hungary</option>
         <option disabled value="IS">Iceland</option>
         <option disabled value="IN">India</option>
         <option disabled value="ID">Indonesia</option>
         <option disabled value="IE">Ireland</option>
         <option disabled value="IM">Isle of Man</option>
         <option disabled value="IL">Israel</option>
         <option disabled value="IT">Italy</option>
         <option disabled value="JM">Jamaica</option>
         <option disabled value="JP">Japan</option>
         <option disabled value="JE">Jersey</option>
         <option disabled value="JO">Jordan</option>
         <option disabled value="KZ">Kazakhstan</option>
         <option disabled value="KE">Kenya</option>
         <option disabled value="KI">Kiribati</option>
         <option disabled value="KR">Korea, Republic of</option>
         <option disabled value="KW">Kuwait</option>
         <option disabled value="KG">Kyrgyzstan</option>
         <option disabled value="LA">Lao People's Democratic Republic</option>
         <option disabled value="LV">Latvia</option>
         <option disabled value="LS">Lesotho</option>
         <option disabled value="LR">Liberia</option>
         <option disabled value="LY">Libyan Arab Jamahiriya</option>
         <option disabled value="LI">Liechtenstein</option>
         <option disabled value="LT">Lithuania</option>
         <option disabled value="LU">Luxembourg</option>
         <option disabled value="MO">Macao</option>
         <option disabled value="MK">Macedonia</option>
         <option disabled value="MG">Madagascar</option>
         <option disabled value="MW">Malawi</option>
         <option disabled value="MY">Malaysia</option>
         <option disabled value="MV">Maldives</option>
         <option disabled value="ML">Mali</option>
         <option disabled value="MT">Malta</option>
         <option disabled value="MH">Marshall Islands</option>
         <option disabled value="MQ">Martinique</option>
         <option disabled value="MR">Mauritania</option>
         <option disabled value="MU">Mauritius</option>
         <option disabled value="YT">Mayotte</option>
         <option disabled value="MX">Mexico</option>
         <option disabled value="FM">Micronesia, Federated States of</option>
         <option disabled value="MD">Moldova, Republic of</option>
         <option disabled value="MC">Monaco</option>
         <option disabled value="MN">Mongolia</option>
         <option disabled value="ME">Montenegro</option>
         <option disabled value="MS">Montserrat</option>
         <option disabled value="MA">Morocco</option>
         <option disabled value="MZ">Mozambique</option>
         <option disabled value="NA">Namibia</option>
         <option disabled value="NR">Nauru</option>
         <option disabled value="NP">Nepal</option>
         <option disabled value="NL">Netherlands</option>
         <option disabled value="NC">New Caledonia</option>
         {/* <option disabled value="NZ">New Zealand</option> */}
         <option disabled value="NI">Nicaragua</option>
         <option disabled value="NE">Niger</option>
         <option disabled value="NG">Nigeria</option>
         <option disabled value="NU">Niue</option>
         <option disabled value="NF">Norfolk Island</option>
         <option disabled value="MP">Northern Mariana Islands</option>
         <option disabled value="NO">Norway</option>
         <option disabled value="OM">Oman</option>
         <option disabled value="O1">Other Country</option>
         <option disabled value="PK">Pakistan</option>
         <option disabled value="PW">Palau</option>
         <option disabled value="PS">Palestinian Territory</option>
         <option disabled value="PA">Panama</option>
         <option disabled value="PG">Papua New Guinea</option>
         <option disabled value="PY">Paraguay</option>
         <option disabled value="PE">Peru</option>
         <option disabled value="PH">Philippines</option>
         <option disabled value="PN">Pitcairn</option>
         <option disabled value="PL">Poland</option>
         <option disabled value="PT">Portugal</option>
         <option disabled value="PR">Puerto Rico</option>
         <option disabled value="QA">Qatar</option>
         <option disabled value="RE">Reunion</option>
         <option disabled value="RO">Romania</option>
         <option disabled value="RU">Russian Federation</option>
         <option disabled value="RW">Rwanda</option>
         <option disabled value="BL">Saint Bartelemey</option>
         <option disabled value="SH">Saint Helena</option>
         <option disabled value="KN">Saint Kitts and Nevis</option>
         <option disabled value="LC">Saint Lucia</option>
         <option disabled value="MF">Saint Martin</option>
         <option disabled value="PM">Saint Pierre and Miquelon</option>
         <option disabled value="VC">Saint Vincent and the Grenadines</option>
         <option disabled value="WS">Samoa</option>
         <option disabled value="SM">San Marino</option>
         <option disabled value="ST">Sao Tome and Principe</option>
         <option disabled value="SA">Saudi Arabia</option>
         <option disabled value="SN">Senegal</option>
         <option disabled value="RS">Serbia</option>
         <option disabled value="SC">Seychelles</option>
         {/* <option disabled value="SG">Singapore</option> */}
         <option disabled value="SX">Sint Maarten</option>
         <option disabled value="SK">Slovakia</option>
         <option disabled value="SI">Slovenia</option>
         <option disabled value="SB">Solomon Islands</option>
         <option disabled value="SO">Somalia</option>
         <option disabled value="ZA">South Africa</option>
         <option disabled value="GS">South Georgia and the South Sandwich Islands</option>
         <option disabled value="SS">South Sudan</option>
         <option disabled value="ES">Spain</option>
         <option disabled value="LK">Sri Lanka</option>
         <option disabled value="SR">Suriname</option>
         <option disabled value="SJ">Svalbard and Jan Mayen</option>
         <option disabled value="SZ">Swaziland</option>
         <option disabled value="SE">Sweden</option>
         <option disabled value="CH">Switzerland</option>
         <option disabled value="TW">Taiwan</option>
         <option disabled value="TJ">Tajikistan</option>
         <option disabled value="TZ">Tanzania, United Republic of</option>
         {/* <option disabled value="TH">Thailand</option> */}
         <option disabled value="TL">Timor-Leste</option>
         <option disabled value="TG">Togo</option>
         <option disabled value="TK">Tokelau</option>
         <option disabled value="TO">Tonga</option>
         <option disabled value="TT">Trinidad and Tobago</option>
         <option disabled value="TN">Tunisia</option>
         <option disabled value="TR">Turkey</option>
         <option disabled value="TM">Turkmenistan</option>
         <option disabled value="TC">Turks and Caicos Islands</option>
         <option disabled value="TV">Tuvalu</option>
         <option disabled value="UG">Uganda</option>
         <option disabled value="UA">Ukraine</option>
         <option disabled value="AE">United Arab Emirates</option>
         <option disabled value="GB">United Kingdom</option>
         {/* <option disabled value="US">United States</option> */}
         <option disabled value="UM">United States Minor Outlying Islands</option>
         <option disabled value="UY">Uruguay</option>
         <option disabled value="UZ">Uzbekistan</option>
         <option disabled value="VU">Vanuatu</option>
         <option disabled value="VE">Venezuela</option>
         <option disabled value="VN">Vietnam</option>
         <option disabled value="VG">Virgin Islands, British</option>
         <option disabled value="VI">Virgin Islands, U.S.</option>
         <option disabled value="WF">Wallis and Futuna</option>
         <option disabled value="EH">Western Sahara</option>
         <option disabled value="ZM">Zambia</option>
         <option disabled value="ZW">Zimbabwe</option>
 
         </>
    )
 }

export function CountryPart(props){
    return(
        <>
        <select value={props.form.bankCountry} onChange={e=>props.setForm({...props.form, bankCountry: e.target.value})} >
            <option value="HK">Hong Kong</option>
            <option value="SG">Singapore</option>
            <option value="US">United States</option>
            <option value="GB">United Kingdom</option>
            <option value="AU">Australia</option>
            <option value="CA">Canada</option>
            <option value="NZ">New Zealand</option>
            <option value="TH">Thailand</option>
            {CountryDisabled()}
        </select>
        
        
        </>
    )
}

