import React from "react";
import { Route, Switch } from "react-router-dom";
import Crypto from "./containers/Crypto";
import Saving from "./containers/Saving";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup"
import ChangePassword from "./containers/ChangePassword"
import Dashboard from "./containers/Dashboard";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import PlanList from "./components/planList";
import Gold from "./containers/Gold"
import Payment from "./containers/Payment"
import Account from "./containers/Account"
import Exchange from "./containers/Exchange"
import Landing from "./containers/Landing"
import Earning from "./containers/Earn"
import Loan from "./containers/Loan"
import OrderStatus from "./containers/OrderStatus"
import CheckOrder from "./containers/CheckOrder"
import ForgotPassword from "./containers/ForgotPassword";
import Deposit from "./components/deposit";
import Withdrawal from "./components/withdrawal";
import InterestHistory from "./components/interestHistory";
import Purchase from "./components/purchase";
import Orders from "./components/orders";
import { BankForm } from "./components/bankForm";
import PurchaseHistory from "./components/purchaseHistory";
export default function Routes({ appProps }) {
    // const {isAuthenticated} = useAppContext();
    return (
      <Switch>
        <Route exact path = "/">
          <Landing />
        </Route>
        <Route exact path = "/crypto">
          <Crypto />
        </Route>
        <Route exact path = "/saving">
          <Saving />
        </Route>
        <Route exact path = "/gold">
          <Gold />
        </Route>
        <Route exact path = "/plan">
          <PlanList />
        </Route>
        <Route exact path = "/payment">
          <Payment />
        </Route>
        <Route exact path = "/account_open">
          <Account />
        </Route>
        <Route exact path = "/exchange">
          <Exchange />
        </Route>
        <Route exact path = "/earning">
          <Earning />
        </Route>
        <Route exact path = "/loan">
          <Loan />
        </Route>
        <Route exact path = "/order">
          <OrderStatus />
        </Route>
        <Route exact path = "/checkOrder">
          <CheckOrder />
        </Route>

        <UnauthenticatedRoute exact path = "/login">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path = "/forgotPassword">
          <ForgotPassword/>
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path = "/signup">
          <Signup />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path = "/dashboard">
          <Dashboard/>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path = "/deposit" >
          <Deposit/>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path = "/buy" >
          <Purchase />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path = "/buy_history" >
          <PurchaseHistory />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path = "/orders" >
          <Orders />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path = "/withdrawal" >
          <Withdrawal/>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path = "/address" >
          <BankForm />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path = "/interest" >
          <InterestHistory/>
        </AuthenticatedRoute>
        
        <AuthenticatedRoute exact path = "/changePassword">
          <ChangePassword/>
        </AuthenticatedRoute>
        
        
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
      </Switch>
    );
  }