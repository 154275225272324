import React, { useState, useEffect} from "react";
import { Menu, Dropdown} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {Auth} from "aws-amplify";
import { Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import FootNav from '../components/footNav'
import { layoutGenerator } from 'react-break';
import {Logout, Setting} from '../components/svg'
import { failure } from "../components/notifications";


export default function Nav(props){
	
	const { isAuthenticated, userHasAuthenticated, userInfo, setUserInfo } = useAppContext();
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [toggle, setToggle] = useState("")
	const history = useHistory();
	const [isActive, setIsActive] = useState("")
	const layout = layoutGenerator({
		mobile: 0,
		phablet: 550,
		tablet: 768,
		desktop: 992,
	  });
	
	
	const OnAtMostTablet = layout.isAtMost('tablet');
	useEffect(() => {
		onLoad();
	  }, []);
	  
	  async function onLoad() {
		try {
		  	await Auth.currentSession();
		  	userHasAuthenticated(true);
				
		}
		catch(e) {
		//   if (e !== 'No current user') {
		// 	const title = '用户异常'
		// 	const description = e.message
		// 	failure(title, description)
		//   }
		}
		const remainTime = localStorage.getItem('exp') - Date.now()
		if (remainTime<=0){
			localStorage.removeItem('order')
		}

	  
		setIsAuthenticating(false);
	  };
			
	  
	  async function handleLogout() {
		await Auth.signOut();
		userHasAuthenticated(false);
		history.push("/login");
	}
	

	const menu = (
		<Menu>
			<Menu.Item key="0" disabled>
			<b className='has-text-link'>{userInfo.email}</b>
			</Menu.Item>
			<Menu.Item key="1" disabled>
			银行信息
			</Menu.Item>
			<Menu.Item key="2" >
			<Link to="/changePassword" className='has-text-dark'>修改登录密码</Link>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="3" onClick={handleLogout}>
				<span>登出 <Logout /></span>
			</Menu.Item>
		</Menu>
	)

	

	const menuStyle = {
		backgroundColor:"transparent", 
		"border-bottom":'1px solid transparent'
	}


	function navMenu(){
		return(
			<div id="navbarBasic" className={"navbar-menu"}>
			<div className="navbar-end">
			  <Menu mode="horizontal" style={menuStyle}  selectedKeys={[null]}>
				<Menu.Item key="0">

				  <Link to="/crypto" >加密货币</Link>

			  </Menu.Item>

			  <Menu.Item key="1">
					
				<div class={"dropdown "+isActive}>
					<div class="dropdown-trigger">
						<a aria-haspopup="true" aria-controls="dropdown-menu" onClick={()=>isActive==""?setIsActive("is-active"):setIsActive("")}>储蓄与贷款 <DownOutlined /></a>
					</div>
					<div class="dropdown-menu" id="dropdown-menu" role="menu">
						<div class="dropdown-content">
							{/* <Link to='/saving'class="dropdown-item">
								美元储蓄
							</Link> */}
							<Link to='/earning'class="dropdown-item">
								加密货币理财
							</Link>
							<Link to='/loan'class="dropdown-item">
								抵币贷款
							</Link>
						
						</div>
					</div>
				</div>	
					
					
					
      					

			  </Menu.Item>

			  <Menu.Item key="2">
				  <Link to="/gold" >黄金投资</Link>
			  </Menu.Item>

			  <Menu.Item key="3">
				  <Link to="/payment" >匿名支付</Link>
			  </Menu.Item>

			  <Menu.Item key="4">
				  <Link to="/account_open" >海外开户</Link>
			  </Menu.Item>
			  <Menu.Item key="5">
				  <Link to="/exchange" >加密货币闪兑<sup class="navbadge pulsate">New</sup></Link>
			  </Menu.Item>

			  <Menu.Item key="6">

			  {isAuthenticated&&<Link to="/dashboard">资产中心</Link>}

			  </Menu.Item>

			  </Menu>

			  
			   
			  



			  {/* onClick={handleLogout}  */}
			  { isAuthenticated?
			  
				  <Dropdown overlay={menu} placement="bottomCenter">
					<span id = 'login'className="button navbar-item is-rounded" onClick={e => e.preventDefault()}>
					<Setting />
					</span>
				</Dropdown>
			   
			  : <>
			  <Link to="/signup">
			  <span className="signup button navbar-item is-rounded">
			  注册
			  </span>
			  </Link>
			  <Link to="/login">
			  <span id = 'login' className="button navbar-item  is-rounded">
				登录
			  </span> 
			  </Link> 
			  </>
			  }   
			  </div>
			</div>
		  

		);
	}


	function toggleMenu(){
		toggle === ""?setToggle("is-active"):setToggle("")
	}





	return(
		!isAuthenticating &&
		<>
		
		  <div className='hero-body'>
		  <nav className="navbar">
			<div className="container ">
			  <div className="navbar-brand">
				
				<Link to="/" className='has-text-link' >
				<div className='is-flex'>
					<img style={{width:'300px'}} src="FamilyOfficeLogo.png"/>
				</div>	
					
				</Link>
			
				<a role="button" className={"navbar-burger " + toggle} aria-label="menu" onClick={()=>toggleMenu()} aria-expanded="false" >
				  
				</a>
			  </div>
			
			  {navMenu()}
			 
			
			  </div>
		  </nav>
		  
		  </div>
		  <OnAtMostTablet><FootNav handleLogout={handleLogout}/></OnAtMostTablet>
		</>
	);
}