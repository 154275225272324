import React, {  useEffect, useState} from "react";
import { Space, Radio, Tooltip } from 'antd';
import {RightArrow, ThreeDotsLoader} from '../components/svg'
import {getUserDepositAddr, getUserDepositHistory, newDeposit} from '../containers/API'
import {failure, depositNotification} from '../components/notifications'
import {assetList, findIndexByType} from './selfDefine'
import { useAppContext } from "../libs/contextLib";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Stat, StatLabel, StatNumber, Center, Text, HStack, Stack} from "@chakra-ui/react"
import { CopyOne, Receive, HourglassFull, FaceWithSmilingOpenEyes} from "@icon-park/react";
import Nav from "../containers/Nav";
let QRCode = require('qrcode.react');

export default function Deposit(props){
	const location = useLocation()
	const history = useHistory()
	const { type, available } = location.state
	const [valid, setValid] = useState(false)
	const [data, setData] = useState()
	const [value, setValue] = useState(0)

	async function onLoad(){
		try {
			const result = await getUserDepositAddr();
			console.log(result);
			if (result){
				setData(result)
				setParams(result.address[0])
				setValid(true)	
			}else{
				setValid(false)
			}

		}catch(e){
			failure('无法加载', e.message)
		}
	}

	useEffect(()=>{
		onLoad();
	}, [])


	const [params, setParams] = useState({
		network:'',
		address: '',
		memo: ''
	})

	

	function handleSelect(e){
		setParams(data.address[e.target.value]);
		setValue(e.target.value)
	}


	function SelectNet(props){
		const renderOptions = props.list.map((item, i)=><Radio.Button value={i} style={{borderRadius:'10px'}}>{item.network}</Radio.Button>)
		
		return (
			<Radio.Group defaultValue= {0} value={value} buttonStyle="outline" onChange={(e)=>handleSelect(e)}>
				<Space size={12}>
					{renderOptions}
				</Space>
			</Radio.Group>

		)
	
	}
	function renderAddress(){
		return(
			<Box>
				<div className='card round-card'>
					<div className='card-content'> 					
						<Box  m='auto' pt='7%' pb='10%' pl='20px' pr='20px' w='600px'>
							
								<div className='field is-horizontal'>
									<div className='field-body'>
										<div className="field">
											<label className="label">转账网络</label>
											<div className="control ">
												<SelectNet list={data.address}/>	
											</div>
										</div>
									</div>
								</div>
								<div className='field is-horizontal'>
									<div className='field-body'>
										<div className="field">
											<label className="label">{type} 充值地址 </label>
											{/* <p class='help'>请确保地址输入正确</p> */}
											<div className="control">
												<Center pt='2%' pb='3%'>
													<QRCode value = {params.address}/>
												</Center>
												<Center pt='3%'>
													<span className='tag is-large' style={{fontFamily:'monospace'}}>{params.address}
														<Tooltip placement="topRight" title="复制成功" trigger='click'>
															<CopyOne theme="outline" size="24" fill="#9b9b9b" onClick={() => navigator.clipboard.writeText(params.address)} />
														</Tooltip> 
														
													</span>
												</Center>
													{/* <Box pt='10%'>
														<button className='tag is-link is-light is-medium is-bold' onClick={checkHistory}>
															查看充值记录
														</button>
													</Box> */}
											</div>
										</div>
									</div>
								</div>
							
							
						
							
						</Box>
					</div>
				</div>	
				
				
			</Box>
		)
	}

	function renderNote(){
		return(
			
			<Center pt='100%' pb='100%'>
				<Stack spacing={30}>
					<ThreeDotsLoader />
					<Text>钱包生成中，请稍后查看...</Text>
				</Stack>
					
			</Center>
			
		)
	}

	return (
		<div style={{backgroundColor: '#F9FAFC'}}>
				<Nav />
				<div class= 'container' style={{paddingBottom:'20%'}}>
					
					<Box  pt='20px' w='fit-content' m='auto'>
						<Box pb='2%' fontSize='25px'>
							<HStack>
								<Receive theme="outline" size="30" fill="#1972F5" strokeWidth={3}/> 
								<Box>{type} 充值</Box>
							</HStack>
						
						</Box>
						
								{valid?
								renderAddress():
								renderNote()
								}
					</Box>
					
				</div>
				
			</div>
	)


}
