import React, { useEffect, useState } from "react";
import {floor10} from '../components/Decimal'
import {BankForm} from '../components/bankForm'
import BlockchainAddr from '../components/withdrawalAddress'
import {RightArrow} from '../components/svg'
import {Select, Space, Radio, Tabs} from 'antd'
import {assetList, findIndexByType, findThisByType} from './selfDefine'
import { useHistory, useLocation, Link } from "react-router-dom";
import Nav from "../containers/Nav";
import { Box, Input, Stat, StatLabel, StatNumber, Stack, Text, InputGroup, InputRightElement, HStack } from "@chakra-ui/react"
import { AddressBook } from "@icon-park/react";
import { useFormFields } from "../libs/hooksLib";
import { Logo } from "./coinLogo";
import { handleInputNumber } from "../libs/handleInputNumbers";
import { ceil10 } from "../components/Decimal";
import { SendOne } from "@icon-park/react";
import { createWithdrawalRequest, getWithdrawalRate } from "../containers/API";
import { failure, success } from "./notifications";
import { useAppContext } from "../libs/contextLib";
export default function Withdrawal(props){
	const location = useLocation()
	const history = useHistory()
	const { isAuthenticated } = useAppContext()
	const {type, available} = location.state
	const { TabPane } = Tabs;
	const netList = [
		{
			name: "TRC20",
		},
		{
			name: "ERC20"
		},
		{
			name: "BEP20"
		},
	]
	const [blockchainParams, setBlockchainParams] = useState({
		network: netList[0].name,
		address: '',
		amount: '',
		name: type,
	})

	const [fiatParams, setFiatParams] = useState({
		amount: '',
		get: '',
		name: type,
	})
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [withdrawalType, setWithdrawalType] = useState('crypto')
	const [rate, setRate] = useState()

	async function onLoad(){
		if (!isAuthenticated){
			history.push('/login')	  
			return;
		}
		try {
			const result = await getWithdrawalRate(type);
			setRate(result)
			console.log(result)
		}catch(e){
			failure('汇率加载失败', e.message)
		}
	}

	useEffect(()=>{
		onLoad();
	},[]);

	function SelectNet(props){
		const renderOptions = props.list.map((item, i)=><Radio.Button value={item.name} style={{borderRadius:'10px'}}>{item.name}</Radio.Button>)

		return(
			<Radio.Group defaultValue={props.list[0].name} value = {blockchainParams.network} buttonStyle="outline" onChange={(e)=>setBlockchainParams({...blockchainParams, network: e.target.value})}>
				<Space size={12}>
					{renderOptions}
				</Space>
			</Radio.Group>
		)
	
	}

	function calNumbers(e){
        // type: get or pay
		const value = handleInputNumber(e.target.value, 6)
		const spend = ceil10(value*rate, -2).toFixed(2) 
		setFiatParams({...fiatParams, get: spend, amount: value})    
    }

	function withdrawAll(e){	
		setFiatParams({...fiatParams, get: ceil10(available*rate, -2), amount: available})
	}

	function handleSelect(key){
		if (key === '1'){
			setWithdrawalType('crypto')
		}else{
			setWithdrawalType('fiat')
		}
	}

	async function onSubmitTab1(){
		setIsSubmitting(true)
		const data = {
			type: withdrawalType,
			data: blockchainParams
		}
		try{
			await createWithdrawalRequest(data);
			success('成功提交提币申请!', '请耐心等待处理')
			history.push('/dashboard')
		}catch(e){
			failure('提交失败', e.message)
		}
		setIsSubmitting(false)
	}

	function validateTab1(){
		return blockchainParams.address.length>0&&blockchainParams.amount>0&&(blockchainParams.amount<=Number(available))
	}
	function validateTab2(){
		return fiatParams.amount>0&&fiatParams.get>0&&(fiatParams.amount<=Number(available))
	}

	return(
		<div style={{backgroundColor: '#F9FAFC'}}>
			<Nav />
			<div class= 'container'>
				
				<Box  pt='20px' w='450px' m='auto' pb='20%'>
					<Box pb='2%' fontSize='25px'>
						<HStack>
							<SendOne theme="outline" size="30" fill="#1972F5" strokeWidth={3} strokeLinecap="square"/>
							<Box>{type} 提现</Box>
							
						</HStack>
					</Box>
					<div className='card round-card'>
						<div className='card-content'> 
							<Tabs defaultActiveKey="1" onChange={handleSelect}>
								<TabPane tab="加密货币提现" key="1">
									<Box  m='auto' pt='10%' pb='20%' pl='20px' pr='20px'>
										<Stack spacing={5}>
											<Stat>
												<StatLabel>可用余额</StatLabel>
												<StatNumber>{available} {type}</StatNumber>
											</Stat>
											<form>
												<div className='field is-horizontal'>
													<div className='field-body'>
														<div className="field">
															<label className="label">转账网络</label>
															<div className="control ">
																<SelectNet list={netList}/>	
															</div>
														</div>
													</div>
												</div>
												<div className='field is-horizontal'>
													<div className='field-body'>
														<div className="field">
															<label className="label">提币地址 </label>
															{/* <p class='help'>请确保地址输入正确</p> */}
															<div className="control">
																<input className="input" type="text" placeholder="请输入提币地址" value={blockchainParams.address} onChange={(e)=>setBlockchainParams({...blockchainParams, address: e.target.value})}/>
																{/* <span class="icon is-small is-left">
																	<AddressBook theme="outline" size="24" fill="#9b9b9b" strokeWidth={4}/>
																</span> */}
																
															</div>
														</div>
													</div>
												</div>
												<div className='field is-horizontal'>
													<div className='field-body'>
														<div className="field">
															<label className="label">提现金额 </label>
															<div className="control has-icons-right">
																<input className="input" type="text"  value={blockchainParams.amount} onChange={(e)=>setBlockchainParams({...blockchainParams, amount: handleInputNumber(e.target.value, 6)})}/>
																<span class="icon is-small is-right">
																	<Box pr='145px'>
																		<HStack>
																			<span className='tag is-link is-light' onClick={()=>setBlockchainParams({...blockchainParams, amount: available})}>全部提现</span>
																			<Text>USDT</Text>
																			<figure class="image is-24x24">
																				<Logo.USDT />
																			</figure>
																		</HStack>
																	</Box>
																</span>
																
															</div>
														</div>
													</div>
												</div>
											</form>
										</Stack>
										<Space size={12} style={{paddingTop:'5%'}}>
											{
												isSubmitting?<button className='button is-rounded is-bold is-link is-loading'  onClick={onSubmitTab1}>确认</button>:
												<button className='button is-rounded is-bold is-link' disabled={!validateTab1()} onClick={onSubmitTab1}>确认</button>
											}
											
											
											<button className='button is-rounded is-bold' onClick={()=>history.push('/dashboard')}>取消</button>
										</Space>
										
									</Box>		
								</TabPane>
								<TabPane tab="法币提现" key='2'>
									<Box pt='10%' pb='20%' pl='20px' pr='20px'>
										<Stack spacing={35}>
											<Stat>
												<StatLabel>可用余额</StatLabel>
												<StatNumber>{available} {type}</StatNumber>
											</Stat>
											<Box>
												<Text>提现</Text>
												<InputGroup>
													<Input variant="filled" placeholder="" borderRadius="20px" value = {fiatParams.amount} onChange={(e)=>calNumbers(e)}/>
													<InputRightElement children={
														<Box pr='135px'>
															<HStack>
																<button className='tag is-link is-light ' onClick={e=>withdrawAll(e)} > 全部提现</button>
																<Text>USDT</Text>
																<figure class="image is-24x24">
																	<Logo.USDT />
																</figure>
															</HStack>
														</Box>
													}/>
												</InputGroup>
											</Box>
											<Box>
												<Text>得到</Text>
												<InputGroup>
													<Input variant="filled" placeholder="" borderRadius="20px" value ={fiatParams.get}/>
													<InputRightElement children={
														<Box pr='50px'>
															<HStack>
																<Text>USD</Text>
																<figure class="image is-24x24">
																	<Logo.USD />
																</figure>
															</HStack>
														</Box>
													}/>
												</InputGroup>
											</Box>
											<Box>
												<Text>参考价格 1 USD ≈ {(1/rate).toFixed(4)} USDT</Text>
											</Box>
											<Box>
												<Link to={{pathname:'/address', state:{type: withdrawalType, fiatParams: fiatParams}}} class='button is-link is-rounded ' disabled={!validateTab2()}>填写银行信息</Link>
											</Box>
										
										</Stack>
										
									</Box>
								</TabPane>
							</Tabs>
						</div>
					</div>
				</Box>
			</div>
			
		</div>

		
	)


}

//   function balance(type){
//     for (const i in props.assetInfo){
//       if (props.assetInfo[i].type === type){
//         const step = assetList[findIndexByType(type)].precision
//         if (props.assetInfo[i].type === 'GOLD'){
//           const sum = floor10(Number(props.assetInfo[i].balance), -step)
//           return sum
//         }else{
//           const sum = floor10(Number(props.assetInfo[i].balance)+Number(props.assetInfo[i].interest), -step)
//           return sum
//         }
        
//       }
//     }
//   }
//   const usdtBalance = balance('USDT')

//   const [modal, setModal] = useState({
//     modalClass: "modal",
// 	  showForm: false,
//     // wasFormSaved: false,
//     confirmWithdrawal: false,
//     // isModifying: false,
//   })

//   const [withdrawalInfo, setWithdrawalInfo] = useState({
// 	  amount: null, //the withdrawal asset amount
// 	  isLoading: false,
//     type: "USDT", 
//   })


//     let amount = withdrawalInfo.amount
//     let type = withdrawalInfo.type

    
//   function onClick(percent){
//     const step = assetList[findIndexByType(type)].precision
//     setWithdrawalInfo({...withdrawalInfo, amount: floor10(balance(type)*percent,-step)})

//   }


  
// 	function showModal(){
//     setModal({...modal, modalClass: "modal is-active"})
// }

//   function closeModal(){
//     setModal({...modal, modalClass: "modal", showForm: false})
//   }

//   function validateForm(){
//     const min = assetList[findIndexByType(type)].min 
//     return (Number(amount) <= Number(balance(type)))&&(parseFloat(amount) >= min);
//   }

//   function selector() {  
//     const { Option } = Select;
//     // function onSearch(val) {
//     //     console.log('search:', val);
//     //   }
//     function onChange(value) {
//         setWithdrawalInfo({...withdrawalInfo, type: value, amount: ''});
        
//       }
//     const list = (assetList) => assetList.map((item, i)=>
//     <Option value={item.type} key={i}>
//       <p  style={{display: "inline-flex"}}> {item.icon} {item.description} </p>
//     </Option>
//     )
//     return (
        
//         <div class="select">
//             <Select
//                 // showSearch
//                 style={{ width: 180 }}
//                 bordered={false}
//                 placeholder="选择提现的资产种类"
//                 optionFilterProp="children"
//                 defaultValue = 'USDT'
//                 size = 'large'
//                 onChange={onChange}
//                 suffixIcon=""
//             >
//                     {list(assetList)}
                
//             </Select>
//             </div>
//     )
//   }

//   function addonSelector (type) {
//     const idx = assetList.map(i => i.type).indexOf(type)
//     if (idx>=0){
//       return (
//         <span class='button is-rounded is-static'>
//           {assetList[idx].type}
//           {assetList[idx].icon}
//       </span>
//       )
//     }
//   }

//   function modalSelector (type){
//       const idx = assetList.map(i => i.type).indexOf(type)
//       if (assetList[idx].isFiat){
//         return(<BankForm modal={modal} setModal={setModal} state={props.state} setState={props.setState} assetInfo={props.assetInfo} setAssetInfo={props.setAssetInfo}
//             withdrawalInfo={withdrawalInfo} setComplete = {props.setComplete}/>)
//       }else{
//           return (<BlockchainAddr modal={modal} setModal={setModal} withdrawalInfo={withdrawalInfo} setComplete = {props.setComplete}/>)
//       }
//   }
//   function presicion(e){
//     let tempAmount = e.target.value;
//     const step = assetList[findIndexByType(type)].precision 

//     let dotPosition = tempAmount.indexOf('.')
    
    
//     dotPosition!==-1?tempAmount = e.target.value.slice(0,dotPosition+1+step): tempAmount = e.target.value
    
//     setWithdrawalInfo({...withdrawalInfo, amount: tempAmount})
      
//   }
//   function renderWithdrawal(){
//         return (
//           <>
//         <div class={modal.modalClass}>
//           <div class="modal-background"></div>
//           <div class="modal-content">
             
//             {modalSelector(type)}
            
//           </div>
//           <button class="modal-close is-large"  onClick={closeModal}>
          
//           </button>
//         </div>
          
//           {selector()}
//           <form >
//             <span class='tag has-background-white' style={{marginTop:'30px', fontSize:'13px'}}>最小提现数额为 {assetList[findIndexByType(type)].min} {type}</span>
//             <div class='field'>
//             <div class='field is-expanded'>
//               <div class ='field has-addons'>
//                 <p class="control has-icons-right ">
//                   <input class="input" value={amount} onChange={e=>presicion(e)}  type="number" placeholder={balance(type)} /> 
//                 </p>
//                 <p class='control'>
//                     {addonSelector(type)}
//                 </p>
//               </div> 
//               <span className='label' style={{marginTop:'-35px'}}><span className='tag'>提现手续费: -{floor10(findThisByType('fee',type)*amount,-findThisByType('precision',type))} {type}</span></span>
              
//               <p class = 'control'>
//                   <span class="button" onClick={()=>onClick(0.25)} >25%</span>
//                   <span class="button" onClick={()=>onClick(0.5)}>50%</span>
//                   <span class="button" onClick={()=>onClick(0.75)}>75%</span>
//                   <span class="button" onClick={()=>onClick(1)}>100%</span>
//               </p> 

//               <div class='field'>
                
                
//               </div>
                
//             </div>     
//             </div>
             
          
        
//         </form>
//         <p><span className='tag has-background-white'>将得到: {(amount - findThisByType('fee',type)*amount).toFixed(findThisByType('precision',type))} {type}</span></p>
//         <button class='button is-rounded is-bold is-link' onClick= {showModal} disabled={!validateForm()}>确认提现
//            <RightArrow />
//         </button>
//         </>
//         );
//     }
    

//     return renderWithdrawal();


