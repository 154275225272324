import React from "react";
import CountryListFull from './countrylist'


export default function TransferType(props){
    const form = props.form
    const setForm = props.setForm

    function Selector(){
        

        return(
            <div className="field">
            <label className="label">转账类型</label>
            <div className='select'>
            <select value={form.transferType} onChange={e=>setForm({...form, transferType:e.target.value})}>
            <option value='personalSf'>个人 SWIFT</option>
            <option value='companySf'>公司 SWIFT</option>
            {form.bankCountry==='US'&&<><option value='personalAba'>个人 ABA 本地（当天/隔日）</option>
            <option value='companyAba'>公司 ABA 本地（当天/隔日）</option></>}
        </select> 
        </div>
        </div>
        )
    }

    function ABA(){
        
        
        return(
            <>

            
            <div className='field is-horizontal'>
                <div className='field-body'>
                    <div className="field">
                        <label className="label">收款人国家</label>
                        <div className="control country">
                                <div className='select'>
                                    <CountryListFull form={form} setForm={setForm}/>
                                </div>
                        </div>
                    </div> 

                    <div className="field">
                        <label className="label">收款人城市</label>
                        <div className="control">
                            <input className="input" value={form.city} onChange={e=>setForm({...form, city:e.target.value})} type="text" placeholder=""/>
                        </div>
                    </div> 

                </div>
            </div>


        <div className='field is-horizontal'>
            <div className='field-body'>
                <div className="field">
                    <label className="label">收款人地址（英文）</label>
                    <div className="control">
                        <input className="input" value={form.address} onChange={e=>setForm({...form, address:e.target.value})} type="text" placeholder=""/>
                    </div>
                </div> 

                <div className="field">
                    <label className="label">ACH/Wire Routing Number</label>
                    <div className="control">
                        <input className="input" value={form.aba} onChange={e=>setForm({...form, aba:e.target.value})} type="text" placeholder=""/>
                    </div>
                </div> 

            </div>
        </div>

        {form.transferType==='companyAba'&&CompanyName()}
        <hr></hr>
        <div className="field">
            <label className="label">账号</label>
            <div className="control">
                <input className="input" value={form.account} onChange={e=>setForm({...form, account:e.target.value})} type="text" placeholder=""/>
            </div>
        </div> 
            </>
        )
    }

    function CompanyName(){
        
        return(
            <div className="field">
                        <label className="label">公司名</label>
                        <div className="control">
                            <input className="input" value={form.companyName} onChange={e=>setForm({...form, companyName:e.target.value})} type="text" placeholder=""/>
                        </div>
            </div>
        )
    }

    function SWIFT(){
        
        

        return (
            <>

        
        
        
            <div className='field is-horizontal'>
                <div className='field-body'>
                    <div className="field">
                        <label className="label">收款人国家</label>
                        <div className="control country">
                                <div className='select'>
                                    <CountryListFull form={form} setForm={setForm}/>
                                </div>
                        </div>
                    </div> 

                    <div className="field">
                        <label className="label">收款人城市</label>
                        <div className="control">
                            <input className="input" value={form.city} onChange={e=>setForm({...form, city:e.target.value})} type="text" placeholder=""/>
                        </div>
                    </div> 

                </div>
            </div>

            {form.transferType===('companySf')&&CompanyName()}
            
            <div className='field is-horizontal'>
                <div className='field-body'>
                    <div className="field">
                    <label className="label">收款人地址（英文）</label>
                    
                    <div className="control">
                        <input className="input" value={form.address} onChange={e=>setForm({...form, address:e.target.value})} type="text" placeholder="请用英文填写收款人银行月结单地址"/>
                    </div>
                </div>

                    <div className="field">
                        <label className="label">BIC/SWIFT</label>
                        <div className="control">
                            <input className="input" value={form.swift} onChange={e=>setForm({...form, swift:e.target.value})} type="text" placeholder=""/>
                        </div>
                    </div> 



                </div>
            </div>
            
            <hr></hr>
            <div className='field is-horizontal'>
                <div className='field-body'>
                    <div className="field">
                        <label className="label">账号</label>
                        <div className="control">
                            <input className="input" value={form.account} onChange={e=>setForm({...form, account:e.target.value})} type="text" placeholder=""/>
                        </div>
                    </div> 
                

                {form.bankCountry==='GB'&&
                
                    <div className="field">
                        <label className="label">IBAN</label>
                        <div className="control">
                            <input className="input" value={form.IBAN} onChange={e=>setForm({...form, IBAN:e.target.value})} type="text" placeholder=""/>
                        </div>
                    </div> 
                }

            </div>
            </div>

        </>

        );
    }

    
    
    return(
        <>
        {Selector()}
        {(form.transferType===('companyAba')||form.transferType===('personalAba'))?ABA():SWIFT()}
        </>
    );
    }
