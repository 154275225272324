import React, {useState} from "react";
import Nav from "./Nav"
import Footer from '../components/footer'
import { layoutGenerator } from 'react-break';

import {Tabs, Radio, BackTop } from 'antd';
import { accountOpen } from './text'
export default function Account(props) {
	const {TabPane} = Tabs;
	

				
	const accoutList = (array) => array.map((item,i) =>		
		<TabPane tab={item.country} key = {i}>
				<div className='has-text-centered'>
					<h1>{item.title}</h1>
					<p>{item.info1}</p>
					<p>{item.info2}</p>
					<img src={require('../image/account_open/'+item.img).default} alt={item.country} />
				</div>
		</TabPane>
	)
	

    

  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    
    <div  className='hero'>
    <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} />  
    <div className='hero-body accountOpen' style={{marginTop:'10px'}}>
        

          
        <div className='hero-body'>
					<div className='has-text-centered'>
						<div className='columns'>
							<div className='column is-5'>
								<h1>离岸账户 在岸开户</h1>
								<h3>无需亲赴现场 无需购买理财</h3>
								
								<a class="button is-rounded is-link is-bold" href='#accountList'>
                            立即开户
                </a>
							</div>
							<div className='column'>
								<img src={require('../image/account_open/bankAccounts.jpg').default} alt="Description" id='poster'/>
							</div>

						</div>
							
							
							
							
					</div>	
        </div>
				<div className='line' id='product'></div>
				<div className='hero-body' id='accountList'>
					<div className='columns'>
						<div className='column is-2'></div>
						<div className='column'>
							<div className='accountList'>
								<Tabs tabPosition={'top'}>
									{accoutList(accountOpen)}
								</Tabs>
							</div>
						</div>
						<div className='column is-2'></div>

					</div>
					
				</div>




      </div>
      
    </div>
    
    <Footer/>
   
</>
  );
}
