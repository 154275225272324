import React, {useState} from "react";
import Nav from "./Nav"
import Footer from '../components/footer'
import { layoutGenerator } from 'react-break';

import {Collapse, BackTop } from 'antd';
import { cards } from './text'
export default function Payment(props) {
  
    const [modalClass, setModalClass] = useState('modal')
    const [id, setId] = useState('hi')

    function onClick(e){
        showModal();
        setId(e.target.id)
    }

    
    function showModal(){
        setModalClass('modal is-active')
    }

    function closeModal(){
        setModalClass('modal')
    }

    
      

    function modal() {

        return (
            <div class={modalClass+' payment'}>
            <div class="modal-background "></div>
            <div class="modal-card ">
        
                <section class="modal-card-body">
                {/* <button class="delete" aria-label="close" onClick={closeModal}></button> */}
                <span class="delete" aria-label="close" onClick={closeModal}></span>
                <div class="divider is-centered" style={{backgroundColor: '#459CF7', marginBottom: '20px', height:'3px', width:'50px', borderRadius:'15px'}}></div>
                {cards[id]}
                
                </section>
                {/* <footer class="modal-card-foot">
                <button class="button" onClick={closeModal}>Cancel</button>
                </footer> */}
            </div>
            </div>
        )

    }
        
    

  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    
    <div  className='hero' style={{backgroundColor:'#FAFAFB'}}>
    <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} /> 
      {modal()}  
    <div className='hero-body payment' style={{marginTop:'10px'}}>
        
        <div class="container">
            <div class="columns is-vcentered">
                <div class="column is-5 is-offset-1 ">
                    <div>
                        <h1 className="is-bold">
                            用比特币满足您的一切消费需求
                        </h1>
                        
                        <h3 class="">
                            使用加密货币和美金储蓄轻松，安全，私密地购物与出行
                        </h3>
                    
                        
                        <a class="button is-rounded is-info is-bold" href='#feature'>
                            匿名支付 隐形生活
                        </a>
                    </div>
                </div>
                <div class="column is-4 is-offset-1">
                    
                        <img src={require('../image/payment.png').default} alt="Description" id='poster'/>
                   
                </div>
            </div>
            <h3 className='info'>所有过程均不受 CRS 监管，全程支付双向加密，匿名隐私，安全可靠，秒级到账体验，为您出行购物旅游沟通保驾护航。</h3>
        </div>
        
        
          
        <div className='hero-body' id='feature'>
            <div className='columns feature'>
            <div className='column'></div>
            <div className='column '>
                <div className='widget'>
                    <p className='heading'>网购</p>
                    <figure>
                    <img src={ require('../image/payment/shopping-cart.svg').default} />
                    </figure> 
                    <div className='plain-text'>
                        <p>用加密货币或美元储蓄在天猫，京东，苏宁易购，唯品会，沃尔玛，家乐福匿名支付</p>
                
                    </div>
                </div>
            </div>
            <div className='column '>
                <div className='widget'>
                <p>手机充值</p>
                <figure>
                <img src={ require('../image/payment/payment-method.svg').default} />
                </figure>  
                <div className='plain-text'>
                        <p>移动，联通，电信三大运营商话费充值卡</p>
                </div>
                </div>
                
            </div>
            <div className='column '>
                <div className='widget'>
                <p className='heading'>出行</p>
                <figure>
                    <img src={ require('../image/payment/air-freight.svg').default} />
                    </figure>
                    <div className='plain-text'>
                    <p>全球 300 多家航空公司机票, 全球 140 多家连锁酒店预定</p>
                    </div>
                </div>
            </div>
            <div className='column'></div>
            </div>
        </div>


        <div className='has-text-centered cards' >
            <h1 style={{marginBottom:'7%', paddingTop:'10%'}}>合作伙伴</h1>
            <div className='columns' >
                <div className = 'column is-1'></div>
                <div className = 'column' >
                    <div className = 'card' id = 'suning' onClick={onClick}>
                        <img  id = 'suning' src = {require('../image/payment/suning.png').default} />
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id='tmall' onClick={onClick}>
                        <img id='tmall' src = {require('../image/payment/tmall.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id = 'vipshop' onClick={onClick}>
                        <img id = 'vipshop' src = {require('../image/payment/vipshop.png').default}/>
                    </div>
                </div>
                <div className = 'column is-1'></div>
            </div>
            <div className='columns'>
                <div className = 'column is-1'></div>
                <div className = 'column'>
                    <div className = 'card' id = 'walmart' onClick={onClick}>
                        <img  id = 'walmart' src = {require('../image/payment/walmart.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id = 'jd' onClick={onClick}>
                        <img id = 'jd' src = {require('../image/payment/jd.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id = 'gap' onClick={onClick}>
                        <img id = 'gap' src = {require('../image/payment/gap.png').default}/>
                    </div>
                </div>
                <div className = 'column is-1'></div>
            </div>
            <div className='columns'>
                <div className = 'column is-1'></div>
                <div className = 'column'>
                    <div className = 'card' id = 'hotelgift' onClick={onClick}>
                        <img id = 'hotelgift' src = {require('../image/payment/hotelgift.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id = 'flightgiftcard' onClick={onClick}>
                        <img id = 'flightgiftcard' src = {require('../image/payment/flightgiftcard.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id = 'china-mobile' onClick={onClick}>
                        <img id = 'china-mobile' src = {require('../image/payment/china-mobile-china.png').default}/>
                    </div>
                </div>
                <div className = 'column is-1'></div>
            </div>
            <div className='columns'>
                <div className = 'column is-1'></div>
                <div className = 'column'>
                    <div className = 'card' id = 'china-telecom' onClick={onClick}>
                        <img id = 'china-telecom' src = {require('../image/payment/china-telecom.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id = 'china-unicom' onClick={onClick}>
                        <img id = 'china-unicom' src = {require('../image/payment/china-unicom-china.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id = 'carrefour' onClick={onClick}>
                        <img id = 'carrefour' src = {require('../image/payment/carrefour-china.png').default}/>
                    </div>
                </div>
                <div className = 'column is-1'></div>
            </div>
            <div className='columns'>
                <div className = 'column is-1'></div>
                <div className = 'column'>
                    <div className = 'card' id = 'hema' onClick={onClick}>
                        <img id = 'hema' src = {require('../image/payment/hema.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    <div className = 'card' id = 'hema' onClick={onClick}>
                        <img id = 'starbucks' src = {require('../image/payment/starbucks.png').default}/>
                    </div>
                </div>
                <div className = 'column'>
                    
                </div>
                <div className = 'column is-1'></div>
            </div>
        </div>

            

      </div>
      
    </div>
    
    <Footer/>
   
</>
  );
}
