import { DatabaseConfig } from "@icon-park/react";
import {API} from "aws-amplify";

function getBinanceData(pair){
    const content = {
        body: {
            pair: pair
        }
    }
    let apiName = 'getBinanceData'
    let path = '/getBinanceData'
    return API.post(apiName,path,content)
}

function getAssetBalance() {
    let apiName = 'assetBalance'
    let path = '/assetBalance'
    return API.get(apiName, path);
}

function getAssetDetails() {
    let apiName = 'getAssetDetails'
    let path = '/getAssetDetails'
    return API.get(apiName, path);
}


function getTransaction(){
    let apiName = 'transactions'
    let path = '/transactions'
    return API.get(apiName, path);
}

function getWithdrawal(){
    let apiName = 'withdrawal'
    let path = '/withdrawal'
    return API.get(apiName, path)
}

function getFiatBalance(){
    let apiName = 'fiatBalance'
    let path ='/fiatBalance'
    return API.get(apiName, path);
}

function getSavingBalance(){
    let apiName = 'savingBalance'
    let path ='/savingBalance'
    return API.get(apiName, path);
}

function getEstimateInterest(){
    let apiName = 'getEstimateInterest'
    let path ='/getEstimateInterest'
    let content = {
        body:{
            assetType:'USDT',
        },
    };
    return API.post(apiName, path, content);
}

export function getOptHistory(){
    let apiName = 'optHistory'
    let path = '/optHistory'
    return API.get(apiName, path)
}

export function updateBalance(type, balance){
    let content = {
        body: {
            type: type,
            balance: balance,
        }
    };
    let api = 'assetBalance';
    let path = '/assetBalance'
    return API.put(api, path, content)
}
const defaultDetails = {
    moneyInfo : '',
    bankInfo : ''
}
export function cashout(type, amount, details = defaultDetails){
    const content = {
        body:{
            type: type,
            amount: amount,
            code: 'WTHD', // code for withdrawal
            moneyInfo: details.moneyInfo,
            bankInfo: details.bankInfo,
        }
    };
    const api = 'cashout';
    const path = '/cashout'
    return API.put(api, path, content)

}
// export function makeTransaction(asset, type, amount){
    
//     let content = {
//         body: {
//             asset: asset,
//             amount: amount,
//             type: type,
//         }
//     }
//     let api = 'transactions';
//     let path = '/transactions'
//     return API.post(api, path, content)
// }

export function newDeposit(info){
    
    const content = {
        body: info
    }
    const api = 'newDeposit';
    const path = '/newDeposit';
    return API.post(api, path, content)

}

export function invitationCodeVerify(code){
    const content = {
        body: {
            code: code
        }
    }
    console.log('content', content)
    const api = 'users';
    const path = '/verifyCode';
    return API.post(api, path, content)
}


// export function updateSavingBalance(type, amount){
//     let content = {
//         body: {
//             type: type,
//             balance: amount,
//         }
//     };
//     let api = 'savingBalance';
//     let path = '/savingBalance';
//     return API.put(api, path, content)
// }


// export function updateFiatBalance(fiatType, amount){
//     let content = {
//         body: {
//             fiatType: fiatType,
//             balance: amount,
//         }
//     };
//     let api = 'fiatBalance';
//     let path = '/fiatBalance';
//     return API.put(api, path, content)
// }



// export function createSavingBalance(type){
//     let content = {
//         body: {
//             type: type,
//             balance: 0.00,
//         }
//     }
//     let api = 'savingBalance';
//     let path = '/savingBalance'
//     return API.post(api, path, content)
// }

// export function createFiatBalance(fiatType){
//     let content = {
//         body: {
//             fiatType: fiatType,
//             balance: 0.00,
//         }
//     }
//     let api = 'fiatBalance';
//     let path = '/fiatBalance'
//     return API.post(api, path, content)
// }

export function createBalance(asset, email, username){
    let apiName='assetBalance';
    let path = '/assetBalance';
    let content = {
        body: {
            assetList: asset,
            email: email,
            username: username
        }
        
    };
    
    return API.post(apiName, path, content)
    
}

export function swap(info){
    const apiName='swap';
    const path = '/swap'
    const content = {
        body: info
    }
    return API.post(apiName, path, content)   
}

export function getSwapHistory(){
    const apiName = 'getSwapHistory'
    const path = '/getSwapHistory'
    return API.get(apiName, path);
}

export function exchangeGetPrice(data){
    const apiName = 'exchangeGetPrice'
    const path = '/exchangeGetPrice'
    const content = {
        body: data
    }
    return API.post(apiName, path, content)
}

export function exchangeCreateOrder(data){
    const apiName = 'exchangeCreateOrder'
    const path = '/exchangeCreateOrder'
    const content = {
        body: data
    }
    return API.post(apiName, path, content)
}

export function exchangeGetOrder(data){
    const apiName = 'exchangeGetOrder';
    const path = '/exchangeGetOrder';
    const content = {
        body: data
    }
    return API.post(apiName, path, content)
}

export function checkTrc20USDTTransactionStatus(address){
    const apiName = 'checkTrc20USDTTransactionStatus';
    const path = '/checkTrc20USDTTransactionStatus';
    const content = {
        body: address
    }
    return API.post(apiName, path, content)
}

export function verifyBep2Addr(address){
    const apiName = 'verifyBep2Addr';
    const path = '/verifyBep2Addr';
    const content = {
        body: address
    }
    return API.post(apiName, path, content)
}

export function getOrderByIdAddr(data){
    const apiName = 'getOrderByIdAddr';
    const path = '/getOrderByIdAddr';
    const content = {
        body: data
    }
    return API.post(apiName, path, content)
}

export function getUser(){
    const apiName = 'users';
    const path = '/users';
    return API.get(apiName, path)
}

export function getUserDepositAddr(){
    const apiName= 'users';
    const path = '/depositAddr';
    return API.get(apiName, path)
}

export function getUserDepositHistory(){
    const apiName= 'users';
    const path = '/depositHistory'
    return API.get(apiName, path)
}

export function createWithdrawalRequest(data){
    const apiName = 'users';
    const path = '/withdrawal'
    const content = {
        body: data
    }
    return API.post(apiName, path, content)
}

export function getWithdrawalRate(data){
    const apiName = 'users';
    const path = '/withdrawalRate'
    const content = {
        body: {
            name: data
        }
    }
    return API.post(apiName, path, content)
}

export function getUserWithdrawalHistory(){
    const apiName = 'users';
    const path = '/withdrawal'
    return API.get(apiName, path)
}

export function getInterestHistory(data){
    const apiName = 'users';
    const path = '/interest'
    const content = {
        body: {
            name: data
        }
    }
    return API.post(apiName, path, content)
}

export function getPurchaseRate(){
    const apiName = 'users';
    const path = '/buyRate'
    return API.get(apiName, path)
}

export function getBankAddr(){
    const apiName = 'users';
    const path = '/bankAddr'
    return API.get(apiName, path)
}

export function createOrder(data){
    const apiName = 'users';
    const path = '/order'
    const content = {
        body: data
    }
    return API.post(apiName, path, content)
}

export function getOrder(){
    const apiName = 'users';
    const path = '/order'
    
    return API.get(apiName, path)
}

export function getOrderHistory(){
    const apiName = 'users';
    const path = '/orders'
    return API.get(apiName, path)
}

export function createUserProfile(data){
    const apiName = 'users';
    const path = '/users';
    const content = {
        body: {
            email: data
        }
    }
    return API.post(apiName, path, content)
}
export {getBinanceData, getAssetBalance, getTransaction, getWithdrawal, getFiatBalance, getSavingBalance, getEstimateInterest, getAssetDetails}