import React, { useState } from "react";
import { useFormFields } from "../libs/hooksLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import passwordValidator from 'password-validator'
import {failure, success} from '../components/notifications'
import {Eye, EyeOff} from '../components/svg'
import Nav from "./Nav"


export default function ChangePassword(props){
	const history = useHistory()
    const [fields, handleFieldChange] = useFormFields({
		oldPassword: '',
		newPassword: "",
		confirmNewPassword: "",
	  });
    const [inputType, setInputType] = useState("password");
    const [isLoading, setIsLoading] = useState(false)


    let schema = new passwordValidator();
	schema
	.is().min(6)                                    // Minimum length 8
	.is().max(30)                                  // Maximum length 100
	// .has().uppercase()                              // Must have uppercase letters
	// .has().lowercase()                              // Must have lowercase letters
	// .has().digits()                                 // Must have digits
	// .has().symbols()
	.has().not().spaces()                           // Should not have spaces
	// .is().not().oneOf(['Passw0rd', 'Password123']);

	// useEffect(() => {
	// 	async function onLoad() {
	// 		if (!isAuthenticated){
	// 			history.push('/login')
	// 			console.log('isAuthenticated',isAuthenticated)
	// 			return;
	// 		}
	// 	}
	// 	onLoad();
	// }, [])
	
    function validateForm(){
        return (
            schema.validate(fields.newPassword) &&
            fields.newPassword === fields.confirmNewPassword &&
            fields.newPassword !== fields.oldPassword
          );
    }

    function showPW(event){
		event.preventDefault();
		inputType === "password"?setInputType("text"):setInputType("password")
		
    }

    async function handleSubmit(event){
        event.preventDefault();
        setIsLoading(true);
				
				try {
					const currentUser = await Auth.currentAuthenticatedUser();
					await Auth.changePassword(currentUser,fields.oldPassword, fields.newPassword);
                    setIsLoading(false);
					success('修改密码成功！','正在返回 ...')
					history.push('/dashboard')
					// setNewUser(newUser);
				} catch (e){
					failure('修改密码失败', e.message)
					setIsLoading(false)
				}
    }
    
    function renderForm(){
        return (
			<>
			<Nav/>
            <div className='hero-body'>
				<form className="Login" onSubmit = {handleSubmit}>
				
					<div class="field" id="password">
					<label class="label">请输入旧密码</label>
					  <div class="control has-icons-right has-icons-left">
					    <input class="input" id='oldPassword' type={inputType}  value={fields.oldPassword} onChange={handleFieldChange}/>
						
						<span class="icon is-right" onClick={(e)=>showPW(e)}>
						{inputType === 'password'?<EyeOff />:<Eye />}
						</span>
					  </div>
					  
					</div>

                    <div class="field" id="password">
					<label class="label">请输入新密码</label>
					  <div class="control has-icons-right has-icons-left">
					    <input class="input" id='newPassword' type={inputType}  value={fields.newPassword} onChange={handleFieldChange}/>
						
						<span class="icon is-right" onClick={(e)=>showPW(e)}>
						{inputType === 'password'?<EyeOff />:<Eye />}
						</span>
					  </div>
					  
						{fields.newPassword.length<6&&<p class="help has-text-danger">新密码长度至少 6 位数</p>
						// <p class="help has-text-success">密码长度至少 8 位数</p>
						}
                        {fields.newPassword === fields.oldPassword&&fields.newPassword.length>1&&<p class="help has-text-danger">新密码不能与旧密码相同！</p>}

						{/* {schema.validate(fields.password)&&fields.password.length>7?<p class="help has-text-success">包括数字，大小写字母，特殊字符</p>:<p class="help has-text-danger">包括数字，大小写字母，特殊字符</p>} */}

					</div>

					<div class="field" >
					<label class="label">请再次输入新密码</label>
					
					  <div class="control has-icons-right has-icons-left">
					    <input class="input" id='confirmNewPassword' type={inputType}  value={fields.confirmNewPassword} onChange={handleFieldChange}/>
						<span class="icon is-right" onClick={(e)=>showPW(e)}>
						{inputType === 'password'?<EyeOff />:<Eye />}
						</span>
					  </div>
					{(fields.newPassword !== fields.confirmNewPassword)&&fields.confirmNewPassword.length>1? <p class="help is-danger">两次密码不一致</p>:
					<></>
					
					}
					</div>


					<hr />
					<div class="field is-grouped is-grouped-centered">
					  <div class="control">
							{ isLoading?
							<button class="button is-rounded is-bold is-link is-loading" type="submit">提交</button> :
					    <button class="button is-rounded is-bold is-link" type='submit' disabled={!validateForm()}>提交</button>
							}
							</div>
							
					</div>
				</form>
				
				</div>
				</>
        )
    }

	return (renderForm()

		);

}