import React, {useState} from "react";
import Nav from "./Nav"
import Footer from '../components/footer'
import {BackTop } from 'antd';
import { exchanges } from './text'
import { FixedFloat } from "../components/exchangeForm";
export default function Exchange(props) {
    const exchangeRows = (array) => array.map((item, i) =>
    <div className = 'column'>
                    <div className = 'card'  >
                        <a href={item.url}><img  alt = {'image'} src = {require('../image/service_providers/'+item.src).default}/></a>
                    </div>
                        <a href={item.url}><h3>{item.name}</h3></a>
    </div>
    
    )

    const exchangeColumns = (array) => array.map((item,i) =>
    <div className='columns' key = {i}>
                <div className = 'column is-1'></div>
                    {exchangeRows(item)}
                <div className = 'column is-1'></div>
    </div>
  )
    
        
    

  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    
    <div  className='hero' style={{backgroundColor:'white'}}>
    <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} /> 
    <div className='hero-body' >
      <div class="container">
        <FixedFloat />
      </div>
    </div>
      
    <div className='hero-body payment' style={{marginTop:'10px'}}>
        
        <div class="container">
          
        <div className='has-text-centered exchange' >
            <h1 style={{marginBottom:'7%', paddingTop:'10%'}}>Recommended Cryptocurrency Exchanges</h1>
            {exchangeColumns(exchanges)}
        </div>

        </div>
        
        
          
        <div className='hero-body' id='feature'>
        </div>

      </div>
      
    </div>
    
    <Footer/>
   
</>
  );
}
