import React, { useEffect, useState } from "react";
import {floor10} from '../components/Decimal'
import {BankForm} from '../components/bankForm'
import BlockchainAddr from '../components/withdrawalAddress'
import {RightArrow} from '../components/svg'
import {Select, Space, Radio, Table} from 'antd'
import {assetList, findIndexByType, findThisByType} from './selfDefine'
import { useHistory, useLocation } from "react-router-dom";
import Nav from "../containers/Nav";
import { Box, HStack, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react"
import { AddressBook } from "@icon-park/react";
import { useFormFields } from "../libs/hooksLib";
import { Wallet } from "@icon-park/react";
import { getInterestHistory } from "../containers/API";
import { failure } from "./notifications";
import { useAppContext } from "../libs/contextLib";
import {Logo} from "../components/coinLogo"
export default function InterestHistory(props){
	const location = useLocation()
    const history = useHistory();
    const { isAuthenticated} = useAppContext();
	const {type, totalInterest, rate} = location.state
    const totalAmount = '1.23'
    const currentInterest ='4%'
    const lastAmount = '0.02'
    const [dataSource, setDataSource] = useState()
    const columns = [
        {
            title: '币种',
            key: 'name',
            render: (text, record)=>(
                <HStack>
                  <figure class="image is-24x24">
                    {Logo[type]()}
                  </figure>
                  <div>
                    {text.name}
                  </div>
                </HStack>
              )
                
        },
        {
            title: '数量',
            key: 'interest',
            render: e => <Text>+ {e.interest}</Text>
        },
        {
            title: '时间',
            key: 'createdAt',
            // defaultSortOrder: 'descend',
			// sorter: {
			// 	compare:(a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
			// 	multiple: 1,
			// },
			render: e=> new Date(e.createdAt).toLocaleString()
        },

    ]

    async function getHistory(){
        if (!isAuthenticated){
			history.push('/login')  
            return;
         }
        try {
            const result = await getInterestHistory(type)
            setDataSource(result)
            console.log(result)
        }catch(e){
            failure('加载错误', e.message)
        }
    }

    useEffect(()=>{
        getHistory()
    }, [])

    // const dataSource = [
    //     {
    //         type: type,
    //         createdAt: 1629709656000,
    //         amount: '0.01'
    //     },
    //     {
    //         type: type,
    //         createdAt: 1629709556000,
    //         amount: '0.02'
    //     }
    // ]
	return(
		<div style={{backgroundColor: '#F9FAFC'}}>
			<Nav />
			<div class= 'container'>
                <Box pt='4%'>
                    <HStack style={{textAlign:'center'}}>
                        <Stat>
                            <StatLabel>累计收益</StatLabel>
                            <StatNumber>+ {totalInterest} {type}</StatNumber>
                        </Stat>
                        {/* <Stat>
                            <StatLabel>昨日收益</StatLabel>
                            <StatNumber>+ {lastAmount} {type}</StatNumber>
                        </Stat> */}
                        <Stat>
                            <StatLabel>年化</StatLabel>
                            <StatNumber>{rate} %</StatNumber>
                        </Stat>
                        
                    </HStack>
                </Box>
                <Box pt='3%'>
                    <div className='card round-card'  >
						<div className = 'card-content'>
                            <Box>只展示最近 30 天记录</Box>
                            <Table columns={columns} dataSource={dataSource}/>
                        </div>
                    </div>
                </Box>
			</div>
			
		</div>

		
	)


}

