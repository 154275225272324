import React from "react";
import { Link} from "react-router-dom";
import { Menu, Dropdown } from 'antd';
import {Home, Aperture, Key, Login, Logout, Setting, Box} from './svg'
import { useAppContext } from "../libs/contextLib";

export function FootNav(props){
    const { isAuthenticated, userInfo} = useAppContext();
    const menu = (
		<Menu>
			<Menu.Item key="0" disabled>
			<b className='has-text-link'>{userInfo.email}</b>
			</Menu.Item>
			<Menu.Item key="1" disabled>
			银行信息
			</Menu.Item>
            <Menu.Item key="2" >
			<Link to="/changePassword" className='has-text-dark'>修改登陆密码</Link>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="3" onClick={props.handleLogout}><span>登出 <Logout /></span></Menu.Item>
		</Menu>
    )
    const home = (
        <Menu>
			<Menu.Item key="1">
                <Link to='/'>加密货币</Link>
			</Menu.Item>
            <Menu.Divider />
            {/* <Menu.Item key="2" >
			    <Link to="/saving" className='has-text-dark'>美元储蓄</Link>
			</Menu.Item> */}
            <Menu.Divider />
            <Menu.Item key="3" >
			    <Link to="/earning" className='has-text-dark'>加密货币理财与贷款</Link>
			</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4" >
			    <Link to="/gold" className='has-text-dark'>黄金投资</Link>
			</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="5" >
			    <Link to="/payment" className='has-text-dark'>匿名支付</Link>
			</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="6">
				  <Link to="/account_open" >海外开户</Link>
			  </Menu.Item>
              <Menu.Divider />
			  <Menu.Item key="7">
				  <Link to="/exchange" >加密货币闪兑</Link>
			  </Menu.Item>
		</Menu>
    )
    return(
        <div>
        <nav className='nav foot-nav container'>

            <div >
                <Dropdown overlay={home} placement="topRight" trigger={['click']}>
                    <Link to="/" className='nav-items '><Home/>业务详情</Link>
                </Dropdown>
            </div>
             {isAuthenticated&&<div>
                <Link to="/dashboard" className='nav-items'><Box />资产中心</Link>
            </div>}
            {!isAuthenticated?
            <>
            <div>    
                <Link to="/signup" className='nav-items'><Key />注册</Link>
            </div>
            <div>
               
                <Link to="/login" className='nav-items'> <Login />登录</Link> 
            </div></>:
            <div>
              <Dropdown overlay={menu} placement="topRight" trigger={['click']}>
					<span id = 'login'className="button navbar-item is-rounded" onClick={e => e.preventDefault()}>
					<Setting />
					</span>
			    </Dropdown>
                
            </div>}

        </nav>
        </div>
    );
}

export default FootNav;