import React, { useEffect, useState } from "react";
import {floor10} from './Decimal'
import {BankForm} from './bankForm'
import BlockchainAddr from './withdrawalAddress'
import {RightArrow, ThreeDotsLoader} from './svg'
import {Select, Space, Radio, Table} from 'antd'
import {assetList, findIndexByType, findThisByType} from './selfDefine'
import { useHistory, useLocation, Link } from "react-router-dom";
import Nav from "../containers/Nav";
import { Box, HStack, Stat, StatLabel, StatNumber, Center, Text } from "@chakra-ui/react"
import { Time } from "@icon-park/react";
import { statusHelper } from "../libs/statusHelper";
import { getOrderHistory } from "../containers/API";
import { failure } from "./notifications";
import { useAppContext } from "../libs/contextLib";
import {Logo} from "../components/coinLogo"
export default function PurchaseHistory(props){
    const {isAuthenticated} = useAppContext()
    const history = useHistory()
	const location = useLocation()
    const [dataSource, setDataSource] = useState()
    const [isLoaded, setIsLoaded] = useState(false)
    const columns = [
        
        {
            title: '支付',
            key: 'spend',
            render: (text) => (
                <HStack>
                    <figure class="image is-24x24">
                        {Logo[text.spend.name]()}
                    </figure>
                    <div>
                        {text.spend.value} {text.spend.name}
                    </div>
                </HStack>
            )
        },
        {
            title: '得到',
            key: 'get',
            render: (text) => (
                <HStack>
                    <figure class="image is-24x24">
                        {Logo[text.get.name]()}
                    </figure>
                    <div>
                        {Number(text.get.value).toFixed(6)} {text.get.name}
                    </div>
                </HStack>
                )
        },
        {
            title: '时间',
            key: 'createdAt',
			render: e=> new Date(e.createdAt).toLocaleString()
        },
        {
            title: '状态',
            key: 'status',
            render: (e)=> e.status === '300'?<span className='tag is-link is-light'><HStack> <Time theme="outline" size="20" fill="blue" strokeWidth={3} strokeLinecap="square"/><h2>等待支付</h2></HStack></span>:statusHelper(e.status)
        },
        {
            title: '详情',
            key: 'details',
            render: (e)=> <Link to={{pathname: "/orders", state: {
                price: e.rate,
                recieve: e.get.value,
                spend: e.spend.value,
                bankData: e.bank,
                createdAt: e.createdAt,
                orderId: e.createdAt,
                fromHistory: true,
                status: e.status
            } }}>{e.status === '300'?<span className = 'tag is-link'>支付</span>:<span className = 'tag'>详情</span>}</Link>

        }

    ]


    async function onLoad(){
        if (!isAuthenticated){
			history.push('/login')  
            return;
        }
        try {
            const result = await getOrderHistory()
            setDataSource(result)
            setIsLoaded(true)
        }catch(e){
            failure('加载失败', e.message)
            setIsLoaded(true)
        }
    }

    useEffect(()=>{
        onLoad()
    }, [])
	return(
		<div >
			<Nav />
            {
                isLoaded?<div class= 'container'>
                <Box pt='3%'>
                    <div className='card round-card'  >
						<div className = 'card-content'>
                            <Table columns={columns} dataSource={dataSource}/>
                        </div>
                    </div>
                </Box>
			</div>:
            <div id='loading'>
                <ThreeDotsLoader/>
            </div>
            }
			
			
		</div>

		
	)


}

