

export function handleInputNumber(value, precision){
    const p = precision;
    let num = value.replace(/[^\d\.]/g, '');
    if (num.lastIndexOf('.')!==num.indexOf('.')||num[0]==='.'){
        num = num.slice(0, -1);
    }
    const dotPosition = num.indexOf('.')
    dotPosition!==-1?num = num.slice(0,dotPosition+1+p): num = num
    return num
}