import React, { useState, useEffect } from "react";
// import "./saving.css";
import {CountryPart} from './countrylist'
import {API} from "aws-amplify";
import CountryMap from './countryMap'
import {cashout, createWithdrawalRequest} from '../containers/API'
import { useAppContext } from "../libs/contextLib";
import TransferType from './FormType'
import { Box, Stat, StatLabel, StatNumber, Center} from "@chakra-ui/react"
import {modifyNotification,newNotification,withdrawalNotification, failure, success } from '../components/notifications'
import Nav from "../containers/Nav";
import { useHistory, useLocation } from "react-router-dom";
import { SpaCandle } from "@icon-park/react";
// import {floor10} from './Decimal'
export function BankForm(props){
	const location = useLocation()
	const {type, fiatParams} = location.state  // type refers to withdrawal type {fiat}
	const [isSubmitting, setIsSubmitting] = useState(false)
	// const assetInfo = props.assetInfo, binanceData=props.binanceData;

	const history = useHistory()
	const [form, setForm] = useState({
		userName: "",
		bankCountry: "HK",
		beneficialCountry: "",
		city:"",
		address: "",
		aba: "",
		account: "",
		// checked: true,
		swift:"",
		companyName:"",
		IBAN:"",
		transferType:"personalSf",
		amount: Number(fiatParams.amount).toFixed(6),
		get: fiatParams.get,
		name: fiatParams.name
	})


	async function submitForm(){
		setIsSubmitting(true)
		const data = {
			type: type,
			data: form
		}
		try{
			await createWithdrawalRequest(data);
			success('成功提交提现申请', '请耐心等待处理');
			history.push('/dashboard')
		}catch(e){
			failure('提交失败', e.message)
		}
		setIsSubmitting(false)
		
	}
	
	



	function validateForm(){

		if (form.transferType === 'personalSf'){
			if (form.bankCountry === 'GB'){
				let array = [form.userName, form.city, form.address, form.swift, form.IBAN, form.account]
				return array.every(e=>e.toString().length>0)
				
				} else {
				let	array = [form.userName, form.city, form.address, form.swift, form.account];
				return array.every(e=>e.toString().length>0)
			}
				
		}else if (form.transferType === 'companySf'){
			
			if (form.bankCountry === 'GB'){
				let array = [form.userName, form.city, form.address, form.swift, form.IBAN, form.account, form.companyName]
				return array.every(e=>e.toString().length>0)
				} else {
				let array = [form.userName, form.city, form.address, form.swift, form.account, form.companyName]
				return array.every(e=>e.toString().length>0)
				
			}
		}else if(form.transferType === 'personalAba'){
			let array = [form.userName, form.city, form.address, form.aba, form.account]
			return array.every(e=>e.toString().length>0)
			
		}else if(form.transferType === 'companyAba'){
			let array = [form.userName, form.city, form.address, form.aba, form.account, form.companyName]
			return array.every(e=>e.toString().length>0)
		}
		
		
	}

	
	return (
		<Box style={{backgroundColor: '#F9FAFC'}}>
			<Nav />
			<Box className='container' pt='5%' pb='10%'>
				<Box className='card round-card' w='60%' m='auto'>
					<Box  className='card-content' p="50px 50px">

							<div className='field is-horizontal'>
								<div className='field-body'>
									<div className="field">
										<label className="label">收款人英文姓名</label>
										<Box className="control" w='300px'>
											<input className="input" value={form.userName} onChange={(e)=>setForm({...form, userName:e.target.value})}  placeholder="请用英文填写姓名"/>
										</Box>
									</div>
								</div>
							</div> 
						<Box className='field is-horizontal'>
							<Box className='field-body'>
								<Box className="field">
									<label className="label">首款人银行所在国家</label>
									<Box className="control country">
										<Box className='select'>
										<CountryPart form={form} setForm={setForm}/>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
						
						<TransferType form={form} setForm={setForm}/>		
						
						<Box className='field is-horizontal'>
						<Box className='field-body'>
							<Box className="field is-grouped">
								<Box className="control">
									{
										isSubmitting?<button className='button is-rounded is-bold is-link is-loading'>确认提现</button>:
										<button className='button is-rounded is-bold is-link'  disabled={!validateForm()} onClick={submitForm}>确认提现</button>
									}
										
									
										
								</Box>
							</Box>
							</Box>
						</Box>
					</Box>					
				</Box>
			</Box>
	</Box>
	);
    
	
}