import React from 'react'
import bitcoin from '../image/svgFiles/bitcoin-btc-logo.svg'
import bnb from '../image/svgFiles/bnb-logo.svg'
import bat from '../image/svgFiles/bat-logo.svg'
import link from '../image/svgFiles/chainlink-link-logo.svg'
import xlm from '../image/svgFiles/stellar-xlm-logo.svg'
import xrp from '../image/svgFiles/xrp-logo.svg'
import eth from '../image/svgFiles/eth-logo.svg'
import usdc from '../image/svgFiles/usdc-logo.svg'
import xmr from '../image/svgFiles/monero-xmr-logo.svg'
import trx from '../image/svgFiles/tron-trx-logo.svg'
import ltc from '../image/svgFiles/litecoin-ltc-logo.svg'
import zcash from '../image/svgFiles/zcash-zec-logo.svg'
import busd from '../image/svgFiles/binance-usd-busd-logo.svg'
import bch from '../image/svgFiles/bitcoin-cash-bch-logo.svg'
import usd from '../image/svgFiles/usd.svg'
import gold from '../image/svgFiles/gold.svg'
import usdp from '../image/svgFiles/usdp.svg'

export const  Logo = {
    USDT: ()=>(
        <svg xmlns="http://www.w3.org/2000/svg"  width = '100%' height = '100%' viewBox='0 0 32 32'>
        <g fill="none" fill-rule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#26A17B"/>
            <path fill="#FFF" d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117"/>
        </g>
        </svg>
    ),
    BTC: ()=>(
        <img src={bitcoin} />
    ),
    BAT: ()=>(
        <img src={bat} />
    ),
    LINK: ()=>(
        <img src={link} />
    ),
    XLM: ()=>(
        <img src={xlm} />
    ),
    XRP: ()=>(
        <img src={xrp}/>
    ),
    BNB: ()=>(
        <img src={bnb} />
    ),
    ETH: ()=>(
        <img src={eth} />
    ),
    USDC: ()=>(
        <img src={usdc} />
    ),
    XMR: ()=>(
        <img src={xmr} />
    ),
    TRX: ()=>(
        <img src={trx} />
    ),
    LTC: ()=>(
        <img src={ltc} />
    ),
    Zcash: ()=>(
        <img src={zcash} />
    ),
    BUSD: ()=>(
        <img src={busd} />
    ),
    BCH: ()=>(
        <img src={bch} />
    ),
    USD: ()=>(
        <img src={usd} />
    ),
    USDP: ()=>(
        <img src={usdp} />
    ),
    GOLD: ()=>(
        <img src={gold} />
    ),
}




    


    
        


    
