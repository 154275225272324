import React from 'react';
import {USD, USDT, Gold} from '../components/svg'

export const assetList = [
    {
        type: 'USDT',
        description: 'USDT',
        isFiat: false, 
        icon: <USDT />,
        min: 1,
        precision: 6,
        isAccountDefaultOpen: true,
        fee: 0,
    },
    {
        type: 'USD',
        description: 'USD 美金',
        isFiat: true,  
        icon: <USD />,
        min: 1,
        precision: 2,
        isAccountDefaultOpen: false,
        fee: 0,
    },
    {
        type: 'GOLD',
        description: 'GOLD 黄金',
        isFiat: false,
        icon: <Gold />,
        min: 0.01,
        precision: 8,
        isAccountDefaultOpen: true,
        fee: 0.02/100,
    }
]

export const businessInfo = {
    amount: '$10,004,635.16',
    date: '2021 年 04 月 02 日'
  } 

export function findIndexByType(type){
    return (assetList.map(i => i.type).indexOf(type))
  }

export function findThisByType(This, type){
    return (assetList.find(i => i.type === type)[This]);
};