import React from "react";
// import "./Home.css";
import Nav from "./Nav"
import {GirlWithPhone} from "../components/svg"
import Footer from '../components/footer'
import {businessInfo} from '../components/selfDefine'
import { layoutGenerator } from 'react-break';
import {Collapse, Timeline,BackTop } from 'antd';
import { faq,interestDetails } from './text';
import { useAppContext } from "../libs/contextLib";
export default function Crypto(props) {
  const layout = layoutGenerator({
    mobile: 0,
    phablet: 550,
    tablet: 768,
    desktop: 992,
  })
  const OnAtMostTablet = layout.isAtMost('tablet');
  const OnDesktop = layout.is('desktop');
  const {BTCPrice} = useAppContext();
  const { Panel } = Collapse;

  const list = (array) => array.map( (item,i) =>
    <Panel header={item.header} key={i+1}>
          <div className="message is-link">
          <p className="message-body">{item.text}</p>
          </div>
    </Panel>
  )
  const peersfer = (
    <div class="columns is-desktop">
    <div class="column is-one-third is-offset-1 has-text-centered">
      <div className='peersfer'>
        <h1>FAQ 买卖加密货币常见问题</h1> 
        <figure style={{marginTop:'150px'}}>
                <img src={ require('../image/illustrators/question.png').default} />
        </figure>
      </div>
    </div>
    <div class="column faq-background">
      <Collapse defaultActiveKey={['1']} ghost={true} bordered={false} style={{fontSize:'17px'}}>
        {list(faq.transfer)}
      </Collapse>
    </div>
  </div>
  )

  const interestList = interestDetails.map((item,i) =>
    <Timeline.Item key={i} label={item.text}>
      <span class="tag is-rounded is-link  is-large">{item.label}</span>
    </Timeline.Item>
  )
  const interest = (
    <Timeline mode='right'>
      
       {interestList}
    </Timeline>
  )

  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    <div id={'transfer'}>
      <div className='landing'>
        <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} />     
        <div className="hero-body">
          <div className="container">
            <div className="columns ">
              <div className="column is-4 is-offset-1 landing-caption">
                <div>
                  <h1>
                  自助免费买卖加密货币
                  </h1>
                  
                <h3>
                支持 36 种货币以及 200 多个国家和地区</h3>
                
                <h3 className='is-5'>最快数小时内到账  <span className='tag is-link is-light is-bold is-medium'>全程合法合规</span> </h3> 
                <div class="tags has-addons is-bold has-text-justified">
                  <span class="tag is-medium is-link  ">当前 1 枚比特币 BTC 价格为: </span>
                  <span class="tag is-medium is-link is-light">{BTCPrice} 美元</span>
                </div>
                </div>
                 
              </div>

              <div className="column is-offset-1">
                <OnAtMostTablet>
                <figure >
                </figure> 

                </OnAtMostTablet>
                <OnDesktop>
                  <figure>
                  {/* <img src={ require('../image/home.png').default } /> */}
                  <GirlWithPhone/> 
                  </figure>
                </OnDesktop>
              </div>
              
            </div>

          </div>
        </div>
      </div>
      <div className='hero-body'>
        <div className='columns '>
          <div className='column is-offset-1 is-third'>
            <h3>合作伙伴</h3>
            <p>资金受欧洲合规加密货币牌照发放机构和美国南达科他州银行部以及美国纽约州金融服务厅 (NYDFS) 和内华达州工商部 (DBI) 与美国金融犯罪执法网 (FinCEN)，英国金融行为监管局 (FCA)共同监管。</p>
          </div>
          <div className='column is-three-fifths  '>
            <div className='columns partners is-mobile'>
              <div className='column'> 
                <div ><a href="https://credissimo.com/"><img id='circle' src="partners/circle-logo.png" alt='CIRCLE'/></a></div>
                <div ><a href="https://www.dfs.ny.gov/"><img id='nydfs' src="partners/NYDFS.jpg"alt='Nydfs'/></a></div>
              </div>
              
              <div className='column'>
                <div><a href="https://www.currencycloud.com/"><img id='currencycloud' src="partners/Currencycloud.png" alt='currencycloud'/></a></div>
                <div ><a href="https://alliancetrustcompany.com/"><img id='aliance' src="partners/aliance.png" alt='alliancetrust'/></a></div>
              </div>

              <div className='column'> 
                <div><a href="https://www.cfsb.com/"><img id='cfsb' src="partners/cfsb.png" alt='CFSB' /></a></div>
                <div><a href="https://transactiveltd.com/"><img id='transactive'src="partners/transactive.png"alt='Transactive'/></a></div>
              </div>

              <div className='column'> 
                <div><a href="https://primetrust.com/"><img id='primetrust'src="partners/PrimeTrust.png" alt='PrimeTrust'/></a></div>
                <div><a href="https://bcbgroup.io/"><img id='bcb' src="partners/BCB_group.png" alt='BCB' /></a></div>
              </div>

              

            </div>
          </div>
          
        </div>
      </div>

      <div className='hero-body '>
        <div className='columns feature'>
          <div className='column'></div>
          <div className='column '>
            <div className='widget'>
                <p className='heading'>快速到账</p>
                <figure>
                  <img src={ require('../image/illustrators/rocket_launch.png').default} />
                </figure> 
                <div className='plain-text'>
                  <p>美、欧、加、澳、新加坡元，港币，英镑数小时内到账</p>
                  <p>其它支持的法币 1-3 个工作日</p>
                  
                </div>
            </div>
          </div>
          <div className='column '>
            <div className='widget'>
              <p>截止到 {businessInfo.date}</p>
              <p>客户买卖加密货币金额</p>
              <span class="tag is-info is-large is-bold is-rounded is-light">{businessInfo.amount} </span>
                <figure>
                  <img src={ require('../image/illustrators/card.jpg').default} />
                </figure>  
              
            </div>
          </div>
          <div className='column '>
            <div className='widget'>
              <p className='heading'>无需费用</p>
              <figure>
                  <img src={ require('../image/illustrators/adventure.png').default} />
                </figure>
                <div className='plain-text'>
                  <p>我们作为咨询方不收取任何额外费用</p>
                </div>
            </div>
          </div>
          <div className='column'></div>
        </div>
      </div>

      <div className='hero-body' style={{marginBottom:'15%'}}>     
        {peersfer}
      </div>     
    </div>

    <Footer/>
   
</>
  );
}

