import React from 'react'
import {Telegram, Wechat, ThreeDotsLoader, Logo} from './svg'
import { Tooltip } from 'antd';
export default function Footer(){
    
    return (
        <footer className="footer">
          <div className="content hero-body ">
            <div className='columns'>
                <div className='column is-offset-1 is-one-third'>
                    <h2 class='has-text-link'>
                    <div className='is-flex '>

                        <img style={{width:'300px', marginLeft:0}} src="FamilyOfficeLogo.png"/>
				    </div>
                    </h2>
                    <p>📮 邮箱: support@peersfer.com</p>
                    <div className='address'>
                        <p>Keemia tn 4</p>
                        <p>Kristiine linnaosa, Tallinn</p>
                        <p>Harju maakond, 10616, Estonia</p>
                    </div>
                    <div className='columns'>
                    <div className='column is-3 '>
                    <Tooltip title={<h1><img src={require('../image/IMG_2973.jpg').default}/></h1>} color={'white'}>
                        <span><figure style={{marginTop:'2px'}}><Wechat />JQKA690</figure></span>
                    </Tooltip>    
                        </div>
                        <div className='column is-3'>
                            <figure style={{marginBottom:'0px'}}><a href="https://t.me/JQKA690"><Telegram />JQKA690</a></figure>
                        </div>
                        
                        

                    </div>
                </div>

                <div className='column has-text-centered clients'>
                    {/* <h3>联系我们</h3>
                    <div className='columns'>
                        <div className='column is-one-fifth'>
                            <figure ><Wechat/></figure>
                        </div>
                        <div className='column is-one-fifth'>
                            <figure ><Telegram /></figure>
                        </div>
                    </div> */}
                    {/* <figure>
                        <img src={ require('../image/Skateboarding-3200x2400-colored.png') } />
                    </figure> */}

                    <h3>我们的客户 Our Clients</h3>
                    <div className='columns is-mobile'>
                        <div className="column "><a href="http://www.byimin.com/" >
                            <img id = 'byimin' src={ require('../image/clients/byimin.png').default}/></a>
                        </div>
                        <div className="column "><a href="http://www.overseas-sa.com/">
                            <img id = 'shanghang' src={ require('../image/clients/shanghang.png').default}/></a>
                        </div>
                        <div className="column "><a href="http://www.mthui.com.cn/">
                            <img id = 'mthui' src={ require('../image/clients/mthui.png').default}/>
                            </a>
                        </div>
                        <div className="column "><a href="http://www.jsjyg.cn/">
                            <img id = 'jyg' src={ require('../image/clients/jyg.png').default}/>
                            </a>
                        </div>
                        
                        <div className="column "><a href="http://haiwaivisa.com/">
                            <img id = 'kunni' src={ require('../image/clients/kunni.jpg').default}/>
                            </a>
                        </div>
                        
                        
                    </div>  
                        
                    <div className='columns is-mobile' >
                        <div className="column ">
                            <a href="http://www.galaxycarib.com/">
                            <img id = 'galaxy' src={ require('../image/clients/db_logo.png').default}/></a>
                        </div>
                        <div className="column ">
                        <a href="http://www.wisevisa.cn/">
                            <img id = 'huiqiao' src={ require('../image/clients/huiqiao.png').default}/></a>
                        </div>
                        <div className="column "><a href="https://www.ukec.com/cn/immigration">
                            <img id = 'ukec' src={ require('../image/clients/ym.png').default}/></a>
                        </div>
                        <div className="column "><a href="http://www.finevisa.com/">
                            <img id = 'zhongjiang' src={ require('../image/clients/zhongjiang.png').default}/></a>
                        </div>
                        <div className="column "><a href="https://www.atlascapitalfm.com/?lang=zh-hans">
                            <img id = 'anteng' src={ require('../image/clients/anteng.png').default}/></a>
                        </div>
                       
                        
                    </div>   

                    <div className='columns is-mobile' >
                        
                        
                    </div> 

          
      
                </div>
            </div>
            <div className='columns'>
                <div className='column is-offset-1'>
                    <p> &copy; 2019-2023 Supreme Exchange Ltd. OÜ All rights reserved</p>
                    <p> <b>希望加密资产从此能给您的生活带来无穷的乐趣和便捷</b></p>
                </div>

                <div className='column'>
                </div>
            </div>
            
          </div>
        </footer>
    );
}