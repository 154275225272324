import React, {  useState} from "react";
import { listOfEarningCoins } from "../containers/text";


export default function EarningCal(props){
    const [selectCoin, setSelectCoin] = useState("USDT")
    const [principle, setPrinciple] = useState('100')
    const [rate, setRate] = useState("0.06")
    

    function Rate(props){
        const data = props.data
        const rate = data.find(i => i.type === selectCoin).rate 
        setRate(rate)
        return (
            <p>年化收益率: <span className="tag is-link is-large is-light" >{(Number(rate)*100).toFixed(2)}%</span></p>
        )
    }

    const testlogo = (
        <svg xmlns="http://www.w3.org/2000/svg"  width = '45' height = '28' viewBox='0 0 32 32'>
         <g fill="none" fill-rule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#26A17B"/>
            <path fill="#FFF" d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117"/>
        </g>
        </svg>
    )

    function Interest(props){
        const data = props.data
        const precision = data.find(i => i.type === selectCoin).precision
        return <p>预计年收益: <span className="tag is-link is-large is-light" >{(rate*principle).toFixed(precision)}</span>{selectCoin}</p>
    }


    function Select(props){
        const data = props.data
        const listOptions = data.map((data) =>
            <option>{data.type}</option>
            
            
        )
        return(
            <div className="field ">
            <p className="control">
                <span className="select">
                    <select value={selectCoin} onChange={e=>setSelectCoin(e.target.value)}>
                     {listOptions}
                     
                    </select>
                </span>
            </p>
            </div>
        )    
    }

    function Principle(props){
        return(
            
            <div className="field">
                <div className="field has-addons">
                    <div className="control">
                        <span className="tag is-large is-warning">
                            本金
                        </span>
                    </div>
                    <div className="control" id='inputCal'>
                        <input className="input" type="text"  value = {principle} onChange = {e=>setPrinciple(e.target.value)}/>
                    </div>
                </div>
            
            </div>
               
        )
    }


    return(
        <>
        <div className="field earningCal is-grouped">
            {Principle()}
            <Select data={listOfEarningCoins} />    
        </div>
        <div className="field is-grouped earningDisplay">
            <Rate data={listOfEarningCoins}/>
            <Interest data = {listOfEarningCoins}/>
        </div>
        </>
    )



}


