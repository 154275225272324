// import { statusCode } from "./statusCode"

export function statusHelper(status){
    const statusCode = {
        '200': '已完成',
        '300': '处理中',
        '400': '已取消',
        '500': '失败'
    }
    
    let classname 
    if (status === '200'){
        classname = 'is-success is-bold'    
    }else if (status === '300'){
        classname = 'is-link is-light'    
    }
    else if (status === '400'){
        classname = 'is-warning is-bold'    
    }
    else if (status === '500'){
        classname = 'is-danger is-bold'    
    }   
    return <span class={'tag '+classname}>{statusCode[status]}</span>
}