import React, {  useState} from "react";
import { findIndexByType } from "./selfDefine";
import {listOfLoanCoins } from "../containers/text";
export default function Loanlist(props){
    const columns = props.columns
    function processData(data, column){
        let newData = []
        let i = 0
        do{
            let block = []
            let j = 0
        do {
            block.push(data[i])
            j += 1
            i += 1
        } while (j<column&&i<data.length)
            newData.push(block)
        } while (i<data.length)

        return newData
    }

    const newData = processData(listOfLoanCoins, columns)

    function Card(props){
        const data = props.data
        return(
            <div className='loan'>
            <div className="card">
                <div className="card-content">
                <div class="media">
                    <div class="media-left">
                        
                            <figure class="image is-48x48">
                                {data.img}
                            </figure>
                    </div>
                        <div class="media-content">
                            {data.type}
                            {/* <p className='has-text-link is-bold'>{(Number(data.rate)*100).toFixed(2)}%</p> */}
                        </div>  
                    </div>

                </div>
            </div>
            </div>
        )
    }

    const Column = (array) => array.map((item)=>
        <div className="column ">
            <Card data={item} />
        </div>
    )
    const row = newData.map((item)=>
        <>
        <div className="columns ">
            <div className='column'></div>
            {Column(item)}
            <div className='column'></div>
        </div>

        </>
    )

    return row

}


