import React, { useState } from "react";
import { useFormFields } from "../libs/hooksLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import passwordValidator from 'password-validator'
import {failure, success} from '../components/notifications'
import {Eye, EyeOff} from '../components/svg'
import validator from 'email-validator'
import Countdown from 'react-countdown';
import Nav from "./Nav"


export default function ForgotPassword(props){
	const history = useHistory()
    const [fields, handleFieldChange] = useFormFields({
		email: "",
        code: "",
		newPassword: "",
		
	  });
    
    const [inputType, setInputType] = useState("password");
    const [isLoading, setIsLoading] = useState(false)
	const [codeSent, setCodeSent] = useState(false)
    const [isSendingCode, setIsSendingCode] = useState(false);

    let schema = new passwordValidator();
	schema
	.is().min(6)                                    // Minimum length 8
	.is().max(30)                                  // Maximum length 100
	// .has().uppercase()                              // Must have uppercase letters
	// .has().lowercase()                              // Must have lowercase letters
	// .has().digits()                                 // Must have digits
	// .has().symbols()
	.has().not().spaces()                           // Should not have spaces
	// .is().not().oneOf(['Passw0rd', 'Password123']);

	// useEffect(() => {
	// 	async function onLoad() {
	// 		if (!isAuthenticated){
	// 			history.push('/login')
	// 			console.log('isAuthenticated',isAuthenticated)
	// 			return;
	// 		}
	// 	}
	// 	onLoad();
	// }, [])
	
    function validateForm(){
        return (
            schema.validate(fields.newPassword) &&
			fields.code.length == '6'&&
			validator.validate(fields.email)
          );
    }

    function showPW(event){
		event.preventDefault();
		inputType === "password"?setInputType("text"):setInputType("password")
		
    }

	function validateEmail(){
		return validator.validate(fields.email)
	}

	async function sendCode(event){
		event.preventDefault();
        localStorage.setItem('countdown', Date.now())
        setIsSendingCode(true)
		try {
			await Auth.forgotPassword(fields.email);
			success("验证码已发送至邮箱")
			setCodeSent(true)
		}catch (e){
			failure("验证码发送失败", e.message)
            setIsSendingCode(false);
		}
		setIsSendingCode(false);
		
    }


    const countdownRenderer = ({seconds, completed}) => {
       
        if (completed){
            setCodeSent(false)
            
            return <div></div>
        }else{
            return <div>{seconds}s 后重新发送</div>
        }
    
    }


    async function handleSubmit(event){
        event.preventDefault();
        setIsLoading(true);
		console.log('is submitting')		
				try {
					console.log('is trying')
					await Auth.forgotPasswordSubmit(fields.email,fields.code, fields.newPassword);
                    setIsLoading(false);
					success('新密码设置成功！','请重新登录')
					history.push('/login')
					// setNewUser(newUser);
				} catch (e){
					failure('找回密码账户失败', e.message)
					setIsLoading(false)
				}
    }
    
    function renderForm(){
        return (
			<>
			<Nav/>
            <div className='hero-body'>
				<form className="Login" onSubmit={handleSubmit} >
				
					<div class="field">
					<label class="label">请输注册邮箱地址</label>
					  <div class="control">
					    <input class="input" id='email'  value={fields.email} onChange={handleFieldChange}/>
						
						
						
						
					  </div>
					  
					  
					</div>

					<div class="field is-grouped" >
					{/* <label class="label">请输入验证码</label> */}
					
					  <div class="control is-grouped">
					    <input class="input" id='code' type="text" value={fields.code} onChange={handleFieldChange} placeholder="输入邮箱验证码"/>
				
					  </div>
                      <div class="control">
                        {codeSent === false?
                        isSendingCode?<button id = 'sideButton' class="button is-rounded is-link is-loading" onClick={e=>sendCode(e)} disabled={!validateEmail()}>发送验证码</button>:<button id = 'sideButton' class="button is-rounded is-link " onClick={e=>sendCode(e)} disabled={!validateEmail()}>发送验证码</button>:
                        <div id = 'sideButton'>
                            <Countdown date={JSON.parse(localStorage.getItem('countdown'))+60*1000} renderer={countdownRenderer}/> 
                        </div>}
					  </div>
		
					</div>

                    <div class="field" >
					<label class="label">设置密码</label>
					
					  <div class="control has-icons-right">
					    <input class="input" id="newPassword" type={inputType} value={fields.newPassword} onChange={handleFieldChange}/>
						
						<span class="icon is-right" onClick={(e)=>showPW(e)}>
						{inputType === 'password'?<EyeOff />:<Eye />}
						</span>
					  </div>
					  
						{fields.newPassword.length<6&&<p class="help has-text-danger">新密码长度至少 6 位数</p>
						// <p class="help has-text-success">密码长度至少 8 位数</p>
						}


					</div>

					


					<hr />
					<div class="field is-grouped is-grouped-centered">
					  <div class="control">
							{ isLoading?
							<button class="button is-rounded is-bold is-link is-loading" type="submit">提交</button> :
					    <button class="button is-rounded is-bold is-link" type='submit' disabled={!validateForm()}>提交</button>
							}
							</div>
							
					</div>
				</form>
				
				</div>
				</>
        )
    }

	return (renderForm()

		);

}