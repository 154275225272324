import React, { useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib"
import {getBinanceData} from './containers/API'
import { failure } from "./components/notifications";
import 'antd/dist/antd.css'
import './css/App.css'
import './css/customize.css'
import './css/Dashboard.css'
import './css/footNav.css'
import './css/Crypto.css'
import './css/index.css'
import './css/Login.css'
import './css/NotFound.css'
import './css/popConfirm.css'
import './css/saving.css'

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: '',
    username: ''
  })
  const defaultRate = '实时价格获取中...'
  const [rate, setRate] = useState(defaultRate)
  const [BTCPrice, setBTCPrice] = useState(defaultRate)
  const [hasTrustAccount, setHasTrustAccount] = useState(false)
  const [resendConfirm, setResendConfirm] = useState(false)
  const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
  
  useEffect(() => {
		async function onLoad() {

			try{
				const rate1 = await getBinanceData('PAXGUSDT');
        const rate2 = await getBinanceData('BUSDUSDT');
        const BTCPrice = await getBinanceData('BTCBUSD')
        const data = rate1/rate2;
          setRate(Number(data).toFixed(2))
          setBTCPrice(Number(BTCPrice).toFixed(2))
			   
			  }catch(e){
          console.log(e)
				failure('实时汇率获取失败', e.message)
        
        }

		}

		onLoad();


	}, [])


  
  return (
    <AppContext.Provider value = {{isAuthenticated, userHasAuthenticated, userInfo, setUserInfo, rate, BTCPrice, hasTrustAccount, setHasTrustAccount, resendConfirm, setResendConfirm, email, setEmail, password, setPassword}}>
        <Routes />
    </AppContext.Provider>
  );
}

export default withRouter(App);
