import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import Nav from "./Nav"
import Footer from '../components/footer'
import {BackTop, Tooltip } from 'antd';
import { exchangeGetOrder, checkTrc20USDTTransactionStatus } from "./API";
import { failure } from "../components/notifications";
import logo from "../image/safari-pinned-tab.svg"
import Countdown from 'react-countdown';
import { currencies } from "./text";
import { Spin } from 'antd';
import { Copy } from "@icon-park/react"
import { HStack, Box, Flex} from "@chakra-ui/react"


export default function OrderStatus(props) {
    const history = useHistory()
    const [isLoaded, setIsLoaded] = useState(false)
    const [orderData, setOrderData] = useState()
	const [isWaitingDeposit, setIsWaitingDeposit] = useState(false)
    const [currentStatus, setCurrentStatus] = useState()
    // const [isButtonClicked, setIsButtonClicked] = useState(false)
	const [timer, setTimer] = useState(0)
	const [timer1, setTimer1] = useState(0)
    const [timeup, setTimeup] = useState(false)
	const [params, setParams] = useState({
		address: '',
		expiration: Date.now(),
	})
    const statusList = {
        0: "等待支付",
        1: "等待确认",
        2: "正在兑换",
        3: "打币中",
        4: "订单完成",
        5: "订单失效",
        6: "目前未使用",
        7: "等待执行"
    }
    // if exchange from USDTTRC
	useEffect(() => {
		async function checkTransactionStatus(){
			try{
				if (params.address){
					const re = await checkTrc20USDTTransactionStatus(params.address);
                    // console.log('transaction #',re)
                    
					if(re > 0){
						setIsWaitingDeposit(false)  // funds has been deposited
                        setCurrentStatus("1")
					}
				}else{
					failure('错误','address 地址为 null')
				}
			}catch(e){
				failure('无法查询转账状态',e)
			}
		}
		if ((params.expiration > Date.now())&&isWaitingDeposit&&isLoaded){  // If user still not yet deposit
			
			checkTransactionStatus()
			const count = setTimeout(()=>{
				setTimer(timer+1)
				
			}, 1000*10) // 10 sec
			return ()=>clearTimeout(count)
			
		}
		
	}, [timer])

    // console.log('timer',timer)

    useEffect(() => {
		let unmounted = false;
        async function getOrder(){
            const data =JSON.parse(localStorage.getItem('order'))
            if(!data){
                history.push('/checkOrder')
            }
            if(data&&!unmounted){
                try{
                    const param = {
                        id: data.id.split("").reverse().join(""), //reverse the order number
                        token: data.token
                    }
                    const re = await exchangeGetOrder(param);
                    setOrderData(re)
                    re.data.status==='0'?setIsWaitingDeposit(true):setIsWaitingDeposit(false)
                    setCurrentStatus(re.data.status)
					setParams({address: re.data.from.address, expiration: re.data.expiration*1000})
                    setIsLoaded(true)
                    setTimer(timer+1) // start to trigger monitor on trc20 transaction
                }catch(e){
                    setIsLoaded(false)
                    return failure('获取订单失败', e.message)     
                }
            }else{
                setIsLoaded(false)
            }
        }
		
        getOrder();
        
		if (currentStatus>'0'&&currentStatus<'4'&&Date.now()<=params.expiration){
			const timeout = setTimeout(()=>{
				getOrder()
				setTimer1(timer1+1)
			}, 1000*10) 
			return ()=>clearTimeout(timeout)
		}
	// 		return ()=>clearTimeout(timeout)
	// 	}
        return () => {
            unmounted = true
       }

	  }, [timer1, currentStatus, timeup]);
// console.log('timer1', timer1)
  function StatusTag(props){
      const status = props.status
      let classname = "is-link"
      if (status === '4'){
        classname = "is-success"
      }else if(status === '5'){
          classname = 'is-danger'
      }
      return <div><span class={"tag is-bold "+classname}>{statusList[status]}</span></div>
  }

  const countdownRenderer = ({minutes, seconds, completed}) => {
        setTimeup(false)
        if (completed){
            setTimeup(true)
            // console.log('到倒计时结束')
            return<div><span className='tag is-danger is-light is-large'>已超时</span></div>
        }else{
            return <div className='countdown'>剩余时间: <span className='tag is-link is-light is-large'>{minutes}:{seconds}</span></div>
        }
    
  }

  function Receipt(props){
    return(
        <div className='receipt'>
            <div className='receiptHeader'>
                <div className='receiptHeaderLogo'>
                    <img src={logo} alt='logo' />
                </div>
                <div className='receiptHeaderTitle'>
                    <div>
                        <p >订单号: {orderData.data.id.split("").reverse().join("")}</p>
                    </div>
                    <div >
                        {/* <HStack>
                            <Box >
                                <p >订单号: {orderData.data.id.split("").reverse().join("")}</p>
                            </Box>
                            <Box >
                            <Tooltip trigger='click' title="已复制" color='#9b9b9b'>
                                <Copy theme="outline" size="14" fill="black" strokeWidth={2}/>
                            </Tooltip>
                            </Box>
                        </HStack> */}
                    </div>
                    <div className='receiptDate'>
                        <p>{new Date(orderData.data.reg*1000).toLocaleString()} </p>
                    </div>
                </div>
                
                 
            </div>
            <div className='receiptBody'>
                <div className='orderDetails'>
                    <div>支付</div>
                    <div>{orderData.data.from.amount} {displayCoinName(orderData.data.from.currency)} </div>
                </div>
                <div className='orderDetails'>
                    <div>得到</div>
                    <div>{orderData.data.to.amount} {displayCoinName(orderData.data.to.currency)}</div>
                </div>
                <div className='orderDetails'>
                    <div>价格</div>
                    <div> 1 {displayCoinName(orderData.data.from.currency)} = {orderData.data.rate} {displayCoinName(orderData.data.to.currency)} </div>
                </div>
            </div>
            <div style={{paddingTop:'15px'}}><p>如有任何问题，请在右下角窗口与我们联系</p></div>
            <div className='orderStatus'>
                
                
                <div>
                    <StatusTag status={orderData.data.status}/>
                </div>
                {orderData.data.status === '0'&&
                <div>
                    <Countdown date={orderData.data.expiration*1000+3000} renderer={countdownRenderer}/> {/* wait extra 3 mins more to finish countdown to give backend more time to update*/}
                </div>}
                
            </div>
        </div>
    )

  }

  function displayCoinName(currency){
    const idx = currencies.findIndex(item => item.currency === currency)
    if (idx>=0){
    return <>{currencies[idx].symbol}<sup style={{fontFamily:'Sen', fontWeight:400}}>{currencies[idx].network}</sup></>
    }else{
    return <>{currency}</>
    }    
  }
  function Sender(props){
    return(
        <div className ='boxSender'>
            <div className = 'senderBody'>
                <div >请支付 <b style={{fontSize:'24px'}} className="has-text-link">{parseFloat(orderData.data.from.amount)} {displayCoinName(orderData.data.from.currency)} </b> 到以下地址：</div>
                <div className='address'>{orderData.data.from.address}</div>
            </div>
            <div className = 'senderFooter'>
                <div>兑换汇率会在<b>一个区块</b>确认后锁定</div>
            </div>
        </div>
    )
  }

  function Receiver(props){
      return(
          <div className = 'boxSender'>
              <div className = 'receiverBody'>
                <div>{displayCoinName(orderData.data.to.currency)} 将打入以下地址</div>
                <div className='address'>{orderData.data.to.address}</div>
              </div>
          </div>
      )
  }


  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    {isLoaded?
    <>
    <div  className='hero' style={{backgroundColor:'#FAFAFB'}}>
    <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} /> 
    <div className='hero-body' >
      <div class="container">
          
          
          <div className='columns'>
              <div className='column is-4'>
                <Receipt />
                {/* <Button onClick={e=>setIsButtonClicked(true)}>getOrder</Button>
                <Button onClick={e=>setIsButtonClicked(false)}>reset</Button> */}
              </div>
              {currentStatus<'4'&&<div className='column'>
                <Sender />
                <Receiver />
              </div>}

          </div>
          
      </div>
    </div>
    </div>
    
    <Footer/>
    </>:<>
    <div style={{display:'block', position: 'absolute', top:'50%', left:'50%'}}>
    <Spin size="large"/>
  </div>
  </>
}
</>
  );
}
