import React from "react";
// import "./NotFound.css";

export default function NotFound() {
  return (
    <div class="NotFound">
      <h1 class='title'>404</h1>
      <h3>Sorry, page not found!</h3>
    </div>
  );
}