import React, { useState, useEffect}from "react";
import Nav from "./Nav"
import {GirlWithPhone, Download} from "../components/svg"
import Footer from '../components/footer'
import { layoutGenerator } from 'react-break';
import { Link} from "react-router-dom";
import {Collapse, Timeline,BackTop } from 'antd';
import { faq,interestDetails } from './text'
import { useAppContext } from "../libs/contextLib";

export default function Gold(props) {
  
  const {rate} = useAppContext();

  
  const { Panel } = Collapse;

  const list = (array) => array.map( (item,i) =>
    <Panel header={item.header} key={i+1}>
          <div className="message is-warning">
          <p className="message-body">{item.text}</p>
          </div>
    </Panel>
  )


  const interestList = interestDetails.map((item,i) =>
    <Timeline.Item key={i} label={item.text}>
      <span class="tag is-rounded is-link  is-large">{item.label}</span>
    </Timeline.Item>
  )
  const partners = (
    <>
    <div className='columns is-mobile'>
      <div className='column is-3'>
        <figure>
          <a href='https://www.brinksglobal.com/Sectors/precious_metals.aspx'><img src="partners/brinks.png"/></a>
        </figure>
      </div>
      <div className='column is-3'>
        <figure>
          <a href='https://www.withum.com/'><img src="partners/withum.png"/></a>
        </figure>
      </div>
    </div>
    <div>
    <p>资金受美国纽约金融服务局 (NYDFS) 监管 黄金审计工作由全美排行前 15 的会计事务所 Withum 负责</p>
    </div>
    </>
  )

  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    
    <div className='hero' style={{backgroundColor:'#FAFAFB'}}>
    <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} />     
      <div className='hero-body gold' style={{marginTop:'10px'}}>
        

        <div className='columns'>
        <div className='column is-one-quarter is-offset-1'>
        <div className='has-text-centered' style={{marginTop:'20px', marginLeft:'15px'}}>
      
              <div>
                <span className='tag is-warning is-rounded is-light is-medium'>最低购买量仅为 0.01 金衡盎司</span> 
              </div>
            </div> 
            <div className='widget'>
                <h3>记名交易 提供实物黄金 无托管费 即时交易</h3>
              <figure style={{marginTop:'25%'}}> 
                <img src={require('../image/illustrators/Gold-ingots.png').default}/>
              </figure>
            </div>
            
            
          </div>
          <div className='column is-offset-1'>
            <div style={{marginBottom:'10%'}}>
              <h1>记名实体黄金购买</h1>
              <h3>存放在 Brinks 专业金库中的伦敦标准交割金条</h3>
                <div class="tags has-addons is-bold has-text-justified">
                  <span class="tag is-medium is-warning  is-light">当前 1 金衡盎司黄金 XAU 价格为:</span>
                  <span class="tag is-medium is-warning">{rate} 美元</span>
                </div>
              
              <a href="/gold#product"><button  style={{marginTop:'5%'}}>
                  了解更多
              </button></a>
            </div>
            {partners}
            
          </div>

          
          
        </div>
        
          <div className='line' id='product'></div>

          <div className='has-text-centered redemption' >
            <h3 style={{marginBottom:'7%'}}>可兑换实物黄金产品一览</h3>
            <div class="columns">
              <div className='column is-2'></div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/1-oz-gold-american-eagle-50-coin-bu-random-year--132083449145199349.jpg").default} />
                </figure> 
                  <p>1 金衡盎司美国之鹰 $50 硬币金币（随机年份）</p>
                  <p class="tag is-info is-light">价格：1.0656 金衡盎司</p>
              </div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/1-kilo-32-15-oz-generic-gold-bar-999-fine-ira-approved--132084749679882532.jpg").default} />
                </figure> 
                  <p>1 公斤（32.15 金衡盎司）普通金条 .999 纯度（IRA 批准）</p>
                  <p class="tag is-info is-light">价格：33.5713 金衡盎司</p>
                </div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/1-oz-gold-american-eagle-50-coin-bu-random-year--132083449145199349.jpg").default} />
                </figure> 
                  <p>0.1 金衡盎司美国之鹰 $5 硬币金币（随机年份）</p>
                  <p class="tag is-info is-light">价格：0.1197 金衡盎司</p>
              </div>
              <div className='column is-2'></div>
          </div>
          <div class="columns">
              <div className='column is-2'></div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/1-oz-gold-bar-pamp-suisse-lady-fortuna-veriscan-9999-fine-in-assay--132083449651848945.jpg").default} />
                </figure> 
                  <p>1 金衡盎司美国之鹰 $50 硬币金币（随机年份）</p>
                  <p class="tag is-info is-light">价格：1.0656 金衡盎司</p>
              </div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/10-oz-pamp-suisse-lady-fortuna-gold-bar-9999-fine-in-assay--132084758245794779.jpg").default} />
                </figure>
                  <p>1 公斤（32.15 金衡盎司）普通金条 .999 纯度（IRA 批准）</p>
                  <p class="tag is-info is-light">价格：33.5713 金衡盎司</p>
                </div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/1-oz-gold-bar-valcambi-suisse-9999-fine-in-assay--132083450098878590.jpg").default} />
                </figure>
                  <p>0.1 金衡盎司美国之鹰 $5 硬币金币（随机年份）</p>
                  <p class="tag is-info is-light">价格：0.1197 金衡盎司</p>
              </div>
              <div className='column is-2'></div>
          </div>
          <div class="columns">
              <div className='column is-2'></div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/10-gram-generic-gold-bar-999-fine-ira-approved-secondary-market--132084725156879268.jpg").default} />
                </figure>  
                  <p>1 金衡盎司美国之鹰 $50 硬币金币（随机年份）</p>
                  <p class="tag is-info is-light">价格：1.0656 金衡盎司</p>
              </div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/1-oz-generic-gold-bar-999-9999-fine-ira-approved-secondary-market--132084748029371735.jpg").default} />
                </figure>
                  <p>1 公斤（32.15 金衡盎司）普通金条 .999 纯度（IRA 批准）</p>
                  <p class="tag is-info is-light">价格：33.5713 金衡盎司</p>
                </div>
              <div class="column" >
                <figure>
                  <img src={require("../image/gold/1-gram-generic-gold-bar-999-fine-ira-approved-secondary-market--132084752010858862.jpg").default} />
                </figure>
                  <p>0.1 金衡盎司美国之鹰 $5 硬币金币（随机年份）</p>
                  <p class="tag is-info is-light">价格：0.1197 金衡盎司</p>
              </div>
              <div className='column is-2'></div>
          </div>
          </div>

          

          <div className='columns saving' style={{background:"white", paddingTop:'10%',paddingBottom:'10%', marginTop:'10%'}}>
          <div className='column is-half is-offset-1 faq-background' > 
            <h1>FAQ 黄金购买常见问题</h1>
            <Collapse defaultActiveKey={['1']} ghost={true} bordered={false} style={{fontSize:'17px'}}>
              {list(faq.gold)}
            </Collapse>
          </div>
          <div className='column has-text-centered'>
              <h3>实时信息与报价</h3>
              <div class='gold-price'>
               1 金衡盎司黄金 XAU 价格 = {rate} 美金
              </div>
          </div>
          </div>
        
      </div>
      
    </div>
    
    <Footer/>
   
</>
  );
}
