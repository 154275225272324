import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Input, Select, Space,  Col, Row, Radio } from 'antd';
import {Switch, Lightning } from '@icon-park/react'
import {failure, success} from '../components/notifications'
import { exchangeGetPrice, exchangeCreateOrder, verifyBep2Addr } from '../containers/API';
import WAValidator from 'multicoin-address-validator'
import { Input as ChakraInput, Stack, InputGroup, InputLeftAddon, InputRightAddon, Tooltip, HStack, Box, Center}  from "@chakra-ui/react"
import { currencies } from '../containers/text';

export function FixedFloat(props){

    const {Option} = Select;
    const history = useHistory();
    const [isBep2Verified, setIsBep2Verified] = useState(false)
    const [isAddrValid, setIsAddrValid] = useState(true)
    const [params, setParams] = useState(
        {
            type:'float',
            fromCurrency: 'USDTTRC',
            toCurrency: 'BTC',
            fromQty: '100',
            toAddress: '',
            memo: '',
        },
        
    )
    const day = 60*60*24*1000  //1000 = 1s
    const [data, setData] = useState(
        {
            rate: '0.0492754',
            from:{
                min: '0.00008249',
                max: '8.55419315'
                
            },
            to:{
                amount: '13.5749076',
                min: '0.000362',
                max: '115.4850959'
            }
        }
    )

    
    const [isSubmitting, setIsSubmitting] = useState(false)

    async function afterStateFinished(){
        if(params.fromQty){
            try{
                const re = await exchangeGetPrice(params);
                if(re.code === 0){
                    setData({
                        rate:re.data.from.rate,
                        from:{
                            min: re.data.from.min,
                            max: re.data.from.max
                        },
                        to:{
                            amount: re.data.to.amount,
                            min: re.data.to.min,
                            max: re.data.to.max
                        }
                    })
                }else{
                    const title = re.code
                    const description = re.msg
                    failure(title, description)
                }
                
            }catch(e){
                console.log('err', e)
                const title = e.message
                const description = '出错！'
                failure(title, description)
            }

        }   
    }

    async function handleSubmit(e){
        e.preventDefault();
        setIsSubmitting(true)
        try {
            const re = await exchangeCreateOrder(params);
            
            if (re.code === 0){
                const info = {
                    id: (re.data.id).split("").reverse().join(""),
                    token: re.data.token,
                }
                
                localStorage.setItem('order', JSON.stringify(info));
                localStorage.setItem('exp', day+Date.now())
                setParams({...params, toAddress:''})
                success('下单成功！🎉')
                history.push('/order');
                 
            }else{
                const title = re.code;
                const description = re.msg
                failure(title, description)
            }      
        }catch(e){
            const title = e.message
            const description = '下单失败 '
            failure(title, description)
        }
        setIsSubmitting(false)
        
    }

    function validSubmission(){
        const targetCurrency = currencies[currencies.findIndex(item => item.currency === params.toCurrency)]

        if (targetCurrency.network === 'BEP2' ){
            const regexp = RegExp('bnb')
            if(regexp.test(params.toAddress)&&params.toAddress.length === 42){
                return isBep2Verified&&params.fromQty>=data.from.min&&params.fromQty<=data.from.max
            }else{
                return false
            }
            
        }else if(targetCurrency.network === 'BEP20'|| targetCurrency.network ==='POLYGON'){
            return WAValidator.validate(params.toAddress, 'ETH')&&params.fromQty>=data.from.min&&params.fromQty<=data.from.max
        }else if(targetCurrency.network === 'TRC20'){
            return WAValidator.validate(params.toAddress, 'Tron')&&params.fromQty>=data.from.min&&params.fromQty<=data.from.max
        }else{
            return WAValidator.validate(params.toAddress, params.toCurrency)&&params.fromQty>=data.from.min&&params.fromQty<=data.from.max
        }
        
    }

    useEffect(()=>{
        async function verifyBep2Address(){
            try{
                const status = await verifyBep2Addr(params.toAddress)
                
                if (status.status === "200"){
                   
                    setIsBep2Verified(true)
                }else{
                    setIsAddrValid(false)
                    setIsBep2Verified(false)
                }
            }catch(e){
                return false
            }
        }
        setIsBep2Verified(false)
        setIsAddrValid(true)
        const regexp = RegExp('bnb')
        if (regexp.test(params.toAddress)&&params.toAddress.length === 42){
            const timer = setTimeout(()=>{
                verifyBep2Address()
            }, 50)
            return () => clearTimeout(timer)
        }
    }, [params.toAddress])
    
    
    useEffect(()=>{
        const timer = setTimeout(()=>{
            afterStateFinished()
        }, 1500)
        return () => clearTimeout(timer);
    }, [params.fromCurrency, params.fromQty, params.toCurrency, params.type])
    
    function listCurrencies(type, data){
        // type = fromCurrency or toCurrency
        let newArrary = data
        if (type === 'fromCurrency'){
            newArrary = data.filter(item=>{
                return item.symbol==='USDT'||item.symbol==='BUSD'|| item.symbol === 'USDC'
            })
        }
        if (type === 'toCurrency'){
            newArrary = data.filter(item=>item.currency!==params.fromCurrency)
        }
        return newArrary.map((item)=>(<Option value={item.currency}>
            <HStack width='255px'>
            <figure class="image is-24x24">
                {item.img} 
            </figure>
            <div>
                {item.name}
            </div>
            </HStack>
        </Option>))
    } 

    function displayCoinName(currency){
        const idx = currencies.findIndex(item => item.currency === currency)
        if (idx>=0){
        return <>{currencies[idx].symbol}<sup style={{fontFamily:'Sen', fontWeight:400}}>{currencies[idx].network}</sup></>
        }else{
        return <>{currency}</>
        }    
    }

    function DisplayLimits(props){
        const data = props.data;
        const type = props.type;
        let colorMin = 'is-link is-light';
        let colorMax = 'is-link is-light'
        const warningType = props.warningType
        if (warningType === 'send'){
            (Number(params.fromQty)<data.min)?colorMin = 'is-danger is-light is-rounded':colorMin = colorMin;
            (Number(params.fromQty)>data.max)?colorMax = 'is-danger is-light is-rounded':colorMax = colorMax;
        }
        return(
        <p style={{paddingTop:'8px'}}><span class={"tag "+colorMin}>最小额度:</span> {data.min} {displayCoinName(type)}, <span class={"tag "+colorMax}>最大额度:</span> {data.max} {displayCoinName(type)}</p>
        )
    }
    function selectAfter(type) {
        //flag === 'send' or 'recieve'
        function handleChange(value){
            setParams({...params, [type]: value})
        }

        return(
        <Select variant="unstyled" value={params[type]} className="select-after" style={{width: 'auto'}} onChange={handleChange} bordered = {false}>
            {listCurrencies(type, currencies)}
        </Select>
        )
    }
    function handleInputNumber(e){
        const precision = 8;
        let num = (e.target.value).replace(/[^\d\.]/g, '');
        if (num.lastIndexOf('.')!==num.indexOf('.')||num[0]==='.'){
            num = num.slice(0, -1);
        }
        const dotPosition = num.indexOf('.')
        dotPosition!==-1?num = num.slice(0,dotPosition+1+precision): num = num
        setParams({...params, fromQty: num})
    }
 
    const PriceTypeOption = () => (
        <Radio.Group value={params.type} onChange={(e)=>setParams({...params, type: e.target.value})}>
            <Radio.Button value="fixed" disabled> 固定价格 (1% 手续费)</Radio.Button>
            <Radio.Button value="float"> 浮动价格 (0.5% 手续费)</Radio.Button>
        </Radio.Group>
    )

    const findSymbol = (item) =>{
        const idx = currencies.findIndex(elm => elm.currency === item)
        return currencies[idx].symbol

    }
   
    const Exchange = () => (
    
        <div>
            <div style={{paddingBottom:'20px'}}>
                <span class="tag is-link is-light is-medium"> 1 {findSymbol(params.fromCurrency)} ≈ {data.rate} {findSymbol(params.toCurrency)} </span>
            </div>
            
            <HStack >
                <Center>
                <Box>
                    
                        {/* <Input value = {params.fromQty} inputmode="numeric" onChange = {e=>handleInputNumber(e)} placeholder="兑换的数量" addonAfter={selectAfter("fromCurrency")} addonBefore="支付"/> */}
                        <InputGroup>
                            <InputLeftAddon
                                children="支付"
                                />
                            <ChakraInput value = {params.fromQty} placeholder="兑换的数量" onChange = {e=>handleInputNumber(e)} focusBorderColor="blackAlpha.100"/>
                            <InputRightAddon children={selectAfter("fromCurrency")} />
                        </InputGroup>
                    <DisplayLimits data={data.from} type={params.fromCurrency} warningType={'send'}/>
                </Box>
                </Center>
                
                <Box width='100px' style={{marginTop:'-35px'}}>
                    <Switch theme="outline" size="21" fill="#1C3574"/>
                </Box>

                <Center>
                <Box >
                    {/* <Input  value = {data.to.amount} addonAfter={selectAfter("toCurrency")} placeholder="得到的数量" addonBefore="得到"/> */}
                    <InputGroup>
                            <InputLeftAddon
                                children="得到"
                                
                                />
                            <ChakraInput value = {data.to.amount} placeholder="得到的数量" focusBorderColor="blackAlpha.100"/>
                            <InputRightAddon children={selectAfter("toCurrency")} />
                        </InputGroup>
                    <DisplayLimits data={data.to} type={params.toCurrency}/>
                </Box>
                </Center>
            </HStack>
            <br/>
            <PriceTypeOption />
            <br/>
            <div className="makeOrder">
                <Space size="large">
                    <div className="addressBox">
                        <Stack spacing={3}>
                        <Tooltip label="地址错误" bg="red.400" hasArrow isOpen={!isAddrValid}>
                            <ChakraInput variant="flushed" value = {params.toAddress} placeholder = {"您的 "+findSymbol(params.toCurrency)+" 地址"} onChange = {e=>setParams({...params, toAddress: e.target.value})}/>
                        </Tooltip>    
                            {currencies[currencies.findIndex(elm => elm.currency === params.toCurrency)].network === 'BEP2'&&
                                <ChakraInput variant="flushed" placeholder="memo (可选）" onChange = {e=>setParams({...params, memo: e.target.value})}/>}
                        </Stack>
                    </div>
                    {isSubmitting?
                    <button class="button is-link is-rounded is-bold is-loading">*兑换</button>: 
                    <button class="button is-link is-rounded is-bold" disabled={!validSubmission()} onClick={handleSubmit}><Lightning theme="outline" size="20" fill="#fff"/>兑换</button>}
                </Space>
            </div>  
        </div>
    )

    return (
       
        <div className='has-text-centered cryptoExchange'>
            
            <div style={{paddingBottom:'40px'}}><h1>加密货币闪电兑换⚡️</h1></div>


            {Exchange()}
        </div>
        
        
    )
}