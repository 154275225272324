import React from "react";
// import "./Home.css";
import Nav from "./Nav"
import Footer from '../components/footer'
import { layoutGenerator } from 'react-break';
import {Collapse, Timeline,BackTop,List } from 'antd';
import {faq} from './text';
import Loanlist from "../components/loanList";
export default function Loan(props) {
  const layout = layoutGenerator({
    mobile: 0,
    phablet: 550,
    tablet: 768,
    desktop: 992,
  })
  const OnAtMostTablet = layout.isAtMost('tablet');
  const OnDesktop = layout.is('desktop');
  const { Panel } = Collapse;

  const list = (array) => array.map( (item, i) =>
    <Panel header={item.header} key = {i+1}>
            <div className="message is-link">
                <p className="message-body">{item.text}</p>
            </div>
    </Panel>
)
    
    
  
  const peersfer = (
    <div class="columns is-desktop">
    <div class="column is-one-third is-offset-1 has-text-centered">
      <div className='peersfer'>
        <h3>在根据您的个人实际情况做完个性化评估后，我们可以给<b>您定制化提供以下几种免费的服务咨询和技术支持：</b></h3> 
        <figure style={{marginTop:'150px'}}>
                <img src={ require('../image/illustrators/rocket_boy.png').default} />
        </figure>
      </div>
    </div>
    <div class="column faq-background">
      
        {list(faq.cryptoSaving)}
      
    </div>
  </div>
  )

  

  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    <div id={'transfer'}>
      <div >
        <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} />     
        <div className="hero-body">
          <div className="container">
            <div className="columns ">
              <div className="column is-4 is-offset-1 landing-caption">
                <div>
                  <h1 className='is-bold'>
                  以币借币<br/>
                  </h1>
                  
                <h3>
                极低利率 · 安全质押 · 利率低至币安的 50% </h3>
                <h3> 支持 BTC, ETH 等 8 种主流加密货币与稳定币 </h3>
                {/* <h3 className='is-5'><span className='tag is-link is-light is-bold is-medium'>私人定制财富管理 · 储蓄与贷款</span> </h3>  */}
                {/* <div class="tags has-addons is-bold has-text-justified">
                  <span class="tag is-medium is-link  ">当前 1 枚比特币 BTC 价格为: </span>
                  <span class="tag is-medium is-link is-light">{BTCPrice} 美元</span>
                </div> */}

                <a class="button is-link is-bold" href='#mission'>  
                            <p>立即体验</p>
                </a>
                </div>
                
                 
              </div>

              <div className="column is-offset-1">
                <OnAtMostTablet>
                <figure >
                </figure> 

                </OnAtMostTablet>
                <OnDesktop>
                <img style={{width:'570px'}} src="Investment.png"/>
                </OnDesktop>
              </div>
              
            </div>

          </div>
        </div>
      </div>

    <div className='hero-body'>
        <Loanlist columns={4} />
    </div>

      <div className='hero-body' style={{marginBottom:"10%"}}>
        
          
        <div className='columns'>
            
            
            
            <div class="column faq-background is-7" style={{marginLeft:'5%'}}>
                <Collapse defaultActiveKey={['1']} ghost={true} bordered={false} style={{fontSize:'17px'}}>
                    {list(faq.cryptoLoaning)}   
                </Collapse> 
            </div>
            
        </div>
        
          
      </div>

    </div>

    <Footer/>
   
</>
  );
}

