import React, { useState } from "react";
import { useFormFields } from "../libs/hooksLib";
import { Auth } from "aws-amplify";
import {createBalance, createUserProfile, invitationCodeVerify} from "../containers/API"
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import passwordValidator from 'password-validator'
import validator from 'email-validator'
import Nav from "./Nav"
import {Eye, EyeOff, Mail, Key} from '../components/svg'
import {failure} from '../components/notifications'
import { Progress } from 'antd';
import {assetList} from '../components/selfDefine'

export default function Signup(props) {
	const history = useHistory();
	let schema = new passwordValidator();
	schema
	.is().min(6)                                    // Minimum length 8
	.is().max(30)                                  // Maximum length 100
	// .has().uppercase()                              // Must have uppercase letters
	// .has().lowercase()                              // Must have lowercase letters
	// .has().digits()                                 // Must have digits
	// .has().symbols()
	.has().not().spaces()                           // Should not have spaces
	// .is().not().oneOf(['Passw0rd', 'Password123']);
	

	
	const { userHasAuthenticated, resendConfirm, email, setEmail, setPassword, password, setResendConfirm} = useAppContext();
	const [inputType, setInputType] = useState("password");
	const [percent, setPercent] = useState(0)
    const [fields, handleFieldChange] = useFormFields({
      email: email,
      password: password,
      confirmPassword: "",
	  confirmationCode: "",
	  invitationCode: ""
    });
    const [newUser, setNewUser] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState(' ')
	
    function validateForm() {
        return (
          fields.email.length > 0 &&
		  schema.validate(fields.password) &&
		  validator.validate(fields.email)&&
          fields.password === fields.confirmPassword
        );
    }
    function validateConfirmationForm() {
		return fields.confirmationCode.length > 0;
	}
	
	function showPW(event){
		event.preventDefault();
		inputType === "password"?setInputType("text"):setInputType("password")
		
	}



    async function handleSubmit(event) {
        event.preventDefault();
				let isCodeOk = "NaN"; //这么写安全吗，还是应该用 useState
				setIsLoading(true);
				try {
					isCodeOk = await invitationCodeVerify(fields.invitationCode)
					if (isCodeOk){
						try {
							const newUser = await Auth.signUp({
								username: fields.email,
								password: fields.password
							});
							setIsLoading(false);
							setNewUser(newUser);
						} catch (e){
							let info = 'An account with the given email already exists.'
							e.message === info?
								info = '该邮箱已经注册了账户':
								info = e.message
							
							failure('注册失败', info)
							setIsLoading(false)
						}
					} else {
						failure('此邀请码不存在', '')
						setIsLoading(false)
					}

				}catch(e){
					failure('无法验证邀请码', e.message)
					setIsLoading(false)
				}
				

    }
    
    async function handleConfirmationSubmit(event) {
        event.preventDefault();
    
				setIsLoading(true);
				
				try {
					await Auth.confirmSignUp(fields.email, fields.confirmationCode);
					setPercent(25)
					setMessage('验证成功, 正在登陆 ...')
					
					try{
						await Auth.signIn(fields.email, fields.password);
						setPercent(50)
						setMessage('登陆成功, 正在生成账户 ...')				
					} catch (e){
						failure('登陆失败', e.message)
						setIsLoading(false);
					}
	
					try {
						// const userDetails = await Auth.currentUserInfo();
						setPercent(75)
						await createUserProfile(fields.email);
						userHasAuthenticated(true);
						setPercent(99.9)
						setMessage('已经成功生成账户, 正在跳转 ...')
						setEmail('')
						setPassword('')	
						setResendConfirm(false)		
						history.push("/dashboard");	
						
					} catch (e){
						const title = '生成账户失败, 正在加载主页'
						const description = e.message
						failure(title, description)
						setIsLoading(false);
					}
				} catch (e) {
					const description = e.message;
					const title = '验证失败'
					failure(title, description)
					setIsLoading(false);
				}
				
				


				

				


		}
		
	function ProgressAnime(){
		return(
			<Progress
				strokeColor={{
					from: '#108ee9',
					to: '#87d068',
				}}
				percent={percent}
				status="active"
			/>
		)
	}


    function renderConfirmationForm() {
        return (
			<div class='hero-body'>
            <form class="Login" onSubmit={handleConfirmationSubmit}>
                <div class="field">
                  <label class="label">请输入验证码</label>
                  <div class="control">
                    <input autoFocus class="input" id='confirmationCode' type="tel" onChange ={handleFieldChange} value={fields.confirmationCode}/>
                  </div>
									<hr/>
                  <p class="help">验证码已发送至您的邮箱（注：邮件可能会进入垃圾箱）</p>
                </div>
								
                <div class="field is-grouped is-grouped-centered">
                		  <div class="control">
								{ isLoading?
								<>
								<button class="button is-rounded is-bold is-link is-loading" type="submit">验证</button>
								</>
								:
                		    <button class="button is-rounded is-bold is-link" type='submit' disabled={!validateConfirmationForm()}>验证</button>
                				}
                		</div>
                	</div>

					{isLoading&&

					<div style={{ width: 300 }}>
						{ProgressAnime()}
						<p>{message}</p>
					</div>

					}
            </form>
			
			</div>
				);
		}
		
		function renderForm(){
			return (
				<div className='hero-body'>
				<form className="Login" onSubmit = {handleSubmit}>

					<div class="field" >
					  <label class="label">邀请码（必填）</label>
					  <div class="control has-icons-left">
					    <input class="input" autoFocus id='invitationCode' type="text" placeholder="请联系理财经理获取邀请码" value={fields.invitationCode} onChange={handleFieldChange}/>
					  	<span class='icon is-left'><Key /></span>
					  </div>
			
					</div>

					<div class="field" >
					  <label class="label">邮箱</label>
					  <div class="control has-icons-left">
					    <input class="input" autoFocus id='email' type="email" placeholder="example@email.com" value={fields.email} onChange={handleFieldChange}/>
					  	<span class='icon is-left'><Mail/></span>
					  </div>
					  {!validator.validate(fields.email)&&fields.email.length>3&&<p class='help has-text-danger'>邮箱格式不正确</p>}
					</div>
				
					<div class="field" id="password">
					<label class="label">密码</label>
					  <div class="control has-icons-right has-icons-left">
					    <input class="input" id='password' type={inputType}  value={fields.password} onChange={handleFieldChange}/>
						
						<span class="icon is-right" onClick={(e)=>showPW(e)}>
						{inputType === 'password'?<EyeOff />:<Eye />}
						</span>
					  </div>
					  
						{fields.password.length<6&&fields.password.length>1?<p class="help has-text-danger">密码长度至少 6 位数</p>:<></>
						// <p class="help has-text-success">密码长度至少 8 位数</p>
						}
						{/* {schema.validate(fields.password)&&fields.password.length>7?<p class="help has-text-success">包括数字，大小写字母，特殊字符</p>:<p class="help has-text-danger">包括数字，大小写字母，特殊字符</p>} */}
					</div>

					<div class="field" >
					<label class="label">再次输入密码</label>
					
					  <div class="control has-icons-right has-icons-left">
					    <input class="input" id='confirmPassword' type={inputType}  value={fields.confirmPassword} onChange={handleFieldChange}/>
						<span class="icon is-right" onClick={(e)=>showPW(e)}>
						{inputType === 'password'?<EyeOff />:<Eye />}
						</span>
					  </div>
					{(fields.password !== fields.confirmPassword)&&fields.confirmPassword.length>1? <p class="help is-danger">两次密码不一致</p>:
					<></>
					
					}
					</div>


					<hr />
					<div class="field is-grouped is-grouped-centered">
					  <div class="control">
							{ isLoading?
							<button class="button is-rounded is-bold is-link is-loading" type="submit">注册</button> :
					    <button class="button is-rounded is-bold is-link" type='submit' disabled={!validateForm()}>注册</button>
							}
							</div>
							
					</div>
				</form>
				
				</div>
			);
		}

	return(
		<>
		<Nav />
		<div class = "signUp">
			{resendConfirm?renderConfirmationForm():(newUser === null ? renderForm() : renderConfirmationForm())}
		</div>
		</>
	);

}