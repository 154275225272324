import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import Nav from "./Nav"
import Footer from '../components/footer'
import {BackTop } from 'antd';
import { Order, TagOne, Bitcoin,Search} from "@icon-park/react";
import {  getOrderByIdAddr } from "./API";
import { failure } from "../components/notifications";



export default function CheckOrder(props) {
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const [params, setParams] = useState({
        id: '',
        toAddress: '',
        memo: '',
    })
    const regexp = '/ /g' //for checking and removing space
    const day = 1000*60*60*24

   
    function validateForm(){
        return params.id.length>0 && params.toAddress.length>0
    }

    async function handleSubmit(e){
        e.preventDefault()
        setIsLoading(true)
        let address
        if (params.memo.replace(regexp, '')){
            address = (params.toAddress+':'+params.memo).replace(regexp, '')
        }else{
            address = params.toAddress.replace(regexp, '')
        }
        const data = {
            id: params.id.replace(regexp, ''),
            toAddress: address 
        }
        
        try {
            const re = await getOrderByIdAddr(data)
            
            if(re.status === '200'){
                
                localStorage.setItem('order', JSON.stringify(re.data));
                localStorage.setItem('exp', day+Date.now())
                history.push('/order');
            }else{
                failure('验证失败', '订单号或地址输入错误')
            }
        }catch(e){
            failure('请求失败', e)
        }
        setIsLoading(false)
    }

   
    function Form(){
        return(
            <form class="Login"onSubmit={handleSubmit} >
            <div class="field">
              <label class="label">订单号</label>
              <div class="control has-icons-left">
                <input class="input" type="text" value={params.id} onChange={e => setParams({...params, id: e.target.value})}/>
                <span class='icon is-left'><Order theme="outline" size="18"  strokeWidth={3}/></span>
              </div>
           
            </div>
    
            <div class="field">
            <label class="label">您的收币地址</label>
              <p class="control has-icons-right has-icons-left">
                <input class="input" type="text" value={params.toAddress} onChange={e => setParams({...params, toAddress: e.target.value})}/>
                <span class='icon is-left'><Bitcoin theme="outline" size="18" strokeWidth={3}/></span>
              </p>
            </div>

            <div class="field">
              <label class="label">memo（可选）</label>
              <div class="control has-icons-left">
                <input class="input" type='text' value={params.memo} onChange={e => setParams({...params, memo: e.target.value})}/>
                <span class='icon is-left'><TagOne theme="outline" size="18" strokeWidth={3}/></span>
              </div>
           
            </div>

            <hr />
            <div class="field is-grouped is-grouped-centered">
              <div class="control">
                    { isLoading?
                    <button class="button is-rounded is-bold is-link is-loading" type="submit">*查询订单</button> :
                <button class="button is-rounded is-bold is-link" type='submit' disabled={!validateForm()}><Search theme="outline" size="24" fill="#fff" strokeWidth={3}/>查询订单</button>
                    }
                    </div>
            </div>
        </form>
        )
    }


    return (
        <>
        <BackTop style={{marginBottom: '150px'}}/>
        
        <div  className='hero' style={{backgroundColor:'#FAFAFB'}}>
        <Nav /> 
        <div className='hero-body' >
        <div class="container">
            {Form()}
            
        </div>
        </div>
        </div>
        
        <Footer/>
    
    </>
    );
}
