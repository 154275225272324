import React, {  useEffect, useState} from "react";
import { Select, Modal, Button, Space, Radio, Tooltip } from 'antd';
import {RightArrow, ThreeDotsLoader} from '../components/svg'
import {getOrder, newDeposit} from '../containers/API'
import {failure, depositNotification} from '../components/notifications'
import {assetList, findIndexByType} from './selfDefine'
import { useAppContext } from "../libs/contextLib";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Stack, Divider, InputGroup,InputRightAddon, Text, HStack, Center} from "@chakra-ui/react"
import { Buy,BankTransfer, Attention } from "@icon-park/react";
import Nav from "../containers/Nav";
import moment from 'moment';
import { Logo } from "./coinLogo";


export default function Orders(props){
	const location = useLocation()
	const history = useHistory()
	const { spend, recieve, price , bankData, orderId, createdAt, fromHistory, status} = location.state
    const { isAuthenticated} = useAppContext()
    const [params, setParams] = useState({
        address: '',
        orderId: '',
        createdAt: '',
        spend: '',
        recieve: '',
        price: ''
    })
    const [isPageLoading, setIsPageLoading] = useState(true)
    async function onLoad(){
        if (!isAuthenticated){
			history.push('/login')  
        return;
        }
        try {
            const result = await getOrder();
            console.log(result)
            setParams({
                address: result.bank,
                orderId: result.createdAt,
                createdAt: result.createdAt,
                spend: result.spend.value,
                recieve: result.get.value,
                price: result.rate
            })
            setIsPageLoading(false)
        }catch(e){
            failure('加载失败', e.message)
        }
    }

    useEffect(()=>{
        if (fromHistory){
            setParams({
                address: bankData,
                orderId: orderId,
                createdAt: createdAt,
                spend: spend,
                recieve: recieve,
                price: price  
            })
            setIsPageLoading(false)
        }else{
            setTimeout(()=>{
                onLoad(); //wait 1s for previous unfinished dynamodb put action    
            }, 1000)
            
        }
    }, [])

    function ShowLogo(props) {
        const text = props.text
        const figure = props.figure
        return(
            <HStack>
                <Buy theme="outline" size="24" fill="#276CDA" strokeWidth={3}/>
                <h2 style={{fontSize:'20px'}}>{text}</h2>
                <figure class="image is-24x24">
                    {figure}
                </figure>    
            </HStack>)    
    }
    
	return(
		<div>
			<Nav />
            {
                isPageLoading?
            <div id='loading'>
				<ThreeDotsLoader />
			</div>:

            
			<div class= 'container' style={{paddingBottom:'20%'}}>
				
				<Box  pt='20px' w='650px' m='auto' className='card round-card'>
                    <Box className='card-content'>
                        <Stack spacing={30} > 
                            <Box fontWeight='semibold' >
                                <Box >
                                    <ShowLogo text="购买 USDT" figure={<Logo.USDT />}/>
                                </Box>    
                            </Box>
                            <Box >
                                <Box className='orderDetails'>
                                    <Text fontWeight='semibold'>价格 </Text>
                                    <Text>1 USD = {price} USDT</Text>
                                </Box>
                                <Box className='orderDetails'>
                                    <Text fontWeight='semibold'>数量 </Text> 
                                    <Text>{Number(recieve).toFixed(6)} USDT</Text>   
                                </Box>
                                <Box className='orderDetails'>
                                    <Text fontWeight='semibold'>支付 </Text>   
                                    <Text>{spend} USD</Text> 
                                </Box>
                            </Box>
                                    
                            <Divider orientation="horizontal"/>
                            <Stack>
                                <Box >
                                    <Text><b>订单号:</b> #{params.orderId}</Text>
                                      
                                </Box>
                                <Box>
                                    <Text><b>创建时间:</b> {moment(params.createdAt).format('lll')}</Text> 
                                    
                                </Box>
                            </Stack>
                            {
                                status === '300'&&
                                    
                            <Box >
                                <HStack>
                                    <BankTransfer theme="outline" size="24" fill="#276CDA" strokeWidth={2.5}/>
                                    <Text fontWeight='semibold'>支付方式</Text>
                                </HStack>
                                <Box pt='10px'>
                                    <Text>请用与本人护照<b>同名的账户</b>将 <b>{spend} USD</b> 转入以下银行账户</Text>
                                    <Text><span className='tag is-warning is-light is-bold'>不是同名的款项将原路退回，由此导致的拒绝入账手续费和原路退回手续费均由发起转账人承担</span></Text>
                                </Box>
                                <Box bgColor='#F7F8F9' m='20px 10px' borderRadius='5px'>
                                    <Box p="30px 20px">
                                        <Box><b>Beneficiary Name (账户名)</b>: {params.address.beneficiaryName}</Box>
                                        <Box><b>Bank Name (银行名)</b>: {params.address.bankName}</Box>
                                        <Box><b>Bank Address (银行地址)</b>: {params.address.bankAddr}</Box>
                                        <Box><b>Beneficiary/Company Address (公司地址)</b>: {params.address.beneficaryAddr}</Box>
                                        <Box><b>Wire Reference (附言备注)</b>: {params.address.wireRef}</Box>
                                        <Box><b>Routing Number (路由号)</b>: {params.address.routingNum}</Box>
                                        <Box><b>Account Number (账号)</b>: {params.address.accountNum}</Box>
                                        <Box><b>SWIFT code (SWIFT 码)</b>: {params.address.swiftCode}</Box>
                                    </Box>
                                    
                                </Box> 
                                <Box> <HStack><Attention theme="outline" size="24" fill="red" strokeWidth={3} strokeLinecap="square"/> <b>注意：Wire Reference (附言备注) 错误可能会导致资金无法入帐 </b></HStack></Box>
                                
                            </Box>
                            }
                        </Stack>
                        
                    </Box>
                               
				</Box>
			</div>
			}
		
        
        </div>

		
	)
}