import React, {  useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";
import { getUserDepositHistory, getUserWithdrawalHistory} from "../containers/API"
import { Table, Space, Tabs } from 'antd';
import {failure} from '../components/notifications'
import { Link, useHistory} from "react-router-dom";
import {Logo} from "../components/coinLogo"
import { HStack, Box, Text } from "@chakra-ui/react"
import { statusHelper } from "../libs/statusHelper";
export default function Wallet(props){
  const data = props.data
  const {TabPane} = Tabs;
  const history = useHistory();
  const [depositHistory, setDepositHistory] = useState()
  const [withdrawalHistory, setWithdrawalHistory] = useState({
    fiat: '',
    crypto: ''
  })
  const [clicked, setClicked] = useState({
    depositHistory: false,
    withdrawalHistory: false,
  })
  const [activated, setActivated] = useState({
    tabDepositHistory: false,
    tabWithdrawalHistory: false,
  })

  const { isAuthenticated} = useAppContext();

    const columns = [

		{
			title: '币种',
			key: 'name',
      render: (text, record)=>(
        <HStack>
          <figure class="image is-24x24">
            {Logo[text.name]()}
          </figure>
          <div>
            {text.name}
          </div>
        </HStack>
      )
		},

		{
			title: '全部',
      key:'balance',
			render: (text)=>((Number(text.available) + Number(text.pending)).toFixed(6))
		},
    {
			title: '可用',
			dataIndex: 'available',
			key:'available'
		},
    {
      title: '处理中',
      dataIndex: 'pending',
      key: 'pending'
    },
    {
      title: '年化',
      key: 'rate',
      render: (e) => <Text>{(e.rate*100).toFixed(2)}%</Text>
    },
		{
			title: '累计收益',
			dataIndex: 'interest',
			key:'interest',
				
		},
    {
        title: '操作',
        key: 'action',
        render: (text, record)=>(
            <Space size="large" >
                <Link to={{pathname: "/deposit", state: {
                  type: text.name,
                  // available: text.avaliable,
                  } }}><span className='tag'>充值</span></Link>
                <Link to={{pathname: "/withdrawal", state: {
                  type: text.name,
                  available: text.available,
                  } }}><span className='tag'>提现</span></Link>
                
                
                <Link to={{pathname:'/interest', state:{
                  type: text.name,
                  totalInterest : text.interest,
                  rate: (text.rate*100).toFixed(2)
                }}}><span className='tag'>收益记录</span></Link>
                {text.name==='USDT'&&
                <>
                <Link to={{pathname:'/buy', state:{
                  type: text.name,
                }}}><span className='tag is-link'>购买</span></Link>
                <Link to={{pathname:'/buy_history'}}><span className='tag is-link is-light'>订单历史</span></Link>
                </>
                }
            </Space>
        )
    }

	]

  useEffect(()=>{
    
    if (activated.tabDepositHistory){
      displayDepositHistory();
      setActivated({...activated, tabDepositHistory: false}) 
      // console.log('call deposit history api')
    }else if(activated.tabWithdrawalHistory){
      displayWithdrawalHistory();
      setActivated({...activated, tabWithdrawalHistory: false})
      // console.log('call withdrawal history api')
    }
    
  }, [clicked.depositHistory, clicked.withdrawalHistory])

  async function displayDepositHistory(){
    if (!isAuthenticated){
			history.push('/login')  
      return;
    }
    try{
      const result = await getUserDepositHistory()
      setDepositHistory(result)
    }catch(e){
      failure('加载失败', e.message)
    }
  }

  async function displayWithdrawalHistory(){
    if (!isAuthenticated){
			history.push('/login')  
      return;
    }
    try{
      const result = await getUserWithdrawalHistory()
      setWithdrawalHistory(result)
      console.log('withdrawal history', result)
    }catch(e){
      failure('加载失败', e.message)
    }
  }

  const depositHistoryColumns = [
    {
      title: '币种',
      key: 'name',
      render: (text)=>(
        <HStack>
          <figure class="image is-24x24">
            {Logo[text.data.name]()}
          </figure>
          <div>
            {text.data.name}
          </div>
        </HStack>
      ),
    },
    {
      title: '数量',
      key: 'amount',
      render: e=> e.data.amount
    },
    {
      title: '状态',
      key: 'status',
      render: e=> statusHelper(e.status)
    },
    {
      title: '时间',
      key: 'createdAt',
      render: e=> new Date(e.createdAt).toLocaleString()
    },
    
  ]
  const withdrawalColumns = [
    {
      title: '币种',
      key: 'name',
      render: (text)=>(
        <HStack>
          <figure class="image is-24x24">
            {Logo[text.data.name]()}
          </figure>
          <div>
            {text.data.name}
          </div>
        </HStack>
      ),
    },
    {
      title: '数量',
      key: 'amount',
      render: (text)=> <Text>{text.data.amount}</Text>,
    },
    {
      title: '状态',
      key: 'status',
      render: e=> statusHelper(e.status)
    },
    {
      title: '时间',
      key: 'createdAt',
      render: e=> new Date(e.createdAt).toLocaleString()
    },
    
  ]
  const cashColumns = [
    {
      title:'币种',
      key:'name',
      render: (text)=>(
        <HStack>
          <figure class="image is-24x24">
            {Logo[text.data.name]()}
          </figure>
          <div>
            {text.data.name}
          </div>
        </HStack>
      ),
    },
    {
      title:'数量',
      key: 'amount',
      render: (text)=> <Text>{text.data.amount}</Text>,
    },
    {
      title:'得到',
      key: 'get',
      render: (text)=> <Text>{text.data.get} USD</Text>
    },
    {
      title: '收款人',
      key: 'userName',
      render: (text)=> <Text>{text.data.userName} {text.data.account}</Text>
    },
    {
      title:'状态',
      key:'status',
      render: e=> statusHelper(e.status)
    },
    {
      title:'时间',
      key:'createdAt',
      render: e=> new Date(e.createdAt).toLocaleString()
    },
  ]
  
  async function callback(key){
    if(key === '2'){
      setActivated({...activated, tabDepositHistory: true})
      setClicked({...clicked, depositHistory: true})
      
    }else if(key ==='3'|| key === '4'){
      setActivated({...activated, tabWithdrawalHistory: true})
      setClicked({...clicked, withdrawalHistory: true})
    }
  }

    
  return (
    <Tabs defaultActiveKey="1" onTabClick={callback}>
      <TabPane tab="主界面" key="1">
        <Box pt='2%'>
          <Table columns={columns} dataSource={data.assets} />
        </Box>
      </TabPane>
      <TabPane tab="充值记录" key="2">
        <Box pt='2%'>
          <Table columns={depositHistoryColumns} dataSource={depositHistory} />
        </Box>
      </TabPane>
      <TabPane tab="提币记录" key="3">
        <Box pt='2%'>
          
          <Table columns={withdrawalColumns} dataSource={withdrawalHistory.crypto} />
           
        </Box>
      </TabPane>
      <TabPane tab="提现记录" key="4">
        <Box pt='2%'>
          
          <Table columns={cashColumns} dataSource={withdrawalHistory.fiat} />
          
          
        </Box>
      </TabPane>
    </Tabs>

    )
    
}