import React from "react";
import { notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

export const modifyNotification = () => {
    notification.open({
      message: '修改银行信息成功！',
      description:
        '阁下已经成功修改提现银行信息 ！',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };

export const newNotification = () => {
    notification.open({
      message: '新建银行信息成功！',
      description:
        '阁下已经成功新建银行信息 ！',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };

export const withdrawalNotification = (information) => {
    notification.open({
      message: '提现请求提交成功！',
      description: information,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };

export const depositNotification = (information) => {
    notification.open({
    message: '充值请求提交成功！',
    description: information,
    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
};

export const success = (title, info='') => {
    notification.open({
    message: title,
    description: info,
    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
}

export const failure = (title, information)=>{
    notification.config({
        duration: null,
      });
    notification['error']({
        message: title,
        description: information
      });
}