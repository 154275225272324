import React from "react";
import { Link} from "react-router-dom";
import {Download} from "../components/svg"
import {Logo} from "../components/coinLogo"
export const rule = (
    <>
    <ul style={{listStyleType: "circle"}}>
      <li>完全合法的国家和地区:</li>
      <p>美国，欧盟，加拿大，中国香港，英国，澳大利亚，新加坡，新西兰，日本，韩国，印度等</p>
      
      <li>个人持有和交易比特币合法，但是禁止或者限制开设法币交易所的国家和地区：</li>
      <p>中国大陆，阿联酋，台湾地区等</p>

      <li>完全禁止比特币等国家和地区:</li>
      <p>埃及，摩洛哥，巴基斯坦等</p>
    </ul>
    <p className='help has-text-grey'>截止到 2020 年 3 月 10 日信息</p>
    </>
  )

export const fee = (
  <>
  <p>用户只需支付给持牌加密货币交易所、信托公司或者场外交易平台的交易手续费（每家 0%~0.5% 不等），并且要承担这期间加密货币波动的风险。如果用户需要 Peersfer Family Office 推荐信赖的买币卖币的交易所，信托公司或者场外交易商家，我们不收取任何额外费用。</p>
  <Link to='/plan'>    
    <button className='button is-bold is-link' >
        买卖加密货币交易手续费参考表  <Download />
    </button>
  </Link>       
  </>
)
export const toCustomer = (
    <ol>
      <li><p>使用内地银行 App 或者柜面跨境转账服务时会把自己离岸账户的所有信息呈交给人民银行和外管局，个中风险，自行斟酌；</p></li> 
      <li><p>内地外管局严查 “蚂蚁搬家” 式跨境汇款；</p></li>
      <li><p>任何在境外合规的钱庄，在内地的收款账户绝大多数都是暴露在大数据监控之下的，此外这类钱庄没有任何售后服务保障，不会协助您对资金来源和银行尽职调查提供任何帮助；</p></li>
      <li><p>任何所谓的 “合法” 对敲操作，如无法获悉对敲者的资金来源证明，并被离岸银行系统筛查后进入尽职调查流程时，对敲伙伴将无法协助您解释资金的来源，账户或者资金可能会被冻结；</p></li>
      <li><p>中国法律、行政法规并未禁止比特币的持有，上海市第一中级人民法院在 2020 年 5 月 6 日的一起宣判中认定比特币属于网络虚拟财产，法律上予以保护。比特币属于网络虚拟财产，应从法律上予以保护。比特币可以转让并产生经济收益。因比特币具有价值性、稀缺性、可支配性等特点，具备权利客体的特征及虚拟财产的商品属性，符合虚拟财产的构成要件。所以，在中国内地持有和私人之间买卖比特币等加密货币是合法合规的。</p></li>
    </ol>
  )

const goldCashout = (
  <ol>
    <li><p>黄金必须在当地时间的黄金交易日交割，卖出时间在 24 小时之内。提现美、欧、加、澳、新加坡元，港币，英镑数小时内到账，其它支持的法币 1-3 个工作日，遇当地法定节假日顺延。</p></li>
    <li><p>提现人民币最快在 2 小时内到账</p></li>
  </ol>
)

const goldWhyUs= (
  <>
  <p>本产品与中国以及国际上主要的黄金交易所进行对比的优势如下：</p>
  <ol>
    <li><p>本产品黄金购买门槛为 0.01 金衡盎司，约等于 19 美金。市面上其它交易所由 150 美元到 600,000 美元不等</p></li>
    <li><p>本产品的黄金交易结算时间为即时。其它交易所为 T+2 天到 T+5 天不等</p></li>
    <li><p>本产品为实物记名黄金，其它黄金交易所或者传统银行大部分为非记名黄金（非实物）</p></li>
    <li><p>本产品由纽约金融服务局（NYDFS) 监管</p></li>
  </ol>
  </>
)

export const aboutFee = (
  <>
  <p>盈路向前支持随存随取，可提现成包括美元/欧元/英镑在内的 37 种法币货币。到账时间按照币种不同，时效性有以下区别：</p>
  <ul style={{listStyleType: "circle"}}>
    <li>
      <p>美元: 最快数小时内到账。无任何手续费（免除第三方加密货币交易所交易费）。</p>
    </li>
    <li>
      <p>人民币: 最快在 2 小时内到账。无任何手续费（第三方加密货币交易所收取不超过 1% 的交易费）。</p>
    </li>
    <li>
      <p>其它 35 种币种：最快为 24 小时到账。无任何手续费（第三方加密货币交易所收取不超过 2% 的交易费）。</p>
    </li>
    <li>
      <p>可申请 Visa， Mastercard 和银联借记卡直接消费，无任何手续费。</p>
    </li>
    <li>
      <p>可购买记名实体黄金。</p>
    </li>
  </ul>
  <br></br>
  <h3>备注:</h3>
  <ol>
    <li>
      <p>其它 35 个币种为：欧元，英镑，港币，日元，加元，澳大利亚元，新西兰元，新加坡元，阿联酋迪拉姆，保加利亚列弗，巴林第纳尔，瑞士法郎，捷克克朗，丹麦克朗，克罗地亚库纳，匈牙利福林，印度尼西亚盾，以色列新谢克尔，印度卢比，肯尼亚先令，科威特第纳尔, 墨西哥比索，马来西亚林吉特，挪威克朗，阿曼里亚尔，菲律宾比索，卡塔尔里亚尔， 波兰兹罗提，罗马尼亚列伊，沙特里亚尔，瑞典克朗，泰国铢，土耳其里拉，乌干达先令，南非兰特。</p>
    </li>
    <li>
      <p>除人民币外，其它币种到账时间在周末及法定假日顺延，请以实际入账时间为准。</p>
    </li>
  </ol>
  </>
)

export const faq = {
    transfer:[
      {
        header: '什么是自助免费买卖加密加密货币？',
        text: <p>简而言之，用户在 A 地区的持牌合规加密货币交易所或者场外交易平台用当地法币 a 购买某种加密货币 <i class="fab fa-btc"></i>，然后在 B 地区的持牌合规加密货币交易所或者场外交易平台把加密货币 <i class="fab fa-btc"></i> 卖出获得当地法币 b。</p>
      },

      {
        header: '用户需要做哪些准备？',
        text:'用户需要在 A，B 地区注册加密货币交易所或者场外交易平台的账户，并准备好能支付法币 a 和接受法币 b 的银行账户，对加密货币有一定了解。'
      },


      {
        header: "法币 a 支付给 A 地区的谁",
        text: '法币 a 直接充值到 A 地区的持牌合规加密货币交易所或者转入场外交易平台的卖家指定账户里，Peersfer Family Office 公司账户不会接收用户的法币 a。'
      },

      {
        header: '由 B 地区的谁支付法币 b？',
        text: 'B 地区持牌合规加密货币交易所或者由场外交易平台买家直接把法币 b 转账到用户指定银行账户，Peersfer Family Office 公司账户不会直接转账法币 b 给用户。'
      },

      {
        header: '卖出加密加密货币多久后资金能到账？',
        text: '美、欧、加、澳、新加坡元，港币，英镑数小时内到账，其它支持的法币 1-3 个工作日，遇当地法定节假日顺延。'
      },

      {
        header: '支持的币种？',
        text: '支持 36 种主流货币：美元，欧元，英镑，港币，日元，加元，澳大利亚元，新西兰元，新加坡元，阿联酋迪拉姆，保加利亚列弗，巴林第纳尔，瑞士法郎，捷克克朗，丹麦克朗，克罗地亚库纳，匈牙利福林，印度尼西亚盾，以色列新谢克尔，印度卢比，肯尼亚先令，科威特第纳尔, 墨西哥比索，马来西亚林吉特，挪威克朗，阿曼里亚尔，菲律宾比索，卡塔尔里亚尔， 波兰兹罗提，罗马尼亚列伊，沙特里亚尔，瑞典克朗，泰国铢，土耳其里拉，乌干达先令，南非兰特。'
      },

      {
        header: '各个国家和地区对于持有或者交易比特币的法规是什么？',
        text: rule
      },

      // {
      //   header: '用户需要支付给 Peersfer Family Office 哪儿些费用？',
      //   text: fee
      // },


      {
        header: 'Peersfer Family Office 无偿服务和法律合规声明',
        text: 'Peersfer Family Office 作为信息咨询方仅推荐信赖的第三方服务与客户对洽，请客户自觉遵守各个国家和地区的加密加密货币和其它相关法律法规，自行决定是否使用该服务，任何牵涉到的法律法规问题由客户本人承担。所有第三方服务均为客户自己实名认证账户，与 Peersfer Family Office 无关。'
      },

      {
        header: <b>对于中国内地客户的几点特殊说明：</b>,
        text: toCustomer
      }

    ],

    saving:[
      {
        header: '什么是「盈路向前」',
        text: '盈路向前是一款随存随取的阶梯式外币储蓄产品，支持美元，欧元，英镑。北京时间每天 24 点前购买当日即起息，次日 12 点之后可获得收益。'
      },

      {
        header: '与我们是什么关系',
        text:'产品与服务是由美国，欧洲和新加坡领先的持牌金融科技集团直接提供，Peersfer Family Office 作为信息展示平台的合作方，不会接触用户资金。'
      },

      {
        header: '存入「盈路向前」有风险吗',
        text: '客户资金均托管于受美国南达科他州银行部以及美国纽约州金融服务厅 (NYDFS) 和内华达州工商部 (DBI) 与美国金融犯罪执法网 (FinCEN) 监管的信托公司。'
      },

      {
        header: "产品提现，到账时间及手续费",
        text: aboutFee
      },

      {
        header: '盈路向前为何比普通银行储蓄产品收益高',
        text: '客户的资金将会借给提供足额抵押物的借款人。通常情况下借款人只能借走不超过抵押物价值 60% 的资金。当抵押物价值不足借出资金的 120% 时，抵押物将被平仓以保证安全收回客户借出的足额资金。'
      },

      {
        header: '关于抵押物',
        text: '支持的抵押物为加密资产中流动性极高的币种，如比特币以太坊，瑞波币，币安币，莱特币等。加密货币资产由美国证券交易所批准的著名托管机构 BitGo 进行担保。'
      },

    ],

    gold: [
      {
        header: '购买的黄金存放在哪',
        text: <p>记名实体黄金是由伦敦金银市场协会（LBMA）认可的标准交割金条，金条存放在 <b>Brinks</b> 专业金库中，受到纽约州金融服务部批准且受其监管。且美国顶级的审计机构 Withum 将每月审计确认黄金的供应匹配情况。</p> 
      },
      {
        header: '与我们是什么关系',
        text: <p>产品与服务是由美国纽约金融服务局 (NYDFS) 监管的信托管理公司直接提供， Peersfer Family Office 作为信息展示平台的合作方，不会接触用户的任何法币或黄金资产。</p>
      },
      {
        header: '托管费',
        text: <p>客户黄金资产均<b>免费</b>托管于受美国纽约州金融服务厅监管的信托公司</p>
      },
      {
        header:'黄金如何交割提现',
        text: goldCashout,
      },
      {
        header:'黄金交易开放时间',
        text:'北京时间周一早上 6 点开盘至周六早上 5 点收盘，另外交易日期间每天早上 5 点和 6 点停盘一小时，集中处理前一天交割订单'
      },
      {
        header: '为什么选择我们',
        text: goldWhyUs,
      }
    ],

    cryptoSaving: [
      {
        header: '利息是以什么币种结算的',
        text: '利息按照储蓄的币种类型进行结算。如存入 1 个 BTC，按照 2% 的年化收益，一年后将获得 0.02 个 BTC 为利息。'
      },
      {
        header: '利息多久结算一次',
        text: '本产品所有币种均为活期储蓄，以日计息，随存随取。计息时间为 UTC+08:00 （北京时间）上午 8:00。'
      },
      {
        header: '支持多少个币种',
        text: '目前支持 6 种稳定币（USDT, USDC, DAI, TUSD, PAX, BUSD）以及其它 22 种主流加密货币（BTC, ETH, XRP, XLM, BAT 等)'
      }
    ],

    cryptoLoaning: [
      {
        header: '利息是以什么币种结算的',
        text: '利息按照借出的币种结算，如借出 1 个 BTC，按照 15% 的年利率，一年后需支付 0.15 个 BTC 作为利息。'
      },
      {
        header: '利息多久结算一次',
        text: '本产品所有币种均为灵活借币，以日计息，不计复利，随借随还。计息时间为 UTC+08:00 （北京时间）上午 8:00。'
      },
      {
        header: '支持多少个币种',
        text: '目前支持 6 种稳定币（USDT, USDC, DAI, TUSD, PAX, BUSD）以及其它 22 种主流加密货币（BTC, ETH, XRP, XLM, BAT 等)'
      }
    ]

  }

export const interestDetails = (
    [
      
      {
        label: '2.88%',
        text: '活期'
      },
      {
        label: '3%',
        text: '7 天'
      },
      {
        label: '3.1%',
        text: '14 天'
      },
      {
        label: '3.2%',
        text: '1 个月'
      },
      {
        label: '3.4%',
        text: '3 个月'
      },
      {
        label: '3.7%',
        text: '半年'
      },
      {
        label: '4%',
        text: '一年'
      },
      {
        label: '...',
        text: ''
      },
      
    ]
  )

  const suning = (
    <>
      <ol>
        <li>100 元起充值</li>
        <li>苏宁卡在线上苏宁易购商城（<a href="https://www.suning.com/">https://www.suning.com/</a>），线下苏宁易购门店、苏宁超市、苏宁红孩子、苏宁小店、苏宁易购直营店均可使用，仅限购买苏宁自营商品。</li>
        <li>苏宁卡为不记名卡、不计息、不兑换现金、遗失不补。</li>
        <li>苏宁卡凭卡号及密码使用，请妥善保存，避免因密码遗失、泄露等带来个人损失。</li>
        <li>苏宁卡可永久、多次使用。</li>
        <li>更多帮助及规则请登录苏宁卡网站（<a href="https://ka.suning.com/">https://ka.suning.com/</a>）查询，具体以网站公布为准。</li>
      </ol>
      <hr/>
    <h2><b>Terms & Conditions</b></h2>
    <br></br>
    <p>苏宁易购线下连锁网络覆盖海内外，拥有苏宁易购广场、苏宁云店、苏鲜生、苏宁红孩子、苏宁极物、苏宁汽车超市、苏宁易购直营店、苏宁小店等业态，自营创新互联网门店和网点超 10000 家，稳居国内线下连锁前列；苏宁易购线上通过自营、开放和跨平台运营，跻身中国 B2C 市场前三，且在主流电商中增速领先。</p>
        
    </>
  )

  const tmall = (
    <>
    <ol>
      <li>30 元起充值</li>
      <li>本卡在绑定淘宝账号后可在天猫超市购物使用，天猫超市网址：<a href="https://chaoshi.tmall.com/">https://chaoshi.tmall.com/</a>。</li>
      <li>在天猫超市购物结算时，将优先使用本卡进行支付。</li>
      <li>本卡有效期为购卡之日起 36 个月，具体到期时间及余额可登录电脑端账户页面查询。查询网址：<a href="https://chaoshi.tmall.com/gift-card.htm">https://chaoshi.tmall.com/gift-card.htm</a></li>
      <li>本卡为不记名卡，不挂失，不可提现。使用时请遵守《享淘卡章程》的相关规定。</li>
    </ol>
    <hr/>
  <h2><b>Terms & Conditions</b></h2>
  <br/>
  <p>天猫超市为广大网购消费者提供进口食品、食品饮料、粮油副食、美容洗护、家居用品、家庭清洁、母婴用品等所有生活必需品，商品品质100%保证。随时随地，轻松用手机或鼠标逛超市，生活快消品一次性购齐，不用排队、送货上门，还提供 7 天无理由退货服务。天猫超市，让生活更简单。</p>
    </>
  )

  const vipshop = (
    <>
    <ol>
        <li>100 元起充值</li>
        <li>唯品卡不能兑换其它礼品卡、不兑现、不记名、不挂失。</li>
        <li>唯品卡有效期为自卡片激活之日起 3 年；</li>
        <li>如需查询更多相关信息，请访问卡官网 <a href="https://vipcard.vip.com/">https://vipcard.vip.com/</a>。</li>
      </ol>
      <hr/>
    <h2><b>Terms & Conditions</b></h2>
    <br/>
    <p>唯品会定位于“一家专门做特卖的网站”，主打众多大品牌的服装，配饰，鞋类，和化妆品，为超过2800万用户提供超过 5000 个品牌以供选择</p>
  
</>
  )

  const walmart = (
    <>
    <ol>
              <li>100 元起充值</li>
              <li>本卡仅限在售卡的沃尔玛商场（含沃尔玛购物广场、山姆会员店、沃尔玛惠选超市、沃尔玛社区店，下同）及特约的沃尔玛商场自营区域使用。特约的沃尔玛商场名单请咨询沃尔玛商场。</li>
              <li>本卡为不记名卡、不挂失、无密码。</li>
              <li>电子卡有效期一年，实体卡有效期三年,若超过有效期,请拨打 4000888400 进行延期或咨询沃尔玛商场服务台。</li>
              <li>持本卡在沃尔玛商场消费时不再重复开具发票。</li>
              <li>持本卡在山姆会员店消费时须与山姆会员店会员卡同时使用。</li>
              <li>其它相关事宜，详见沃尔玛商场公示的《沃尔玛GIFT卡章程》。</li>
            </ol>
            <hr/>
          <h2><b>Terms & Conditions</b></h2>
          <br/>
          <p>沃尔玛礼品卡全渠道升级，接入沃尔玛在京东的官方旗舰店以及在京东到家平台的支付体系，从而实现一张礼品卡线上线下支付打通。顾客只要在沃尔玛购物，无论是在全国400多家沃尔玛实体门店</p>
        
    </>
  )

  const jd = (
    <>
    <ol>
              <li>10 元起充值</li>
              <li>京东E卡不能兑换其它礼品卡、不兑现、不记名、不挂失。</li>
              <li>京东E卡仅能购买京东自营产品, 有效期为自卡片激活之日起 3 年。</li>
              <li>如需查询更多相关信息，请访问卡<a href="https://pro.jd.com/mall/active/AXBiiwZRwZYF8tWYvRPZpUTpqxu/index.html">官网</a>。</li>
            </ol>
            <hr/>
          <h2><b>Terms & Conditions</b></h2>
          <br/>
          <p>京东 JD.COM -专业的综合网上购物商城，销售超四千多万种商品。</p>
    </>
  )

  const gap = (
    <>
    <ol>
              <li>100 元起充值</li>
              <li>可多次使用，三年有效期，过期后可拨打 400-060-5151 续延有效期。</li>
              <li>礼品卡可用于购买店内所有商品包含正价、打折和促销商品。</li>
              <li>顾客凭此卡可在 <a href="gap.cn/oldnavy.cn">gap.cn/oldnavy.cn</a> 官网以及全国 gap 正价门店（杭州仅限中大银泰和城西银泰店使用；北京崇文门新世界门店不可使用）、gap factory 店、old navy 正价门店使用，全国奥特莱斯店和清仓店不可使用。</li>
              <li>本礼品卡不找零，不挂失，不开具发票。</li>
              <li>超出礼品卡的多余部分金额可由其它支付方式补足。</li>
            </ol>
            <hr/>
          <h2><b>Terms & Conditions</b></h2>
          <br/>
          <p>Gap(盖璞) 1969 年创立于美国，是美国最大的服装公司之一，旗下拥有五个品牌（GAP、Banana Republic、Old Navy、Piperlime、Athleta )。Gap专注于美式休闲风服饰，现在中国大陆地区覆盖了 32 个城市，约 128 家门店</p>
        
    </>
  )

  const hotelgift = (
    <>
    <ol>
              <li>购买金额最少 100 元，最多 50000 元</li>
              <li>此卡可预定全球 140 多家酒店，包括 Marriott, Hilton, Holiday Inn。</li>
              <li>超出礼品卡的多余部分金额可由其它支付方式补足。</li>
              
            </ol>
            <hr/>
          <h2><b>全球 140 多家酒店预览</b></h2>
          <br/>
          <img src = {require('../image/payment/hotelgift_logos.png').default} style={{width:'100%'}}/>
    </>
  )

  const flightgiftcard = (
    <>
    <ol>
              <li>购买金额最少 100 元，最多 50000 元</li>
              <li>此卡可购买全球 300 多家主流航空公司的航班机票，包括 British Airways, American Airlines, Delta。</li>
              <li>超出礼品卡的多余部分金额可由其它支付方式补足。</li>
  
            </ol>
            <hr/>
          <h2><b>全球 300 多家航空公司预览</b></h2>
          <br/>
          <img src = {require('../image/payment/flightgift_logos.png').default} style={{width:'100%'}}/>
    </>
  )

  const chinaMobile = (
    <>
    您可联系我们购买此项服务，1 元起充。
            <hr/>
          <h2><b>Terms & Conditions</b></h2>
          <br/>
          <img src = {require('../image/payment/china-mobile-china.png').default} />
    </>
  )

  const chinaTelecom = (
    <>
    您可联系我们购买此项服务，1 元起充。
            <hr/>
          <h2><b>Terms & Conditions</b></h2>
          <br/>
          <img src = {require('../image/payment/china-telecom.png').default} />
    </>
  )

  const chinaUnicom = (
    <>
    您可联系我们购买此项服务，1 元起充。
            <hr/>
          <h2><b>Terms & Conditions</b></h2>
          <br/>
          <img src = {require('../image/payment/china-unicom-china.png').default} />
    </>
  )

  const carrefour = (
    <>
    <ol>
              <li>100 元起充值</li>
              <li>本卡仅限在中国大陆所有家乐福门店使用。</li>
              <li>本电子购物卡为不记名卡，不挂失，使用时无密码，不可兑换现金，不找零，卡片激活后可多次使用。</li>
              <li>售卡时已向购卡人开具过发票，使用本卡在中国大陆地区所有门店购物后不再重复开具发票，仅提供购物小票。</li>
              <li>本电子购物卡有效期为三年，请在有效期内使用本电子购物卡，用电子卡购物发生退换货时按各门店规定（详见门店说明）进行协商解决。</li>
            </ol>
            <hr/>
          <h2><b>Terms & Conditions</b></h2>
          <br/>
          <p>家乐福是世界上最大的超市连锁店之一。 家乐福在中国 73 个城市有 236 多家连锁店。</p>
    </>
  )

  const hema = (
    <>
    盒马是阿里巴巴集团旗下,以数据和技术驱动的新零售平台。盒马希望为消费者打造社区化的一站式新零售体验中心,用科技和人情味带给人们“鲜美生活”。
    <hr/>
    此礼品卡 100 元/张 起购
    </>
  )

  const starbucks = (
    <>
    星巴克在中国大陆 180 个城市有超过 4400 个商店
    <hr/>
    此礼品卡 100 元/张 起购
    </>
  )



  export const cards = 
    {
      'suning': suning,
      'tmall': tmall,
      'vipshop' : vipshop,
      'walmart': walmart,
      'jd': jd,
      'gap': gap,
      'hotelgift': hotelgift,
      'flightgiftcard' : flightgiftcard,
      'china-mobile': chinaMobile,
      'china-telecom': chinaTelecom,
      'china-unicom': chinaUnicom,
      'carrefour': carrefour,
      'hema': hema,
      'starbucks': starbucks
      
      
    }


  export const exchanges = [
    [
      {
          name:'Binance',
          url: 'https://www.binance.com/en/register?ref=UHCRSVIJ',
          src: 'binance.png'
      },
      
      {
        name:'Huobi',
        url: 'https://www.huobi.com/topic/invited/?invite_code=m2e24',
        src: 'huobi.png',
      },

      {
        name: 'OKEx',
        url: 'https://www.okex.com/join/2202765',
        src: 'okex.png',
      }
    ],

    [
      
      {
        name: 'Coincola',
        url: 'https://www.coincola.info/signup/phone?ref=2iLqKCKo',
        src: 'coincola.png',
      },

      {
        name: 'Gateio',
        url:'https://www.gate.io/',
        src: 'gateio.png',
      },
   
      {
          name:'LocalCryptos',
          url: 'https://localcryptos.com/r/ethsh',
          src: 'LocalCryptos.png'
      },

    ],

    [
      
      {
        name: 'Cryptolocally',
        url: 'https://cryptolocally.com/zh/user/register?ref=w0lDa',
        src: 'Cryptolocally.png',
      },

      {
        name: 'Coinbase',
        url:'https://www.coinbase.com/join/wang_qfh',
        src:'coinbase.png',
      },
    
      {
          name:'Bitfinex',
          url: 'https://www.bitfinex.com/?refcode=ze0i0ThQu',
          src: 'Bitfinex.png'
      },
      
    ],

    [
      {
        name: 'Bitstamp',
        url: 'https://www.bitstamp.net/',
        src: 'bitstamp.png',
      },

      {
        name: 'Kraken',
        url:'https://www.kraken.com/',
        src:'kraken.png',
      },

    
      {
          name:'Gemini',
          url: 'http://gemini.com/share/9ydkykh7',
          src: 'Gemini.png'
      },
    ],

    [
      
      {
        name: 'Revolut',
        url: 'https://revolut.com/referral/siyis685',
        src: 'Revolut.png',
      },

      {
        name: 'Swissquote',
        url:'https://en.swissquote.com/',
        src:'Swissquote.png',
      },
    
      {
        name: 'Coolbix',
        url: 'https://coolwallet.io/product/coolwallet/?ref=siyiwang',
        src: 'Coolbitx.png',
      },  
    ],

    [
      {
        name: 'Paysend',
        url: 'https://paysend.com/bonus?code=9a5cfa',
        src: 'Paysend.png',
      },
      {
        name: 'Poloniex',
        url: 'https://poloniex.com/signup/?c=YKCPBKZ2',
        src: 'poloniex.png',
      },
      {
        name: 'Independent reserve',
        url: 'https://www.independentreserve.com/invite/XWRMBF',
        src: 'independentreserve.png',
      },
    ]


  ]

  export const accountOpen = [
    {
      country: '美国',
      title: '美 国 银 行 开 户',
      info1:'银行账户已参与美国联邦存款保险公司 (FDIC) 保险',
      info2: '仅需提供护照，身份证和手机号',
      img: 'NYC2x.png'
    },

    {
      country: '瑞士',
      title: '瑞 士 银 行 开 户',
      info1:'合作银行受瑞士金融市场监督管理局 (FINMA ) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'buildup.gif'
    },

    {
      country: '英国',
      title: '英 国 银 行 开 户',
      info1:'银行机构受英国金融行为监管局 (FCA) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'card.jpg'
    },

    {
      country: '德国',
      title: '德 国 银 行 开 户',
      info1:'银行机构受英国金融行为监管局 (FCA) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'globe.png'
    },

    {
      country: '新西兰',
      title: '新 西 兰 银 行 开 户',
      info1:'银行机构受新西兰内政部 (DIA) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'sendmoney.gif'
    },

    {
      country: '澳大利亚',
      title: '澳 大 利 亚 银 行 开 户',
      info1:'银行机构受澳大利亚证券投资委员会 (ASIC) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'Australia.jpg'
    },

    {
      country: '波兰',
      title: '波 兰 银 行 开 户',
      info1:'银行机构受英国金融行为监管局 (FCA) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'onlineshopping.gif'
    },

    {
      country: '新加坡',
      title: '新 加 坡 银 行 开 户',
      info1:'银行机构受新加坡金融管理局 (MAS) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'singapore.jpeg'
    },

    {
      country: '土耳其',
      title: '土 耳 其 银 行 开 户',
      info1:'银行机构受英国金融行为监管局 (FCA) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'turkey.jpeg'
    },

    {
      country: '罗马尼亚',
      title: '罗 马 尼 亚 银 行 开 户',
      info1:'银行机构受英国金融行为监管局 (FCA) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'romania.jpeg'
    },

    {
      country: '匈牙利',
      title: '匈 牙 利 银 行 开 户',
      info1:'银行机构受英国金融行为监管局 (FCA) 监管',
      info2: '仅需提供护照，住址证明和手机号',
      img: 'hungary.png'
    },

  ]
  
  export const service = [
    {
      url: '/crypto',
      text:'实名注册持牌海外信托和交易所，把您的加密资产卖出并转账至全球 200 个国家和地区的银行账户（支持 37 种主流法币）'
    },

    {
      url:'/earning',
      text: '加密资产的定活期理财，年化最高可达 10%, 远高于币安等主流交易所'
    },


    {
      url:'/gold',
      text:'加密资产兑换成英国老牌保险库的记名实体黄金'

    },

    {
      url:'',
      text: '加密资产抵押贷款，利率远低于币安等主流交易所'
    },
    {
      url:'/account_open',
      text:'办理基于加密资产的 VISA/MASTERCARD 消费卡，该卡能在全球任何接受 VISA/MASTERCARD 商户和柜员机进行支付和提现'
    },
    {
      url:'/payment',
      text:'加密资产兑换京东，苏宁，盒马，沃尔玛，星巴克，家乐福等多家商户的礼品卡'
    },
    {
      url: '/payment',
      text:'加密资产预定全球 300 多家航空公司机票，全球 140 多家连锁酒店，以及充值全球手机话费'
    },
    {
      url: '',
      text:'利用加密资产合理避税'
    },
    {
      text:'其它还有更多服务需要根据您的需求进行评估后给出'
    }


  ]


  export const currencies = [
    {
        currency: 'BTC',
        name: 'Bitcoin 比特币',
        symbol: 'BTC',
        network: '',
        img: <Logo.BTC />
    },
    {
        currency: 'BTCBNB',
        name: 'Bitcoin (BEP2) 比特币 (币安链)',
        symbol: 'BTC',
        network: 'BEP2',
        img: <Logo.BTC />
    },
    {
        currency: 'ETHBNB',
        name: 'Ethereum (BEP2) 以太坊 (币安链)',
        symbol: 'ETH',
        network: 'BEP2',
        img: <Logo.ETH />
    },
    {
      currency: 'ETHBSC',
      name: 'Ethereum (BEP20) (币安智能链)',
      symbol: 'ETH',
      network: 'BEP20',
      img: <Logo.ETH />
    },
    {
        currency: 'XMR',
        name: 'Monero 门罗币',
        symbol: 'XMR',
        network: '',
        img: <Logo.XMR />
        
    },
    {
        currency: 'TRX',
        name: 'Tron 波场币',
        symbol: 'TRX',
        network: '',
        img: <Logo.TRX /> 
    },
    {
        currency: 'USDTTRC',
        name: 'USDT (TRC20) 泰达币 (波场链)',
        symbol: 'USDT',
        network: 'TRC20',
        img: <Logo.USDT />
    },
    {
      currency: 'USDTBSC',
      name: 'USDT (BEP20) 泰达币 (币安智能链)',
      symbol: 'USDT',
      network: 'BEP20',
      img: <Logo.USDT />
  },
    {
        currency: 'BCH',
        name: 'Bitcoin Cash 比特币现金',
        symbol: 'BCH',
        network: '',
        img: <Logo.BCH />
    },
    {
        currency: 'ZEC',
        name: 'Zcash 大零币',
        symbol: 'Zcash',
        network: '',
        img: <Logo.Zcash />
    },
    {
        currency: 'LTC',
        name: 'Litecoin 莱特币',
        symbol: 'LTC',
        network: '',
        img: <Logo.LTC />
    },
    {
        currency: 'BNB',
        name: 'BNB (BEP2) 币安币 (币安链)',
        symbol: 'BNB',
        network: 'BEP2',
        img: <Logo.BNB />
    },
    {
      currency: 'BSC',
      name: 'BNB (BEP20) 币安币 (币安智能链)',
      symbol: 'BNB',
      network: 'BEP20',
      img: <Logo.BNB />
  },
    {
        currency: 'BUSD',
        name: 'BUSD (BEP2) (币安链)',
        symbol: 'BUSD',
        network: 'BEP2',//BNB
        img: <Logo.BUSD />
    },
    {
      currency: 'BUSDBSC',
      name: 'BUSD (BEP20) (币安智能链)',
      symbol: 'BUSD',
      network: 'BEP20',
      img: <Logo.BUSD />
  },
  {
    currency: 'BUSDTRC',
    name: 'BUSD (TRC20)',
    symbol: 'BUSD',
    network: 'TRC20',
    img: <Logo.BUSD />
},

   {
        currency: 'USDCBSC',
        name: 'USD Coin (BEP20) (币安智能链)',
        symbol: 'USDC',
        network: 'BEP20',
        img: <Logo.USDC />
    },
  {
    currency: 'USDCTRC',
    name: 'USD Coin (TRC20)',
    symbol: 'USDC',
    network: 'TRC20',
    img: <Logo.USDC />
},
{
  currency: 'USDCMATIC',
  name: 'USD Coin (POLYGON)',
  symbol: 'USDC',
  network: 'POLYGON',
  img: <Logo.USDC />
},


    
]

export const listOfEarningCoins = [
  {
      type: "ETH",
      rate: "0.04",
      precision: 8,
  },
  {
      type: "BTC",
      rate: "0.04",
      precision: 8
  },
  {
      type: "XRP",
      rate: "0.04",
      precision: 8
  },
  {
      type: "XLM",
      rate: "0.04",
      precision: 8
  },
  {
      type: "BAT",
      rate: "0.04",
      precision: 8
  },
  {
    type: "USDT",
    rate: "0.1",
    precision: 8
  },
  {
    type: "USDC",
    rate: "0.1",
    precision: 8
  },
  {
    type: "DAI",
    rate: "0.1",
    precision: 8
  },
  {
    type: "TUSD",
    rate: "0.1",
    precision: 8
  },
  {
    type: "PAX",
    rate: "0.1",
    precision: 8
  },
  {
    type: "BUSD",
    rate: "0.1",
    precision: 8
  },
]

export const listOfLoanCoins = [
  {
      type: "ETH",
      rate: "0.15",
      img: <Logo.ETH />
  },
  {
      type: "BTC",
      rate: "0.15",
      img: <Logo.BTC />
      
  },
  {
      type: "USDT",
      rate: "0.24",
      img: <Logo.USDT />
  
  },
  {
      type: "XLM",
      rate: "0.15",
      img: <Logo.XLM />
  },
  {
      type: "BAT",
      rate: "0.15",
      img: <Logo.BAT />
  },
  {
      type: "LINK",
      rate: "0.15",
      img: <Logo.LINK />
  },
  {
      type: "XRP",
      rate: "0.15",
      img: <Logo.XRP />
  },
  {
      type: "USDC",
      rate: "0.24",
      img: <Logo.USDC />
  }
]