import React, {  useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";
import Nav from "./Nav"
import { getUser } from '../containers/API'
import { failure } from "../components/notifications";
import {ThreeDotsLoader} from '../components/svg'
import Wallet from "../components/wallet";

export default function Dashboard(props){

	
	const { isAuthenticated} = useAppContext();
	const [userData, setUserData] = useState()



	const [pageIsLoading, setPageIsLoading] = useState(true);



	async function onLoad(e){
		
		setPageIsLoading(true)	
		if (!isAuthenticated){
				  
				  return;
			  }
		try{
		  const userData = await getUser();
		  setUserData(userData)

		}catch(e){
			failure('无法加载', e.message)
		}
		setPageIsLoading(false)	
	  }
	  
	  useEffect(()=>{
		  onLoad();
	  }, [])


	function render(){
		return (
			<div id='main'>
			<Nav />
			{pageIsLoading?
			<div id='loading'>
				<ThreeDotsLoader />
			</div>:
			


			<div class= 'hero-body'>
				<div className='container summary'>
					<div className='card round-card'  >
						<div className = 'card-content'>
							<Wallet data = {userData}/>
						</div>
					</div>  
				</div>
			</div>
			
			}
			</div>

		);
	}


	


	  return render();
}
