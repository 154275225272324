import React, { useState } from "react";
// import "./Login.css";
import { Auth } from "aws-amplify";
import Nav from "./Nav"
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import {Eye, EyeOff, Mail} from '../components/svg'
import validator from 'email-validator'
import {failure} from '../components/notifications'
import { Link} from "react-router-dom";
export default function Login(props) {
	const { userHasAuthenticated, setResendConfirm, email, setEmail, password, setPassword, setUserInfo, userInfo } = useAppContext();
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const [inputType, setInputType] = useState("password");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
		setIsLoading(true);
    try {
			await Auth.signIn(email, password);
			userHasAuthenticated(true);
			setUserInfo({... userInfo, email: email})
			history.push("/dashboard");
    } catch (e) {
			const title = '登录失败'
			const description = e.message
			if (description === 'User is not confirmed.'){
				await Auth.resendSignUp(email)
				setResendConfirm(true)
				history.push("/signup")
			}else{
				failure(title, description)
			}
			
			
	}
	


			setIsLoading(false);
	}

	
  return (
	<>
	<Nav />
	<section class='login-container hero-body'>
	<form class="Login" onSubmit = {handleSubmit}>
		<div class="field">
		  <label class="label">邮箱</label>
		  <div class="control has-icons-left">
		    <input class="input" autoFocus type="email" placeholder="example@email.com" value={email} onChange={e => setEmail(e.target.value)}/>
			<span class='icon is-left'><Mail/></span>
		  </div>
		  {!validator.validate(email)&&email.length>3&&<p class='help has-text-danger'>邮箱格式不正确</p>}
		</div>

		<div class="field">
		<label class="label">密码</label>
		  <p class="control has-icons-right has-icons-left">
		    <input class="input" type={inputType} value={password} onChange={e => setPassword(e.target.value)}/>
		  	<span class='icon is-right' onClick={function(){inputType==='password'?setInputType('text'):setInputType('password')}}>
			  {inputType === 'password'?<EyeOff />:<Eye />}
			</span>
		  </p>
		</div>
		{/* <div class="field">
		  <div class="control">
		    <label class="checkbox">
		      <input type="checkbox"/>
		       记住我
		    </label>
		  </div>
		</div> */}
		<div>
			<Link to='/forgotPassword'>忘记密码</Link>
		</div>
		<hr />
		<div class="field is-grouped is-grouped-centered">
		  <div class="control">
				{ isLoading?
				<button class="button is-rounded is-bold is-link is-loading" type="submit">登录</button> :
		    <button class="button is-rounded is-bold is-link" type='submit' disabled={!validateForm()}>登录</button>
				}
				</div>
		</div>
		
	</form>
</section>
</>
  );
}