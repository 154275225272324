
// deveopment
// export default {
//   apiGateway: {
//     REGION: "ap-northeast-1",
//     URL: "https://jgcyo2qtr3.execute-api.ap-northeast-1.amazonaws.com/prod"
//   },
//   cognito: {
//     REGION: "ap-northeast-1",
//     USER_POOL_ID: "ap-northeast-1_ZZ2qzsQyh",
//     APP_CLIENT_ID: "5n8ln50i2vkmjnhck5vngj5lbr",
//     IDENTITY_POOL_ID: "ap-northeast-1:75502a59-cc83-4d9f-b10a-1e501f0aafe4"
//   }
// }

// production
export default {
  apiGateway: {
    REGION: "ap-northeast-1",
    URL: "https://1b4yhzjzvl.execute-api.ap-northeast-1.amazonaws.com/prod",
    UerProfile: "https://wkz4sy6bn5.execute-api.ap-northeast-1.amazonaws.com",
  },
  cognito: {  
    REGION: "ap-northeast-1",
    USER_POOL_ID: "ap-northeast-1_n788cs881",
    APP_CLIENT_ID: "327ak5u3ci97jsduttfpmmlloh",
    IDENTITY_POOL_ID: "ap-northeast-1:58433417-cc79-48e0-905e-2f26b551c25c"
  }
}
