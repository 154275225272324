import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import config from './config';
import Amplify from 'aws-amplify';
import { ChakraProvider } from '@chakra-ui/react'

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    // Storage: {
    //   region: config.s3.REGION,
    //   bucket: config.s3.BUCKET,
    //   identityPoolId: config.cognito.IDENTITY_POOL_ID
    // },
    API: {
      endpoints: [
        {
          name: "transactions",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name: "getBinanceData",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
            name: "assetBalance",
            endpoint: config.apiGateway.URL,
            region: config.apiGateway.REGION
        },

        {
          name: "optHistory",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "fiatBalance",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "savingBalance",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "address",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "withdrawal",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "optHistory",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "getEstimateInterest",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "getAssetDetails",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name: "cashout",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "newDeposit",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "invitationCodeVerify",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },

        {
          name: "swap",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name: "getSwapHistory",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name: "exchangeGetPrice",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name:"exchangeCreateOrder",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name:"exchangeGetOrder",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name:"checkTrc20USDTTransactionStatus",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        }
        ,
        {
          name:"verifyBep2Addr",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name:"getOrderByIdAddr",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name:"users",
          endpoint: config.apiGateway.UerProfile,
          region: config.apiGateway.REGION
        }
      ]
    }
  });

ReactDOM.render(
    
    <Router>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
