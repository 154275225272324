import React, {  useEffect, useRef, useState} from "react";
import { Select, Modal, Button, Space, Radio, Tooltip } from 'antd';
import {RightArrow} from '../components/svg'
import {createOrder, getBankAddr, getPurchaseRate, newDeposit} from '../containers/API'
import {failure, depositNotification, success} from '../components/notifications'
import {assetList, findIndexByType} from './selfDefine'
import { useAppContext } from "../libs/contextLib";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Stack, Input, InputGroup,InputRightAddon, Text, HStack, Center, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton} from "@chakra-ui/react"
import { CopyOne, SmilingFace } from "@icon-park/react";
import Nav from "../containers/Nav";
import { Link} from "react-router-dom";
import { Logo } from "./coinLogo";
import { handleInputNumber } from "../libs/handleInputNumbers";
import { ceil10, floor10 } from "./Decimal";
import { Order } from "@icon-park/react";
export default function Purchase(props){
	const location = useLocation()
	const history = useHistory()
	const { type, available } = location.state
	const [params, setParams] = useState({
        get: '',
        pay: ''
    })
    const [permission, setPermission] = useState(false)
    const [bankData, setBankData] = useState()
    const mini = 1000
    const [rate, setRate] = useState()
    const ratePerUSDT = 1/rate
    const ratePerUSD = rate
    const initialFocusRef = useRef()
    const { isAuthenticated} = useAppContext()

    function calNumbers(e, rate, type){
        // type: get or pay
        
        if (type === 'get'){
            const value = handleInputNumber(e.target.value, 6)
            const spend = ceil10(value*rate, -2) 
            setParams({pay: spend, get: value})
        }
        else {
            const value = handleInputNumber(e.target.value, 2)
            const recieve = floor10(value*rate, -6) 
            setParams({get: recieve, pay: value})
        }    
    }


    function ShowLogo(props) {
        const text = props.text
        const figure = props.figure
        return(
            <HStack>
                <Text>{text}</Text>
                <figure class="image is-24x24">
                    {figure}
                </figure>    
            </HStack>)    
    }

    function validateForm(){
        return params.get>=mini
    }

    async function getBuyRate(){
        try{
            const result = await getPurchaseRate()
            setRate(result.toFixed(4))
            
        }catch(e){
            failure('无法获取汇率', e.message)
        }
    }

    async function checkBankAddr(){
        try{
            const result = await getBankAddr()
            if (result){
                setBankData(result)
                setPermission(true)
            }
        }catch(e){
            failure('出错了', e.message)
        }
    }

    useEffect(()=>{
        getBuyRate()
        checkBankAddr()
    }, [])

    async function onSubmit(){
        if (!isAuthenticated){
			history.push('/login')  
        return;
        }
        try {
            const data = {
                bank: bankData,
                spend: {
                    name: 'USD',
                    value: params.pay
                },
                get: {
                    name: type,
                    value: params.get
                },
                rate: rate
            }
            await createOrder(data)
            success('下单成功', '')
        }catch(e){
            failure('下单失败', e.message)
        }
    }
    
    
	return(
		<div>
			<Nav />
			<div class= 'container' style={{paddingBottom:'20%'}}>
				
				<Box  pt='20px' w='fit-content' m='auto'>
                
                    <Stack spacing={50}>
                        <Box  fontSize='25px'>
                            <HStack>   
                                <Order theme="outline" size="24" fill="#1972F5" strokeWidth={3} strokeLinecap="square"/>
                                <h2 style={{fontSize:'20px'}}>购买{type}</h2>    
                            </HStack>
                        </Box>

                        <Box>
                            <Text>得到</Text>
                            <InputGroup size="lg">
                                <Input variant="filled" placeholder="0.00" backgroundColor='#F6F7F8' focusBorderColor='#276CDA' value={params.get} onChange={(e)=>calNumbers(e,ratePerUSDT,'get')}/>
                                <InputRightAddon children={<ShowLogo text={type} figure={<Logo.USDT />}/>} border='none' />
                            </InputGroup>
                            <span className='help tag is-link is-light'>最低购买 1000 USDT</span>
                        </Box>
                        <Box>
                            <Text>支付</Text>
                            <InputGroup size="lg" >
                                <Input variant="filled" placeholder="0.00" backgroundColor='#F6F7F8' focusBorderColor='#276CDA' value={params.pay} onChange={(e)=>calNumbers(e,ratePerUSD,'pay')}/>
                                <InputRightAddon children={<ShowLogo text='USD' figure={<Logo.USD />}/>} border='none' />
                            </InputGroup>
                        </Box>
                        <Box>
                            <Text>参考价格: 1 USD ≈ {ratePerUSD} USDT</Text>
                        </Box>   
                        <Center>
                            {
                                permission?<Link to={{pathname: "/orders", state: {
                                    type: type,
                                    price: ratePerUSD,
                                    recieve: params.get,
                                    spend: params.pay,
                                    bankData: bankData
                                } }} className='button is-link is-rounded ' disabled={!validateForm()} onClick={onSubmit}>立即下单</Link>:
                                <Popover placement="right"  initialFocusRef={initialFocusRef} closeOnBlur={true}>
                                    <PopoverTrigger>
                                        <button className='button is-link is-rounded' ref={initialFocusRef}>立即下单</button>
                                    </PopoverTrigger>
                                    <PopoverContent bg="gray.50">
                                        
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <PopoverBody >
                                            <Box>
                                                <HStack>
                                                 <SmilingFace theme="outline" size="24" fill="#1972F5" strokeWidth={3} strokeLinecap="square"/>
                                                 <h2 style={{fontSize:'17px'}}>请联系理财经理开通此功能</h2>
                                                </HStack>
                                            
                                            </Box>
                                            
                                        </PopoverBody>
                                    </PopoverContent>
                                     
                                </Popover>
                               
                            }
                            
                        </Center> 
                    </Stack>
                    
				</Box>
			</div>
			
		</div>

		
	)
}