
import React from 'react'
export function Eye(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
        </svg>
    );
}

export function EyeOff(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
            <line x1="1" y1="1" x2="23" y2="23"></line>
        </svg>
    );
}

export function Key(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key">
            <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4">
            </path>
        </svg>
    )
}


export function Mail(){
    return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail">
        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
        <polyline points="22,6 12,13 2,6"></polyline></svg>
    )
}

export function Download(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="-3 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" className="feather feather-download-cloud">
        <polyline points="8 17 12 21 16 17"></polyline>
        <line x1="12" y1="12" x2="12" y2="21"></line>
        <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path>
      </svg>
    )
}

export function GirlWithPhone(){
    return (
        <svg width="462" height="500" viewBox="-40 -40 472 402" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                <g clip-path="url(#clip0)">
                <path d="M220.5 394C337.308 394 432 389.719 432 384.437C432 379.156 337.308 374.874 220.5 374.874C103.692 374.874 9 379.156 9 384.437C9 389.719 103.692 394 220.5 394Z" fill="#1E266C" fill-opacity="0.8"/>
                <path d="M236.016 91.882H233.895V33.6836C233.895 29.2602 233.025 24.8801 231.335 20.7934C229.645 16.7067 227.168 12.9935 224.045 9.86567C220.922 6.73785 217.215 4.25674 213.135 2.56398C209.055 0.871229 204.682 -1.53487e-05 200.266 2.02803e-10H77.1645C72.7483 -1.39534e-05 68.3753 0.871232 64.2952 2.56399C60.2151 4.25674 56.5079 6.73786 53.3851 9.86567C50.2624 12.9935 47.7853 16.7068 46.0953 20.7934C44.4052 24.8801 43.5354 29.2602 43.5354 33.6836V352.964C43.5354 357.388 44.4052 361.768 46.0953 365.854C47.7853 369.941 50.2624 373.654 53.3851 376.782C56.5079 379.91 60.2151 382.391 64.2952 384.084C68.3753 385.777 72.7483 386.648 77.1645 386.648H200.266C204.682 386.648 209.055 385.777 213.135 384.084C217.215 382.391 220.922 379.91 224.045 376.782C227.168 373.654 229.645 369.941 231.335 365.854C233.025 361.768 233.895 357.388 233.895 352.964V133.309H236.016V91.882Z" fill="#3F3D56"/>
                <path d="M201.622 8.76331H185.554C186.293 10.5779 186.575 12.5468 186.375 14.4963C186.175 16.4458 185.5 18.3163 184.409 19.9429C183.317 21.5695 181.843 22.9023 180.117 23.8238C178.39 24.7454 176.463 25.2275 174.507 25.2277H103.984C102.027 25.2275 100.101 24.7454 98.3738 23.8238C96.647 22.9023 95.173 21.5695 94.0816 19.9429C92.9903 18.3163 92.315 16.4458 92.1154 14.4963C91.9157 12.5467 92.1978 10.5779 92.9367 8.76329H77.9285C74.6305 8.76329 71.3648 9.41392 68.3179 10.6781C65.2709 11.9422 62.5024 13.795 60.1704 16.1309C57.8383 18.4667 55.9885 21.2397 54.7264 24.2916C53.4643 27.3435 52.8147 30.6145 52.8147 33.9178V352.73C52.8147 356.033 53.4643 359.304 54.7264 362.356C55.9884 365.408 57.8383 368.181 60.1704 370.517C62.5024 372.853 65.2709 374.706 68.3178 375.97C71.3648 377.234 74.6305 377.884 77.9285 377.884H201.622C204.92 377.884 208.186 377.234 211.233 375.97C214.28 374.706 217.049 372.853 219.381 370.517C221.713 368.181 223.562 365.408 224.825 362.356C226.087 359.304 226.736 356.033 226.736 352.73V33.9178C226.736 30.6145 226.087 27.3435 224.825 24.2916C223.562 21.2397 221.713 18.4667 219.381 16.1309C217.048 13.7951 214.28 11.9422 211.233 10.6781C208.186 9.41395 204.92 8.76331 201.622 8.76331Z" fill="white"/>
                <path d="M156.973 94.4092H113.129V98.4874H156.973V94.4092Z" fill="#6C63FF"/>
                <path d="M189.545 108.517H113.129V112.595H189.545V108.517Z" fill="#E6E6E6"/>
                <path d="M189.47 122.624H113.129V126.702H189.47V122.624Z" fill="#E6E6E6"/>
                <path d="M104.043 94.4698H71.4707V127.095H104.043V94.4698Z" fill="#6C63FF"/>
                <path d="M156.973 180.05H113.129V184.128H156.973V180.05Z" fill="#6C63FF"/>
                <path d="M189.545 194.157H113.129V198.236H189.545V194.157Z" fill="#E6E6E6"/>
                <path d="M189.47 208.265H113.129V212.343H189.47V208.265Z" fill="#E6E6E6"/>
                <path d="M104.043 180.11H71.4707V212.735H104.043V180.11Z" fill="#6C63FF"/>
                <path d="M156.973 265.691H113.129V269.769H156.973V265.691Z" fill="#6C63FF"/>
                <path d="M189.545 279.798H113.129V283.876H189.545V279.798Z" fill="#E6E6E6"/>
                <path d="M189.47 293.906H113.129V297.984H189.47V293.906Z" fill="#E6E6E6"/>
                <path d="M104.043 265.751H71.4707V298.376H104.043V265.751Z" fill="#6C63FF"/>
                <path d="M358.534 175.687C367.453 175.687 374.684 168.444 374.684 159.51C374.684 150.576 367.453 143.333 358.534 143.333C349.614 143.333 342.383 150.576 342.383 159.51C342.383 168.444 349.614 175.687 358.534 175.687Z" fill="#2F2E41"/>
                <path d="M367.037 170.28C367.037 170.28 366.794 180.265 367.767 180.509C368.739 180.752 354.393 184.649 354.393 184.649L352.934 171.01L367.037 170.28Z" fill="#FFB8B8"/>
                <path d="M253.727 255.522C253.727 255.522 243.028 255.522 242.785 254.304C242.542 253.087 240.353 264.29 240.353 264.29L244.73 265.751C244.73 265.751 252.997 262.098 253.727 262.098C254.456 262.098 253.727 255.522 253.727 255.522Z" fill="#FFB8B8"/>
                <path d="M242.055 259.906C242.055 259.906 244.001 253.33 246.432 253.086C246.432 253.086 238.165 247.728 237.922 246.024C237.679 244.319 234.761 239.935 233.545 241.396C232.329 242.857 232.329 265.995 234.518 268.43C236.706 270.866 246.432 265.007 246.432 265.007C246.432 265.007 240.353 262.341 242.055 259.906Z" fill="#2F2E41"/>
                <path d="M374.089 353.186L376.034 363.658L367.037 365.12V353.186H374.089Z" fill="#FFB8B8"/>
                <path d="M374.089 362.44C374.089 362.44 375.547 357.813 376.52 358.544C377.493 359.274 378.952 363.902 378.709 365.119C378.465 366.337 376.763 375.836 377.493 377.54C378.222 379.245 373.845 388.013 368.253 387.282C362.66 386.552 363.147 381.681 363.147 381.681L365.092 374.131L365.821 362.197C365.821 362.197 372.143 361.223 374.089 362.44Z" fill="#2F2E41"/>
                <path d="M364.183 149.178C367.874 149.178 370.866 146.181 370.866 142.484C370.866 138.787 367.874 135.79 364.183 135.79C360.492 135.79 357.5 138.787 357.5 142.484C357.5 146.181 360.492 149.178 364.183 149.178Z" fill="#2F2E41"/>
                <path d="M373.094 138.858C373.094 137.203 372.482 135.607 371.377 134.378C370.271 133.148 368.75 132.373 367.107 132.2C367.338 132.176 367.571 132.164 367.803 132.164C369.576 132.164 371.276 132.869 372.529 134.125C373.782 135.38 374.486 137.083 374.486 138.858C374.486 140.633 373.782 142.336 372.529 143.591C371.276 144.847 369.576 145.552 367.803 145.552C367.571 145.552 367.338 145.54 367.107 145.516C368.75 145.343 370.271 144.567 371.377 143.338C372.482 142.108 373.094 140.512 373.094 138.858Z" fill="#2F2E41"/>
                <path d="M358.527 177.099C364.167 177.099 368.739 172.519 368.739 166.87C368.739 161.22 364.167 156.641 358.527 156.641C352.886 156.641 348.314 161.22 348.314 166.87C348.314 172.519 352.886 177.099 358.527 177.099Z" fill="#FFB8B8"/>
                <path d="M378.709 237.986C378.709 237.986 378.222 250.164 376.277 256.496C374.332 262.829 367.319 307.926 368.778 311.335C370.236 314.745 377.736 352.455 376.034 354.647C374.332 356.839 366.308 356.108 366.308 356.108L355.404 310.361C355.404 310.361 357.554 259.906 339.561 258.688C321.567 257.47 292.145 256.74 292.145 256.74C292.145 256.74 273.179 262.341 264.669 262.098C256.158 261.854 251.295 264.29 251.295 264.29L249.836 254.304L287.525 246.024C287.525 246.024 322.053 234.09 341.019 237.256C359.986 240.422 378.709 237.986 378.709 237.986Z" fill="#2F2E41"/>
                <path d="M365.092 177.342C365.092 177.342 368.01 175.638 368.739 177.342C369.469 179.047 374.575 180.509 376.52 179.047C378.465 177.586 383.328 179.534 383.328 179.534L388.678 195.365C388.678 195.365 386.003 209.004 383.572 216.554C381.14 224.104 380.167 239.691 381.14 241.153C382.113 242.614 361.688 248.459 351.718 244.806C341.749 241.153 339.561 237.012 339.561 237.012C339.561 237.012 350.503 221.425 346.855 214.119C343.208 206.812 346.126 198.531 346.126 198.531L344.667 190.251L352.934 185.623C352.934 185.623 353.42 181.239 355.366 181.239C357.311 181.239 365.092 177.342 365.092 177.342Z" fill="#D0CDE1"/>
                <path d="M348.314 190.494H344.667C343.655 191.442 342.901 192.633 342.476 193.954C342.05 195.275 341.967 196.682 342.235 198.044C343.208 202.672 347.594 218.013 347.594 218.013L351.232 208.517L348.314 190.494Z" fill="#D0CDE1"/>
                <path d="M337.84 229.607C337.84 229.607 330.564 237.499 335.913 241.64C341.263 245.78 342.581 232.141 342.581 232.141L337.84 229.607Z" fill="#FFB8B8"/>
                <path d="M348.314 213.144L346.612 219.964L343.694 225.322C343.694 225.322 333.725 231.898 333.725 234.333C333.725 234.333 338.102 228.732 343.694 234.333L348.679 222.277L348.314 213.144Z" fill="#D0CDE1"/>
                <path d="M356.655 162.774C362.806 162.774 367.793 159.777 367.793 156.08C367.793 152.383 362.806 149.386 356.655 149.386C350.503 149.386 345.516 152.383 345.516 156.08C345.516 159.777 350.503 162.774 356.655 162.774Z" fill="#2F2E41"/>
                <path d="M378.709 156.641C378.709 156.641 370.928 152.257 367.767 155.423C364.606 158.589 377.25 163.46 377.25 163.46L378.709 156.641Z" fill="#FFB8B8"/>
                <path d="M381.626 180.752L383.328 179.534C383.328 179.534 385.274 176.612 386.976 177.586C388.678 178.56 398.647 173.933 398.647 173.933C398.647 173.933 395 175.638 395 172.715C395 169.792 394.514 170.28 392.082 170.28C389.651 170.28 389.651 171.497 388.678 169.792C387.705 168.088 388.192 167.113 386.49 168.088C384.787 169.062 382.842 168.331 382.356 167.6C381.87 166.87 373.116 165.652 373.116 165.652C373.116 165.652 376.52 155.667 377.25 154.936C377.979 154.205 396.702 162.486 396.702 162.486C396.702 162.486 420.288 169.062 414.209 176.612C408.13 184.162 386.246 195.609 386.246 195.609L381.626 180.752Z" fill="#D0CDE1"/>
                </g>
                </g>
                <defs>
                <filter id="filter0_d" x="0" y="0" width="432" height="402" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dx="-5" dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <clipPath id="clip0">
                <rect x="9" width="423" height="394" fill="white"/>
                </clipPath>
                </defs>
                </svg>
              
    )
}

export function Home(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home">
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
        </svg>
    )
}

export function Aperture(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-aperture">
            <circle cx="12" cy="12" r="10"></circle><line x1="14.31" y1="8" x2="20.05" y2="17.94"></line>
            <line x1="9.69" y1="8" x2="21.17" y2="8"></line>
            <line x1="7.38" y1="12" x2="13.12" y2="2.06"></line>
            <line x1="9.69" y1="16" x2="3.95" y2="6.06"></line>
            <line x1="14.31" y1="16" x2="2.83" y2="16"></line>
            <line x1="16.62" y1="12" x2="10.88" y2="21.94"></line>
        </svg>
    )
}


export function Login(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-in">
        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
        <polyline points="10 17 15 12 10 7"></polyline>
        <line x1="15" y1="12" x2="3" y2="12"></line></svg>
    )
}

export function Logout(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
        </svg>
    )
}


export function Box(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box">
            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
            <line x1="12" y1="22.08" x2="12" y2="12"></line>
        </svg>
    )
}

export function Setting(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings">
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
        </svg>
    )
}

export function Refresh(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-ccw">
            <polyline points="1 4 1 10 7 10"></polyline>
            <polyline points="23 20 23 14 17 14"></polyline>
            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15">
            </path>
        </svg>
    )
}

export function USD(){
    return (
        <svg width="45" height="28" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M14.2669 27.6441C21.649 27.6441 27.6335 21.6597 27.6335 14.2776C27.6335 6.89543 21.649 0.911026 14.2669 0.911026C6.88477 0.911026 0.90036 6.89543 0.90036 14.2776C0.90036 21.6597 6.88477 27.6441 14.2669 27.6441Z" fill="#F0F0F0"/>
            <path d="M13.6858 14.2776H27.6335C27.6335 13.0711 27.4726 11.9024 27.173 10.7906H13.6858V14.2776Z" fill="#D80027"/>
            <path d="M13.6858 7.30373H25.6716C24.8534 5.96854 23.8072 4.78837 22.5874 3.81679H13.6858V7.30373Z" fill="#D80027"/>
            <path d="M14.2669 27.6441C17.4127 27.6441 20.3041 26.5568 22.5874 24.7384H5.94644C8.22972 26.5568 11.1211 27.6441 14.2669 27.6441Z" fill="#D80027"/>
            <path d="M2.86221 21.2514H25.6716C26.3285 20.1795 26.8379 19.0079 27.173 17.7645H1.36082C1.69588 19.0079 2.20532 20.1795 2.86221 21.2514Z" fill="#D80027"/>
            <path d="M7.092 2.9984H8.31007L7.17705 3.82154L7.60984 5.15344L6.47687 4.3303L5.3439 5.15344L5.71775 4.00282C4.72016 4.83379 3.8458 5.80736 3.12526 6.89214H3.51556L2.79434 7.4161C2.68197 7.60354 2.57421 7.79396 2.47093 7.9872L2.81533 9.04718L2.17279 8.58034C2.01307 8.91874 1.86698 9.26475 1.73566 9.61797L2.1151 10.7859H3.51556L2.38253 11.609L2.81533 12.9409L1.68236 12.1178L1.00369 12.6109C0.935761 13.1569 0.90036 13.7131 0.90036 14.2776H14.2669C14.2669 6.89548 14.2669 6.02519 14.2669 0.911026C11.6264 0.911026 9.16491 1.67699 7.092 2.9984ZM7.60984 12.9409L6.47687 12.1178L5.3439 12.9409L5.7767 11.609L4.64367 10.7859H6.04413L6.47687 9.45397L6.90962 10.7859H8.31007L7.17705 11.609L7.60984 12.9409ZM7.17705 7.71528L7.60984 9.04718L6.47687 8.22404L5.3439 9.04718L5.7767 7.71528L4.64367 6.89214H6.04413L6.47687 5.56024L6.90962 6.89214H8.31007L7.17705 7.71528ZM12.4044 12.9409L11.2714 12.1178L10.1384 12.9409L10.5712 11.609L9.43819 10.7859H10.8386L11.2714 9.45397L11.7041 10.7859H13.1046L11.9716 11.609L12.4044 12.9409ZM11.9716 7.71528L12.4044 9.04718L11.2714 8.22404L10.1384 9.04718L10.5712 7.71528L9.43819 6.89214H10.8386L11.2714 5.56024L11.7041 6.89214H13.1046L11.9716 7.71528ZM11.9716 3.82154L12.4044 5.15344L11.2714 4.3303L10.1384 5.15344L10.5712 3.82154L9.43819 2.9984H10.8386L11.2714 1.6665L11.7041 2.9984H13.1046L11.9716 3.82154Z" fill="#0052B4"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect x="0.90036" y="0.911026" width="26.7331" height="26.7331" fill="white"/>
            </clipPath>
            </defs>
      </svg>
    )
}

export function USDT(){
    return(
<svg xmlns="http://www.w3.org/2000/svg"  width = '45' height = '28' viewBox='0 0 32 32'>
  <g fill="none" fill-rule="evenodd">
    <circle cx="16" cy="16" r="16" fill="#26A17B"/>
    <path fill="#FFF" d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117"/>
  </g>
</svg>
    )
}

export function RightArrow(){
    return (
        <svg width="25" height="20" viewBox="-3 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 19.4454C14.9706 19.4454 19 15.3163 19 10.2227C19 5.12915 14.9706 1 10 1C5.02944 1 1 5.12915 1 10.2227C1 15.3163 5.02944 19.4454 10 19.4454Z" stroke="#F5F7FB" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 13.9118L13.6 10.2227L10 6.53363" stroke="#F5F7FB" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.39999 10.2227H13.6" stroke="#F5F7FB" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export function Wechat(){
    return (
    <svg id="0c0f1be6-60f1-447d-a9d8-8da5d35db03e" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 78" >
        <title></title>
        <g id="d012921c-310f-4930-a4ed-daab050e3a62" data-name="surface1">
            <path d="M38,12C19.25,12,4,25,4,41c0,8.54,4.12,16.22,11.63,21.78L10.36,78.61,26.85,68.4a39.16,39.16,0,0,0,15.55,1.35C46.78,78.22,56.66,84,68,84a33.34,33.34,0,0,0,9.59-1.53l13.7,7.66L87.47,77C92.63,72.75,96,66.79,96,60c0-12.42-10.8-22.33-24.5-23.79C68.81,22.3,54.73,12,38,12ZM26,28a4,4,0,1,1-4,4A4,4,0,0,1,26,28Zm24,0a4,4,0,1,1-4,4A4,4,0,0,1,50,28ZM68,40c13.49,0,24,9.07,24,20,0,5.91-3,11.1-8,14.79l-1.12.84,1.82,6.24-6.5-3.65-.84.28A29.65,29.65,0,0,1,68,80c-13.49,0-24-9.07-24-20S54.51,40,68,40ZM59,52a3,3,0,1,0,3,3A3.07,3.07,0,0,0,59,52Zm18,0a3,3,0,1,0,3,3A3.07,3.07,0,0,0,77,52Z" transform="translate(-4 -12)" fill="#7bcc6d"/>
        </g>
    </svg>
    )
}

export function Telegram (){
    return(
    <svg xmlns="http://www.w3.org/2000/svg"  version="1.1" id="Layer_1"  viewBox="0 0 48 48"  >
        <circle style={{fill:"#29B6F6"}} cx="24" cy="24" r="20"/>
            <path style={{fill:"#FFFFFF"}} d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733  l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468  c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"/>
            <path style={{fill:"#B0BEC5"}} d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043  l0.964-5.965L23,30.505z"/>
            <path style={{fill:"#CFD8DC"}} d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912  c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"/>
    </svg>
    )
}

export function ThreeDotsLoader() {
    return(
        <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#3273DC">
            <circle cx="15" cy="15" r="15">
                <animate attributeName="r" from="15" to="15"
                        begin="0s" dur="0.8s"
                        values="15;9;15" calcMode="linear"
                        repeatCount="indefinite" />
                <animate attributeName="fill-opacity" from="1" to="1"
                        begin="0s" dur="0.8s"
                        values="1;.5;1" calcMode="linear"
                        repeatCount="indefinite" />
            </circle>
            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                <animate attributeName="r" from="9" to="9"
                        begin="0s" dur="0.8s"
                        values="9;15;9" calcMode="linear"
                        repeatCount="indefinite" />
                <animate attributeName="fill-opacity" from="0.5" to="0.5"
                        begin="0s" dur="0.8s"
                        values=".5;1;.5" calcMode="linear"
                        repeatCount="indefinite" />
            </circle>
            <circle cx="105" cy="15" r="15">
                <animate attributeName="r" from="15" to="15"
                        begin="0s" dur="0.8s"
                        values="15;9;15" calcMode="linear"
                        repeatCount="indefinite" />
                <animate attributeName="fill-opacity" from="1" to="1"
                        begin="0s" dur="0.8s"
                        values="1;.5;1" calcMode="linear"
                        repeatCount="indefinite" />
            </circle>
        </svg>
    )
}

export function Logo(){
    return (
    
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 282.000000 282.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,282.000000) scale(0.100000,-0.100000)"
        fill="#276CDA" stroke="none">
        <path d="M2409 2505 c0 -22 -2 -341 -3 -710 -2 -663 -2 -671 -24 -725 -88
        -220 -335 -321 -543 -222 -120 57 -210 180 -225 305 l-7 57 202 0 201 0 0 200
        c0 153 -3 200 -12 201 -7 1 -96 2 -198 3 l-185 1 -6 55 c-22 193 -72 318 -178
        449 -47 59 -75 86 -131 129 -19 15 -37 29 -40 32 -27 28 -166 90 -249 111 -81
        22 -238 29 -325 16 -295 -46 -566 -292 -640 -584 -28 -108 -29 -147 -30 -842
        -1 -643 0 -694 16 -700 13 -5 354 -7 379 -2 3 1 5 301 5 669 -1 367 2 692 7
        722 19 130 105 246 227 304 59 28 74 31 165 31 88 0 107 -3 157 -27 72 -33
        127 -77 166 -132 33 -45 69 -141 75 -199 l4 -37 -201 -2 -201 -3 0 -200 0
        -200 198 0 c108 0 198 -2 198 -5 1 -3 3 -14 4 -25 1 -11 5 -47 9 -79 19 -155
        106 -332 221 -450 246 -251 615 -312 930 -154 160 80 305 236 374 402 62 151
        63 161 65 923 0 380 -1 700 -4 710 -5 17 -20 18 -203 18 l-197 0 -1 -40z"/>
        </g>
        </svg>

    )
}

export function Gold(){
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width='45' viewBox="-10 0 100 67.9">
            <defs>
                <mask id="mask">
                    <g id="mask-2">
                        <polygon id="path-1" points="0 4.82 23.84 4.82 23.84 59.42 0 59.42 0 4.82" style={{fill:"#fff",fillRrule:"evenodd"}}/>
                    </g>
                </mask>
            </defs>
            <g id="Group-3">
                <g id="Fill-1-Clipped">
                    <g style={{mask:"url(#mask)"}}>
                        <path id="Fill-1" d="M20,8.84c1.37-1.56,2.63-2.88,3.83-4-20.26-.65-14.36,14-21,28.87-7.6,17.14.9,23.67,13.56,25.71a27.56,27.56,0,0,1-4.28-7C3.94,42.09,7.25,23.26,20,8.84" style={{fill:"#efe812"}}/>
                    </g>
                </g>
            </g>
            <path id="Fill-4" d="M68.75,27.1A57.87,57.87,0,0,1,58.52,44.23,49.09,49.09,0,0,1,46.09,55a33.56,33.56,0,0,1-18.34,5.21,72.2,72.2,0,0,1-11.32-.79A23.14,23.14,0,0,0,24,65.35c18.32,8.86,19.47-7.59,38.85-15.94,10.88-4.69,10.92-13.85,5.89-22.31" style={{fill:"#ede70a"}}/>
            <path id="Fill-6" d="M46.09,10a24.07,24.07,0,0,1,7.56,2.68A42.17,42.17,0,0,1,68.75,27.1c3.3-9.3,1.76-16.6-5.45-17.92C55.36,7.73,49.16,7.26,43.2,3.1,35.86-2,29.42-.34,23.84,4.85l1.21.05c7.74.46,15.14,2,21,5.08" style={{fill:"#ede70a"}}/>
            <path id="Fill-8" d="M20,8.84C7.25,23.26,3.94,42.09,12.15,52.42a31.36,31.36,0,0,1,1.77-28C20.83,12.9,34.7,7.9,46.09,10c-5.9-3.12-13.3-4.62-21-5.08l-1.21-.06C22.64,6,21.38,7.28,20,8.84" style={{fill:"#dec21c"}}/>
            <path id="Fill-10" d="M53.65,12.66A24.07,24.07,0,0,0,46.09,10C53.85,14.08,59,21,59,31.94A27.53,27.53,0,0,1,46.09,55,48.89,48.89,0,0,0,58.52,44.23,57.87,57.87,0,0,0,68.75,27.1a42.17,42.17,0,0,0-15.1-14.44" style={{fill:"#e5cb22"}}/>
            <path id="Fill-12" d="M46.09,10C34.7,7.9,20.83,12.9,13.92,24.37a31.33,31.33,0,0,0-1.77,28.05,27.28,27.28,0,0,0,4.28,7,72.2,72.2,0,0,0,11.32.79A33.56,33.56,0,0,0,46.09,55,27.52,27.52,0,0,0,59,31.94C59,21,53.85,14.08,46.09,10m-1.28,39.8a18.94,18.94,0,0,1-26.12-5.62c-5.66-8.73-2.86-20,5.63-26,9.5-6.75,21.68-4.09,26.12,5.62,4.33,9.46,3.14,20.39-5.63,26" style={{fill:"#cca727"}}/>
        </svg> 
    )
}

export function ShieldCheck(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shield-check" width="56" height="56" viewBox="0 0 24 24" stroke-width="2" stroke="#1D72AA" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 12l2 2l4 -4" />
            <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
        </svg>

    )
}

export function ArrowRight(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right" width="56" height="56" viewBox="0 0 24 24" stroke-width="2" stroke="#3273DC" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="5" y1="12" x2="19" y2="12" />
            <line x1="13" y1="18" x2="19" y2="12" />
            <line x1="13" y1="6" x2="19" y2="12" />
        </svg>
    )
}

