import React from "react";
import Nav from "./Nav"
import {GirlWithPhone, Download} from "../components/svg"
import Footer from '../components/footer'
import { layoutGenerator } from 'react-break';
import { Link} from "react-router-dom";
import {Collapse, Timeline,BackTop } from 'antd';
import { faq,interestDetails } from './text'
export default function Saving(props) {
  const layout = layoutGenerator({
    mobile: 0,
    phablet: 550,
    tablet: 768,
    desktop: 992,
  })
  const OnAtMostTablet = layout.isAtMost('tablet');
  const OnDesktop = layout.is('desktop');

  
  const { Panel } = Collapse;

  const list = (array) => array.map( (item,i) =>
    <Panel header={item.header} key={i+1}>
          <div className="message is-link">
          <p className="message-body">{item.text}</p>
          </div>
    </Panel>
  )


  const interestList = interestDetails.map((item,i) =>
    <Timeline.Item key={i} label={item.text}>
      <span class="tag is-rounded is-link  is-large">{item.label}</span>
    </Timeline.Item>
  )
  const interest = (
    <Timeline mode='right'>
      
       {interestList}
    </Timeline>
  )


  return (
    <>
    <BackTop style={{marginBottom: '150px'}}/>
    
    <div  id={'saving'} className='hero' style={{backgroundColor:'#FAFAFB'}}>
    <Nav isAuthenticated={props.isAuthenticated} userHasAuthenticated={props.userHasAuthenticated} />     
      <div className='hero-body saving' style={{marginTop:'10px'}}>
        
        <h1 > 「盈路向前」高端美元保本储蓄</h1>
        <p className='has-text-centered is-bold'>一美元起 随存随取</p>

        <div className='divider'></div>
        <div className='columns'>
        <div className='column is-one-quarter is-offset-1'>
            {interest}
          </div>
          <div className='column is-one-third is-offset-1'>
            <div class="tags has-addons is-bold">
              <span class="tag is-medium is-link  is-light">当前客户总储蓄额:</span>
              <span class="tag is-medium is-link">$4,983,108.32</span>
            </div>
            <div className='columns '>
              <div className='column'>
                <div className='widget'>
                <p className='heading'>提现支持 37 种法币</p>
                    <figure>
                      <img src={ require('../image/illustrators/currency_monochromatic.png').default} />
                    </figure>
                    
                </div>
              </div>
              <div className='column'>
                <div className='widget'>
                <p className='heading'>活期年化 2.88%</p>
                  <figure>
                    <img src={ require('../image/illustrators/bank_note.png').default} />
                  </figure>   
                </div>
              </div>
            </div>
    
          </div>
          
        </div>
        
          <div className='line'></div>
          <div className='columns saving'>
          <div className='column is-half is-offset-1 faq-background' > 
            <h1>FAQ 储蓄常见问题</h1>
            <Collapse defaultActiveKey={['1']} ghost={true} bordered={false} style={{fontSize:'17px'}}>
              {list(faq.saving)}
            </Collapse>
          </div>
          <div className='column partners'>
            <div className='columns is-mobile'>
              <div className='column'>
                <div ><a href="https://alliancetrustcompany.com/"><img id='aliance' src="partners/aliance.png" alt='alliancetrust'/></a></div>
              </div>
              <div className='column'>
                <div><a href="https://primetrust.com/"><img id='primetrust'src="partners/PrimeTrust.png" alt='PrimeTrust'/></a></div>
              </div>
            </div>  
            
            <div className='columns is-mobile' >
              <div className='column'>
                <div ><a href="https://www.dfs.ny.gov/"><img id='nydfs' src="partners/NYDFS.jpg"alt='Nydfs'/></a></div>
              </div>
              <div className='column'>
                <div ><a href="https://dlr.sd.gov/"><img id='dlrlogo' src="partners/dlrlogo.png" alt='dlrlogo'/></a></div>
              </div>
              <div className='column'>
                <div><a href="https://www.transferwise.com/"><img id='transferwise'src="partners/transferwise.png" alt='transferwise'/></a></div>
              </div>
            </div>   
            <div style={{padding:'15% 15% 15% 15%'}}>
              <p>资金受欧洲合规加密货币牌照发放机构和美国南达科他州银行部以及美国纽约州金融服务厅 (NYDFS) 和内华达州工商部 (DBI) 与美国金融犯罪执法网 (FinCEN) 共同监管。</p>
            </div>
          </div>
        </div>
        
      </div>
      
    </div>
    
    <Footer/>
   
</>
  );
}
